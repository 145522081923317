import {useLocation, useNavigate, useParams} from 'react-router-dom';
import React, {useState} from "react";
import Slider from "react-slick";
import {mainpg} from "./products";
import "./ui/Bogdan_ref/css/ListPage.css";
import "./ui/Bogdan_ref/css/ListPageRender.css";
import "./ui/Bogdan_ref/css/ProductPage.css";
import {Control, Status} from "./ui/Bogdan__product/Control";
import {Info} from "./ui/Bogdan__product/Info";
import {Calendar} from "./ui/Bogdan__product/Calendar";
import {Grafik} from "./ui/Bogdan__product/Grafik";
import {Description} from "./ui/Bogdan__product/Description";
import {SmallDescription} from "./ui/Bogdan__product/SmallDescription";
import {BtnProduct} from "./ui/Bogdan__product/BtnProduct";
import {HeadProduct} from "./ui/Bogdan__product/HeadProduct";
import {ForAvtor} from "./ui/Bogdan__product/ForAvtor";
import {ProductImageSlider} from "./ui/Bogdan__product/ProductImageSlider";
import {Subscribed} from "./ui/Bogdan__product/Subscribed";

export const Header_product = () => {
    const navigate = useNavigate();
    const openModalMenu = () => setIsModalMenuOpen(true);
    const closeModalMenu = () => setIsModalMenuOpen(false);
    const [isModalMenuOpen, setIsModalMenuOpen] = useState(false);
    const copyLink = () => {
        navigator.clipboard.writeText(window.location.href)
            .then(() => alert('Ссылка скопирована в буфер обмена'))
            .catch((error) => console.log('Ошибка при копировании ссылки:', error));
    };
    const shareLink = () => {
        if (navigator.share) {
            navigator.share({
                title: document.title,
                text: 'Посмотрите этот сайт!',
                url: window.location.href,
            })
                .then(() => console.log('Ссылка успешно отправлена'))
                .catch((error) => console.log('Ошибка при отправке ссылки:', error));
        } else {
            copyLink();
        }
    };

    return (
        <div className="step step-1" style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
            <button className="back-button" onClick={() => {
                navigate("/");
            }}></button>
            <div className="icons prod">
                <img src="/images/icons/share.png" alt=""/>
                <img src="/images/icons/favorite-btn.png" alt=""/>
                <img src="/images/icons/cart.png" alt=""/>
                <img src="/images/icons/dots.png" alt=""/>
            </div>
        </div>
    );
};

const prices = [
    {name: 'Размещение поста', duration: '60 мин', about: 'Персональная тренировка'},
    {name: 'Групповая тренировка', duration: '90 мин', about: 'Групповое занятие'}, {
        name: 'Групповая тренировка',
        duration: '90 мин',
        about: 'Групповое занятие'
    },
    {name: 'Групповая тренировка', duration: '90 мин', about: 'Групповое занятие'},
];
const predloj = [
    {day: "Пн", dataPR: "Ежедневно", time: "12:00 - 21:00", price: "3600"},
    {day: "Вс", dataPR: "12", time: "12", price: "12"},
    {day: "Ср", dataPR: "12", time: "12", price: "12"}
];
const daysOrder = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];
const sortedPredloj = predloj.sort((a, b) => {
    return daysOrder.indexOf(a.day) - daysOrder.indexOf(b.day);
});
const mes = [
    {month: "Январь"}, {month: "Февраль"}, {month: "Март"}, {month: "Апрель"},
];

const Stars = ({rating}) => {
    const fullStars = Math.floor(rating); // Полные звёзды
    const halfStar = rating % 1 !== 0; // Половинка звезды
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0); // Пустые звёзды

    return (
        <div className="stars">
            {/* Полные звёзды */}
            {[...Array(fullStars)].map((_, index) => (
                <img key={index} src="/star-light.svg" alt="star" className="star"/>
            ))}
            {/* Половинка звезды */}
            {halfStar && <img src="/star.svg" alt="half-star" className="star"/>}
            {/* Пустые звёзды */}
            {[...Array(emptyStars)].map((_, index) => (
                <img key={index} src="/star.svg" alt="empty-star" className="star"/>
            ))}
        </div>
    );
};

const ReviewBlock = ({reviews, starLight, starDark}) => {
    return (
        <div className="reviews-container">
            <div className="reviews-header">
                <h2>Отзывы клиентов</h2>
                <div className="rating-summary">
                    <span className="rating">4.2</span>
                    <span className="reviews-count">(отзывов: 12)</span>
                </div>
                <button className="add-review-btn">+ Добавить отзыв</button>
            </div>

            {reviews.map((review, index) => (
                <div key={index} className="review">
                    <div className="review-header">
                        <img src={review.profileImage} alt={review.author} className="profile-photo"/>
                        <div className="review-info">
                            <span className="review-author">{review.author}</span>
                            <span className="review-date">{review.date}</span>
                        </div>
                    </div>
                    <div className="review-rating">
                        <Stars rating={review.rating} starLight={starLight} starDark={starDark}/>
                        <span className="ratings">4.2</span>
                        <span className="deal-status">Сделка состоялась</span>
                    </div>
                    <p className="review-text">
                        {review.text}
                        <a href="#" className="read-more">Читать далее</a>
                    </p>
                    <div className="review-footer">
                        <span className="likes"><p></p> {review.likes}</span>
                        <span className="dislikes"><p></p> {review.dislikes}</span>
                    </div>
                </div>
            ))}

            <button className="view-all-btn">Посмотреть все</button>
        </div>
    );
};

export const CoachItem = () => {
    const {id} = useParams();
    const locationa = useLocation();
    const {products} = locationa.state || {};
    const [currentSlides, setCurrentSlides] = useState({});
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isPhoneHidden, setIsPhoneHidden] = useState(false);
    const togglePhoneVisibility = () => {
        setIsPhoneHidden(!isPhoneHidden);
    };

    const toggleSubscription = () => {
        setIsSubscribed(!isSubscribed);
    };
    console.log('Received products:', products);
    console.log('Product ID:', id);
    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const flatProducts = products.flat();
    const product = flatProducts.find(p => String(p.id) === String(id));
    const {name, price, oldPrice, ageGroup, rating, images, location, about} = product;
    const [isExpanded2, setIsExpanded2] = useState(false);

    const toggleExpand2 = () => {
        setIsExpanded2(!isExpanded2);
    };
    const visiblePrices = isExpanded2 ? prices : prices.slice(0, 1);
    const previewLength = Math.floor(about.length * 0.3);
    const previewText = about.slice(0, previewLength);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    if (!products) {
        return <div>Объявление не найдено</div>;
    }


    console.log('Found product:', product);

    if (!product) {
        return <div>Продукт не найден</div>;
    }

    const settings = (productId) => ({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => handleSlideChange(productId, current),
    });
    const handleSlideChange = (productId, current) => {
        setCurrentSlides((prevState) => ({
            ...prevState,
            [productId]: current,
        }));
    };


    return (
        <div className="container productPG">
            <Header_product/>
            <Slider {...settings(id)}>
                {images && images.map((image, i) => (
                    <img src={image} alt={`Product Image ${i + 1}`} key={i}/>
                ))}
            </Slider>
            <div className="slider-counter">
                {(currentSlides[id] || 0) + 1} из {images.length}
            </div>
            <div className="head__trener">
                <p>От {price} р за услугу</p>
                <h1>{name}</h1>
                <h2>{location}</h2>

                <div className="izb"></div>
            </div>

            <div className="btn__trener">
                {!isPhoneHidden && <a href="">Позвонить</a>}
                <a href="">Написать</a>
            </div>


            <div className="product__admin">
                <div onClick={toggleOpen}><h1>Управление</h1> <p style={{cursor: 'pointer'}}></p></div>

                <div className={`content product__admin ${isOpen ? 'open' : ''}`}>
                    <div className="btn__admin">
                        <a href=""><p></p> Редактировать</a>
                        <a href=""><p></p> Удалить</a>
                    </div>

                    <div className="srok__admin">
                        <span>Срок размещения вашего объявления истекает через <p>29 дн.</p></span>
                        <a href="">Продлить размещение</a>
                    </div>

                    <div className="switch-group prod__sw">
                        <label className="switch-label">
                            <span style={{fontWeight: 400}}>Скрыть мобильный номер</span>
                            <label className="switch">
                                <input type="checkbox" className="hidden" checked={isPhoneHidden}
                                       onChange={togglePhoneVisibility}/>
                                <span className="slider round"></span>
                            </label>
                        </label>
                    </div>

                    {/*<div className="switch-group prod__sw">*/}
                    {/*    <label className="switch-label">*/}
                    {/*        <span style={{ fontWeight: 400 }}>Разместить фотографии в общем перечне объявлений</span>*/}
                    {/*        <label className="switch">*/}
                    {/*            <input type="checkbox" className="hidden" />*/}
                    {/*            <span className="slider round"></span>*/}
                    {/*        </label>*/}
                    {/*    </label>*/}
                    {/*</div>*/}

                    <a href="">Поднять объявление</a>
                </div>
            </div>

            <div className="uslAndPrice">
                <h1>Услуги и цены</h1>

                {visiblePrices.map((price, index) => (
                    <div key={index} style={{marginTop: 4}} className="prices">
                        <div>
                            <span>{price.name}</span>
                            <p>{price.duration}</p>
                        </div>
                        <span>{price.about ? price.about : 'Не указано'}</span>
                    </div>
                ))}

                {prices.length > 1 && (
                    <div className="plaska" onClick={() => setIsExpanded2(!isExpanded2)} style={{cursor: 'pointer'}}>
                        {isExpanded2 ? 'Скрыть' : 'Все услуги'}
                    </div>
                )}
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Подробности</h1>

                <span>Специализация <p>{about}</p></span>
                <span>Опыт работы <p>{about}</p></span>
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Описание</h1>

                {/* Показываем либо сокращенный текст, либо полный */}
                <p>{isExpanded ? about : `${previewText}...`}</p>

                {/* Ссылка для раскрытия текста */}
                <a onClick={toggleExpand} style={{cursor: 'pointer'}}>
                    {isExpanded ? 'Свернуть' : 'Читать далее'}
                </a>
            </div>

            <a onClick={toggleSubscription}
               className={isSubscribed ? 'subscribed' : 'unsubscribed'}
               style={{cursor: 'pointer'}}>
                {isSubscribed ? 'Отписаться ' : 'Подписаться на уведомления'} <p></p>
            </a>

            <div className="info__poshe">
                {/*<span>Размещено: {data}</span>*/}
                {/*<span>Просмотров: {eyes} (сегодня: {realtimeEye})</span>*/}
                {/*<span>Подписчиков: {sub} (сегодня: {realtimeSub})</span>*/}
                {/*<span>Добавленно в избранное: {izb} (сегодня: {realtimeIzb})</span>*/}
            </div>
        </div>
    );
};

export const InsuranceItem= () => {
    const {id} = useParams();
    const locationa = useLocation();
    const {products} = locationa.state || {};
    const [currentSlides, setCurrentSlides] = useState({});
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isPhoneHidden, setIsPhoneHidden] = useState(false);
    const togglePhoneVisibility = () => {
        setIsPhoneHidden(!isPhoneHidden);
    };

    const toggleSubscription = () => {
        setIsSubscribed(!isSubscribed);
    };
    console.log('Received products:', products);
    console.log('Product ID:', id);
    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const flatProducts = products.flat();
    const product = flatProducts.find(p => String(p.id) === String(id));
    const {name, price, oldPrice, ageGroup, rating, images, location, about} = product;
    const [isExpanded2, setIsExpanded2] = useState(false);

    const toggleExpand2 = () => {
        setIsExpanded2(!isExpanded2);
    };
    const visiblePrices = isExpanded2 ? prices : prices.slice(0, 1);
    const previewLength = Math.floor(about.length * 0.3);
    const previewText = about.slice(0, previewLength);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    if (!products) {
        return <div>Объявление не найдено</div>;
    }


    console.log('Found product:', product);

    if (!product) {
        return <div>Продукт не найден</div>;
    }

    const settings = (productId) => ({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => handleSlideChange(productId, current),
    });
    const handleSlideChange = (productId, current) => {
        setCurrentSlides((prevState) => ({
            ...prevState,
            [productId]: current,
        }));
    };


    return (
        <div className="container productPG">
            <Header_product/>
            <Slider {...settings(id)}>
                {images && images.map((image, i) => (
                    <img src={image} alt={`Product Image ${i + 1}`} key={i}/>
                ))}
            </Slider>
            <div className="slider-counter">
                {(currentSlides[id] || 0) + 1} из {images.length}
            </div>
            <div className="head__trener">
                <p>От {price} р за услугу</p>
                <h1>{name}</h1>
                <h2>{location}</h2>

                <div className="izb"></div>
            </div>

            <div className="btn__trener">
                {!isPhoneHidden && <a href="">Позвонить</a>}
                <a href="">Написать</a>
            </div>


            <div className="product__admin">
                <div onClick={toggleOpen}><h1>Управление</h1> <p style={{cursor: 'pointer'}}></p></div>

                <div className={`content product__admin ${isOpen ? 'open' : ''}`}>
                    <div className="btn__admin">
                        <a href=""><p></p> Редактировать</a>
                        <a href=""><p></p> Удалить</a>
                    </div>

                    <div className="srok__admin">
                        <span>Срок размещения вашего объявления истекает через <p>29 дн.</p></span>
                        <a href="">Продлить размещение</a>
                    </div>

                    <div className="switch-group prod__sw">
                        <label className="switch-label">
                            <span style={{fontWeight: 400}}>Скрыть мобильный номер</span>
                            <label className="switch">
                                <input type="checkbox" className="hidden" checked={isPhoneHidden}
                                       onChange={togglePhoneVisibility}/>
                                <span className="slider round"></span>
                            </label>
                        </label>
                    </div>

                    {/*<div className="switch-group prod__sw">*/}
                    {/*    <label className="switch-label">*/}
                    {/*        <span style={{ fontWeight: 400 }}>Разместить фотографии в общем перечне объявлений</span>*/}
                    {/*        <label className="switch">*/}
                    {/*            <input type="checkbox" className="hidden" />*/}
                    {/*            <span className="slider round"></span>*/}
                    {/*        </label>*/}
                    {/*    </label>*/}
                    {/*</div>*/}

                    <a href="">Поднять объявление</a>
                </div>
            </div>

            {/*<div className="uslAndPrice">*/}
            {/*    <h1>Услуги и цены</h1>*/}

            {/*    {visiblePrices.map((price, index) => (*/}
            {/*        <div key={index} style={{ marginTop: 4 }} className="prices">*/}
            {/*            <div>*/}
            {/*                <span>{price.name}</span>*/}
            {/*                <p>{price.duration}</p>*/}
            {/*            </div>*/}
            {/*            <span>{price.about ? price.about : 'Не указано'}</span>*/}
            {/*        </div>*/}
            {/*    ))}*/}

            {/*    {prices.length > 1 && (*/}
            {/*        <div className="plaska" onClick={() => setIsExpanded2(!isExpanded2)} style={{ cursor: 'pointer' }}>*/}
            {/*            {isExpanded2 ? 'Скрыть' : 'Все услуги'}*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Подробности</h1>


                <span>ИНН организации <p>{about}</p></span>
                <span>Лицензия Банка России <p>{about}</p></span>
                <span>Адрес <p>{about}</p></span>
                <span>Дополнительный телефон <a style={{color: "#283DC1", textDecoration: "none"}}
                                                href="">{about}</a></span>
                <span>Сайт <a style={{color: "#283DC1", textDecoration: "none"}} href="">{about}</a></span>
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Режим работы</h1>


                <span>Режим работы: <p>{about}</p></span>
            </div>

            {/*<div className="about__product">*/}
            {/*    <h1 style={{marginBottom:4}}>Выезд к клиенту</h1>*/}


            {/*    <span><p>{about} Lorem ipsum.</p></span>*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Описание</h1>

                {/* Показываем либо сокращенный текст, либо полный */}
                <p>{isExpanded ? about : `${previewText}...`}</p>

                {/* Ссылка для раскрытия текста */}
                <a onClick={toggleExpand} style={{cursor: 'pointer'}}>
                    {isExpanded ? 'Свернуть' : 'Читать далее'}
                </a>
            </div>

            <a onClick={toggleSubscription}
               className={isSubscribed ? 'subscribed' : 'unsubscribed'}
               style={{cursor: 'pointer'}}>
                {isSubscribed ? 'Отписаться ' : 'Подписаться на уведомления'} <p></p>
            </a>

            <div className="info__poshe">
                {/*<span>Размещено: {data}</span>*/}
                {/*<span>Просмотров: {eyes} (сегодня: {realtimeEye})</span>*/}
                {/*<span>Подписчиков: {sub} (сегодня: {realtimeSub})</span>*/}
                {/*<span>Добавленно в избранное: {izb} (сегодня: {realtimeIzb})</span>*/}
            </div>
        </div>
    );
};

export const GoalKeeperItem = () => {
    const {id} = useParams();
    const locationa = useLocation();
    const {products} = locationa.state || {};
    const [currentSlides, setCurrentSlides] = useState({});
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isPhoneHidden, setIsPhoneHidden] = useState(false);
    const togglePhoneVisibility = () => {
        setIsPhoneHidden(!isPhoneHidden);
    };

    const toggleSubscription = () => {
        setIsSubscribed(!isSubscribed);
    };
    console.log('Received products:', products);
    console.log('Product ID:', id);
    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const flatProducts = products.flat();
    const product = flatProducts.find(p => String(p.id) === String(id));
    const {name, price, oldPrice, ageGroup, rating, images, location, about} = product;
    const [isExpanded2, setIsExpanded2] = useState(false);

    const toggleExpand2 = () => {
        setIsExpanded2(!isExpanded2);
    };
    const visiblePrices = isExpanded2 ? prices : prices.slice(0, 1);
    const previewLength = Math.floor(about.length * 0.3);
    const previewText = about.slice(0, previewLength);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    if (!products) {
        return <div>Объявление не найдено</div>;
    }


    console.log('Found product:', product);

    if (!product) {
        return <div>Продукт не найден</div>;
    }

    const settings = (productId) => ({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => handleSlideChange(productId, current),
    });
    const handleSlideChange = (productId, current) => {
        setCurrentSlides((prevState) => ({
            ...prevState,
            [productId]: current,
        }));
    };


    return (
        <div className="container productPG">
            <Header_product/>
            <Slider {...settings(id)}>
                {images && images.map((image, i) => (
                    <img src={image} alt={`Product Image ${i + 1}`} key={i}/>
                ))}
            </Slider>
            <div className="slider-counter">
                {(currentSlides[id] || 0) + 1} из {images.length}
            </div>
            <div className="head__trener">
                <p>От {price} р за услугу</p>
                <h1>{name}</h1>
                <h2>{location}</h2>

                <div className="izb"></div>
            </div>

            <div className="btn__trener">
                {!isPhoneHidden && <a href="">Позвонить</a>}
                <a href="">Написать</a>
            </div>


            <div className="product__admin">
                <div onClick={toggleOpen}><h1>Управление</h1> <p style={{cursor: 'pointer'}}></p></div>

                <div className={`content product__admin ${isOpen ? 'open' : ''}`}>
                    <div className="btn__admin">
                        <a href=""><p></p> Редактировать</a>
                        <a href=""><p></p> Удалить</a>
                    </div>

                    <div className="srok__admin">
                        <span>Срок размещения вашего объявления истекает через <p>29 дн.</p></span>
                        <a href="">Продлить размещение</a>
                    </div>

                    <div className="switch-group prod__sw">
                        <label className="switch-label">
                            <span style={{fontWeight: 400}}>Скрыть мобильный номер</span>
                            <label className="switch">
                                <input type="checkbox" className="hidden" checked={isPhoneHidden}
                                       onChange={togglePhoneVisibility}/>
                                <span className="slider round"></span>
                            </label>
                        </label>
                    </div>

                    {/*<div className="switch-group prod__sw">*/}
                    {/*    <label className="switch-label">*/}
                    {/*        <span style={{ fontWeight: 400 }}>Разместить фотографии в общем перечне объявлений</span>*/}
                    {/*        <label className="switch">*/}
                    {/*            <input type="checkbox" className="hidden" />*/}
                    {/*            <span className="slider round"></span>*/}
                    {/*        </label>*/}
                    {/*    </label>*/}
                    {/*</div>*/}

                    <a href="">Поднять объявление</a>
                </div>
            </div>

            <div className="uslAndPrice">
                <h1>Услуги и цены</h1>

                {visiblePrices.map((price, index) => (
                    <div key={index} style={{marginTop: 4}} className="prices">
                        <div>
                            <span>{price.name}</span>
                            <p>{price.duration}</p>
                        </div>
                        <span>{price.about ? price.about : 'Не указано'}</span>
                    </div>
                ))}

                {prices.length > 1 && (
                    <div className="plaska" onClick={() => setIsExpanded2(!isExpanded2)} style={{cursor: 'pointer'}}>
                        {isExpanded2 ? 'Скрыть' : 'Все услуги'}
                    </div>
                )}
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Подробности</h1>


                <span>Уровень вратаря <p>{about}</p></span>
                <span>Опыт <p>{about}</p></span>
                <span>Стоимость <p>{about}</p></span>
            </div>

            {/*<div className="about__product">*/}
            {/*    <h1 style={{marginBottom:4}}>Режим работы</h1>*/}


            {/*    <span>Режим работы: <p>{about}</p></span>*/}
            {/*</div>*/}

            {/*<div className="about__product">*/}
            {/*    <h1 style={{marginBottom:4}}>Выезд к клиенту</h1>*/}


            {/*    <span><p>{about} Lorem ipsum.</p></span>*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Описание</h1>

                {/* Показываем либо сокращенный текст, либо полный */}
                <p>{isExpanded ? about : `${previewText}...`}</p>

                {/* Ссылка для раскрытия текста */}
                <a onClick={toggleExpand} style={{cursor: 'pointer'}}>
                    {isExpanded ? 'Свернуть' : 'Читать далее'}
                </a>
            </div>

            <a onClick={toggleSubscription}
               className={isSubscribed ? 'subscribed' : 'unsubscribed'}
               style={{cursor: 'pointer'}}>
                {isSubscribed ? 'Отписаться ' : 'Подписаться на уведомления'} <p></p>
            </a>

            <div className="info__poshe">
                {/*<span>Размещено: {data}</span>*/}
                {/*<span>Просмотров: {eyes} (сегодня: {realtimeEye})</span>*/}
                {/*<span>Подписчиков: {sub} (сегодня: {realtimeSub})</span>*/}
                {/*<span>Добавленно в избранное: {izb} (сегодня: {realtimeIzb})</span>*/}
            </div>
        </div>
    );
};

export const RefereeItem = () => {
    const {id} = useParams();
    const locationa = useLocation();
    const {products} = locationa.state || {};
    const [currentSlides, setCurrentSlides] = useState({});
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isPhoneHidden, setIsPhoneHidden] = useState(false);
    const togglePhoneVisibility = () => {
        setIsPhoneHidden(!isPhoneHidden);
    };

    const toggleSubscription = () => {
        setIsSubscribed(!isSubscribed);
    };
    console.log('Received products:', products);
    console.log('Product ID:', id);
    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const flatProducts = products.flat();
    const product = flatProducts.find(p => String(p.id) === String(id));
    const {name, price, oldPrice, ageGroup, rating, images, location, about} = product;
    const [isExpanded2, setIsExpanded2] = useState(false);

    const toggleExpand2 = () => {
        setIsExpanded2(!isExpanded2);
    };
    const visiblePrices = isExpanded2 ? prices : prices.slice(0, 1);
    const previewLength = Math.floor(about.length * 0.3);
    const previewText = about.slice(0, previewLength);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    if (!products) {
        return <div>Объявление не найдено</div>;
    }


    console.log('Found product:', product);

    if (!product) {
        return <div>Продукт не найден</div>;
    }

    const settings = (productId) => ({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => handleSlideChange(productId, current),
    });
    const handleSlideChange = (productId, current) => {
        setCurrentSlides((prevState) => ({
            ...prevState,
            [productId]: current,
        }));
    };


    return (
        <div className="container productPG">
            <Header_product/>
            <Slider {...settings(id)}>
                {images && images.map((image, i) => (
                    <img src={image} alt={`Product Image ${i + 1}`} key={i}/>
                ))}
            </Slider>
            <div className="slider-counter">
                {(currentSlides[id] || 0) + 1} из {images.length}
            </div>
            <div className="head__trener">
                <p>От {price} р за услугу</p>
                <h1>{name}</h1>
                <h2>{location}</h2>

                <div className="izb"></div>
            </div>

            <div className="btn__trener">
                {!isPhoneHidden && <a href="">Позвонить</a>}
                <a href="">Написать</a>
            </div>


            <div className="product__admin">
                <div onClick={toggleOpen}><h1>Управление</h1> <p style={{cursor: 'pointer'}}></p></div>

                <div className={`content product__admin ${isOpen ? 'open' : ''}`}>
                    <div className="btn__admin">
                        <a href=""><p></p> Редактировать</a>
                        <a href=""><p></p> Удалить</a>
                    </div>

                    <div className="srok__admin">
                        <span>Срок размещения вашего объявления истекает через <p>29 дн.</p></span>
                        <a href="">Продлить размещение</a>
                    </div>

                    <div className="switch-group prod__sw">
                        <label className="switch-label">
                            <span style={{fontWeight: 400}}>Скрыть мобильный номер</span>
                            <label className="switch">
                                <input type="checkbox" className="hidden" checked={isPhoneHidden}
                                       onChange={togglePhoneVisibility}/>
                                <span className="slider round"></span>
                            </label>
                        </label>
                    </div>

                    {/*<div className="switch-group prod__sw">*/}
                    {/*    <label className="switch-label">*/}
                    {/*        <span style={{ fontWeight: 400 }}>Разместить фотографии в общем перечне объявлений</span>*/}
                    {/*        <label className="switch">*/}
                    {/*            <input type="checkbox" className="hidden" />*/}
                    {/*            <span className="slider round"></span>*/}
                    {/*        </label>*/}
                    {/*    </label>*/}
                    {/*</div>*/}

                    <a href="">Поднять объявление</a>
                </div>
            </div>

            <div className="uslAndPrice">
                <h1>Услуги и цены</h1>

                {visiblePrices.map((price, index) => (
                    <div key={index} style={{marginTop: 4}} className="prices">
                        <div>
                            <span>{price.name}</span>
                            <p>{price.duration}</p>
                        </div>
                        <span>{price.about ? price.about : 'Не указано'}</span>
                    </div>
                ))}

                {prices.length > 1 && (
                    <div className="plaska" onClick={() => setIsExpanded2(!isExpanded2)} style={{cursor: 'pointer'}}>
                        {isExpanded2 ? 'Скрыть' : 'Все услуги'}
                    </div>
                )}
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Подробности</h1>


                <span>Категория <p>{about}</p></span>
                <span>Опыт <p>{about}</p></span>
                <span>Стоимость <p>{about}</p></span>
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Режим работы</h1>


                <span>Режим работы <p>{about}</p></span>
            </div>

            {/*<div className="about__product">*/}
            {/*    <h1 style={{marginBottom:4}}>Выезд к клиенту</h1>*/}


            {/*    <span><p>{about} Lorem ipsum.</p></span>*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Описание</h1>

                {/* Показываем либо сокращенный текст, либо полный */}
                <p>{isExpanded ? about : `${previewText}...`}</p>

                {/* Ссылка для раскрытия текста */}
                <a onClick={toggleExpand} style={{cursor: 'pointer'}}>
                    {isExpanded ? 'Свернуть' : 'Читать далее'}
                </a>
            </div>

            <a onClick={toggleSubscription}
               className={isSubscribed ? 'subscribed' : 'unsubscribed'}
               style={{cursor: 'pointer'}}>
                {isSubscribed ? 'Отписаться ' : 'Подписаться на уведомления'} <p></p>
            </a>

            <div className="info__poshe">
                {/*<span>Размещено: {data}</span>*/}
                {/*<span>Просмотров: {eyes} (сегодня: {realtimeEye})</span>*/}
                {/*<span>Подписчиков: {sub} (сегодня: {realtimeSub})</span>*/}
                {/*<span>Добавленно в избранное: {izb} (сегодня: {realtimeIzb})</span>*/}
            </div>
        </div>
    );
};

export const BloggerItem = () => {
    const {id} = useParams();
    const locationa = useLocation();
    const {products} = locationa.state || {};
    const [currentSlides, setCurrentSlides] = useState({});
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isPhoneHidden, setIsPhoneHidden] = useState(false);
    const togglePhoneVisibility = () => {
        setIsPhoneHidden(!isPhoneHidden);
    };

    const toggleSubscription = () => {
        setIsSubscribed(!isSubscribed);
    };
    console.log('Received products:', products);
    console.log('Product ID:', id);
    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const flatProducts = products.flat();
    const product = flatProducts.find(p => String(p.id) === String(id));
    const {name, price, oldPrice, ageGroup, rating, images, location, about} = product;
    const [isExpanded2, setIsExpanded2] = useState(false);

    const toggleExpand2 = () => {
        setIsExpanded2(!isExpanded2);
    };
    const visiblePrices = isExpanded2 ? prices : prices.slice(0, 1);
    const previewLength = Math.floor(about.length * 0.3);
    const previewText = about.slice(0, previewLength);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    if (!products) {
        return <div>Объявление не найдено</div>;
    }


    console.log('Found product:', product);

    if (!product) {
        return <div>Продукт не найден</div>;
    }

    const settings = (productId) => ({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => handleSlideChange(productId, current),
    });
    const handleSlideChange = (productId, current) => {
        setCurrentSlides((prevState) => ({
            ...prevState,
            [productId]: current,
        }));
    };


    return (
        <div className="container productPG">
            <Header_product/>
            <Slider {...settings(id)}>
                {images && images.map((image, i) => (
                    <img src={image} alt={`Product Image ${i + 1}`} key={i}/>
                ))}
            </Slider>
            <div className="slider-counter">
                {(currentSlides[id] || 0) + 1} из {images.length}
            </div>
            <div className="head__trener">
                <p>От {price} р за услугу</p>
                <h1>{name}</h1>
                <h2>{location}</h2>

                <div className="izb"></div>
            </div>

            <div className="btn__trener">
                {!isPhoneHidden && <a href="">Позвонить</a>}
                <a href="">Написать</a>
            </div>


            <div className="product__admin">
                <div onClick={toggleOpen}><h1>Управление</h1> <p style={{cursor: 'pointer'}}></p></div>


                <div className={`content product__admin ${isOpen ? 'open' : ''}`}>
                    <p className="tolkVlad">Раздел доступен только для владельца объявления</p>


                    <div className="btn__admin">
                        <a href=""><p></p> Редактировать</a>
                        <a href=""><p></p> Удалить</a>
                    </div>

                    <div className="srok__admin">
                        <span>Срок размещения вашего объявления истекает через <p>29 дн.</p></span>
                        <a href="">Продлить размещение</a>
                    </div>

                    <div className="switch-group prod__sw">
                        <label className="switch-label">
                            <span style={{fontWeight: 400}}>Скрыть мобильный номер</span>
                            <label className="switch">
                                <input type="checkbox" className="hidden" checked={isPhoneHidden}
                                       onChange={togglePhoneVisibility}/>
                                <span className="slider round"></span>
                            </label>
                        </label>
                    </div>

                    {/*<div className="switch-group prod__sw">*/}
                    {/*    <label className="switch-label">*/}
                    {/*        <span style={{ fontWeight: 400 }}>Разместить фотографии в общем перечне объявлений</span>*/}
                    {/*        <label className="switch">*/}
                    {/*            <input type="checkbox" className="hidden" />*/}
                    {/*            <span className="slider round"></span>*/}
                    {/*        </label>*/}
                    {/*    </label>*/}
                    {/*</div>*/}

                    <a href="">Поднять объявление</a>
                </div>
            </div>

            <div className="uslAndPrice">
                <h1>Услуги и цены</h1>

                {visiblePrices.map((price, index) => (
                    <div key={index} style={{marginTop: 4}} className="prices">
                        <div>
                            <span>{price.name}</span>
                            <p>{price.duration}</p>
                        </div>
                        <span>{price.about ? price.about : 'Не указано'}</span>
                    </div>
                ))}

                {prices.length > 1 && (
                    <div className="plaska" onClick={() => setIsExpanded2(!isExpanded2)} style={{cursor: 'pointer'}}>
                        {isExpanded2 ? 'Скрыть' : 'Все услуги'}
                    </div>
                )}
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Подробности</h1>


                <span>Количество подписчиков <p>{about}</p></span>
                <span>Сайт <a style={{color: "#283DC1", textDecoration: "none"}} href="">{about}</a></span>
                <span>Вконтакте <a style={{color: "#283DC1", textDecoration: "none"}} href="">{about}</a></span>
                <span>Дзен <a style={{color: "#283DC1", textDecoration: "none"}} href="">{about}</a></span>
                <span>Youtube <a style={{color: "#283DC1", textDecoration: "none"}} href="">{about}</a></span>
                <span>Telegram <a style={{color: "#283DC1", textDecoration: "none"}} href="">{about}</a></span>
                <span>Дополнительный телефон <a style={{color: "#283DC1", textDecoration: "none"}}
                                                href="">{about}</a></span>
            </div>

            {/*<div className="about__product">*/}
            {/*    <h1 style={{marginBottom:4}}>Режим работы</h1>*/}


            {/*    <span>Режим работы <p>{about}</p></span>*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Акции и скидки</h1>


                <span>Подтягивается с бэка <p>{about}</p></span>
            </div>

            {/*<div className="about__product">*/}
            {/*    <h1 style={{marginBottom:4}}>Выезд к клиенту</h1>*/}


            {/*    <span><p>{about} Lorem ipsum.</p></span>*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Описание</h1>

                {/* Показываем либо сокращенный текст, либо полный */}
                <p>{isExpanded ? about : `${previewText}...`}</p>

                {/* Ссылка для раскрытия текста */}
                <a onClick={toggleExpand} style={{cursor: 'pointer'}}>
                    {isExpanded ? 'Свернуть' : 'Читать далее'}
                </a>
            </div>

            <a onClick={toggleSubscription}
               className={isSubscribed ? 'subscribed' : 'unsubscribed'}
               style={{cursor: 'pointer'}}>
                {isSubscribed ? 'Отписаться ' : 'Подписаться на уведомления'} <p></p>
            </a>

            <div className="info__poshe">
                {/*<span>Размещено: {data}</span>*/}
                {/*<span>Просмотров: {eyes} (сегодня: {realtimeEye})</span>*/}
                {/*<span>Подписчиков: {sub} (сегодня: {realtimeSub})</span>*/}
                {/*<span>Добавленно в избранное: {izb} (сегодня: {realtimeIzb})</span>*/}
            </div>
        </div>
    );
};

export const EquipmentRepairItem = () => {
    const {id} = useParams();
    const locationa = useLocation();
    const {products} = locationa.state || {};
    const [currentSlides, setCurrentSlides] = useState({});
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isPhoneHidden, setIsPhoneHidden] = useState(false);
    const togglePhoneVisibility = () => {
        setIsPhoneHidden(!isPhoneHidden);
    };

    const toggleSubscription = () => {
        setIsSubscribed(!isSubscribed);
    };
    console.log('Received products:', products);
    console.log('Product ID:', id);
    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const flatProducts = products.flat();
    const product = flatProducts.find(p => String(p.id) === String(id));
    const {name, price, oldPrice, ageGroup, rating, images, location, about} = product;
    const [isExpanded2, setIsExpanded2] = useState(false);

    const toggleExpand2 = () => {
        setIsExpanded2(!isExpanded2);
    };
    const visiblePrices = isExpanded2 ? prices : prices.slice(0, 1);
    const previewLength = Math.floor(about.length * 0.3);
    const previewText = about.slice(0, previewLength);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    if (!products) {
        return <div>Объявление не найдено</div>;
    }


    console.log('Found product:', product);

    if (!product) {
        return <div>Продукт не найден</div>;
    }

    const settings = (productId) => ({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => handleSlideChange(productId, current),
    });
    const handleSlideChange = (productId, current) => {
        setCurrentSlides((prevState) => ({
            ...prevState,
            [productId]: current,
        }));
    };


    return (
        <div className="container productPG">
            <Header_product/>
            <Slider {...settings(id)}>
                {images && images.map((image, i) => (
                    <img src={image} alt={`Product Image ${i + 1}`} key={i}/>
                ))}
            </Slider>
            <div className="slider-counter">
                {(currentSlides[id] || 0) + 1} из {images.length}
            </div>
            <div className="head__trener">
                <p>От {price} р за услугу</p>
                <h1>{name}</h1>
                <h2>{location}</h2>

                <div className="izb"></div>
            </div>

            <div className="btn__trener">
                {!isPhoneHidden && <a href="">Позвонить</a>}
                <a href="">Написать</a>
            </div>


            <div className="product__admin">
                <div onClick={toggleOpen}><h1>Управление</h1> <p style={{cursor: 'pointer'}}></p></div>


                <div className={`content product__admin ${isOpen ? 'open' : ''}`}>
                    <p className="tolkVlad">Раздел доступен только для владельца объявления</p>


                    <div className="btn__admin">
                        <a href=""><p></p> Редактировать</a>
                        <a href=""><p></p> Удалить</a>
                    </div>

                    <div className="srok__admin">
                        <span>Срок размещения вашего объявления истекает через <p>29 дн.</p></span>
                        <a href="">Продлить размещение</a>
                    </div>

                    <div className="switch-group prod__sw">
                        <label className="switch-label">
                            <span style={{fontWeight: 400}}>Скрыть мобильный номер</span>
                            <label className="switch">
                                <input type="checkbox" className="hidden" checked={isPhoneHidden}
                                       onChange={togglePhoneVisibility}/>
                                <span className="slider round"></span>
                            </label>
                        </label>
                    </div>

                    {/*<div className="switch-group prod__sw">*/}
                    {/*    <label className="switch-label">*/}
                    {/*        <span style={{ fontWeight: 400 }}>Разместить фотографии в общем перечне объявлений</span>*/}
                    {/*        <label className="switch">*/}
                    {/*            <input type="checkbox" className="hidden" />*/}
                    {/*            <span className="slider round"></span>*/}
                    {/*        </label>*/}
                    {/*    </label>*/}
                    {/*</div>*/}

                    <a href="">Поднять объявление</a>
                </div>
            </div>

            {/*<div className="uslAndPrice">*/}
            {/*    <h1>Услуги и цены</h1>*/}

            {/*    {visiblePrices.map((price, index) => (*/}
            {/*        <div key={index} style={{ marginTop: 4 }} className="prices">*/}
            {/*            <div>*/}
            {/*                <span>{price.name}</span>*/}
            {/*                <p>{price.duration}</p>*/}
            {/*            </div>*/}
            {/*            <span>{price.about ? price.about : 'Не указано'}</span>*/}
            {/*        </div>*/}
            {/*    ))}*/}

            {/*    {prices.length > 1 && (*/}
            {/*        <div className="plaska" onClick={() => setIsExpanded2(!isExpanded2)} style={{ cursor: 'pointer' }}>*/}
            {/*            {isExpanded2 ? 'Скрыть' : 'Все услуги'}*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Подробности</h1>


                <span>ИНН организации <p>{about}</p></span>
                <span>Категория <a style={{color: "#283DC1", textDecoration: "none"}} href="">{about}</a></span>
                <span>Сайт <a style={{color: "#283DC1", textDecoration: "none"}} href="">{about}</a></span>
                <span>Дополнительный телефон <a style={{color: "#283DC1", textDecoration: "none"}}
                                                href="">{about}</a></span>
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Режим работы</h1>


                <span>Режим работы <p>{about}</p></span>
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Акции и скидки</h1>


                <span>Подтягивается с бэка <p>{about}</p></span>
            </div>

            {/*<div className="about__product">*/}
            {/*    <h1 style={{marginBottom:4}}>Выезд к клиенту</h1>*/}


            {/*    <span><p>{about} Lorem ipsum.</p></span>*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Описание</h1>

                {/* Показываем либо сокращенный текст, либо полный */}
                <p>{isExpanded ? about : `${previewText}...`}</p>

                {/* Ссылка для раскрытия текста */}
                <a onClick={toggleExpand} style={{cursor: 'pointer'}}>
                    {isExpanded ? 'Свернуть' : 'Читать далее'}
                </a>
            </div>

            <a onClick={toggleSubscription}
               className={isSubscribed ? 'subscribed' : 'unsubscribed'}
               style={{cursor: 'pointer'}}>
                {isSubscribed ? 'Отписаться ' : 'Подписаться на уведомления'} <p></p>
            </a>

            <div className="info__poshe">
                {/*<span>Размещено: {data}</span>*/}
                {/*<span>Просмотров: {eyes} (сегодня: {realtimeEye})</span>*/}
                {/*<span>Подписчиков: {sub} (сегодня: {realtimeSub})</span>*/}
                {/*<span>Добавленно в избранное: {izb} (сегодня: {realtimeIzb})</span>*/}
            </div>
        </div>
    );
};

export const EquipmentTailoringItem = () => {
    const {id} = useParams();
    const locationa = useLocation();
    const {products} = locationa.state || {};
    const [currentSlides, setCurrentSlides] = useState({});
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isPhoneHidden, setIsPhoneHidden] = useState(false);
    const togglePhoneVisibility = () => {
        setIsPhoneHidden(!isPhoneHidden);
    };

    const toggleSubscription = () => {
        setIsSubscribed(!isSubscribed);
    };
    console.log('Received products:', products);
    console.log('Product ID:', id);
    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const flatProducts = products.flat();
    const product = flatProducts.find(p => String(p.id) === String(id));
    const {name, price, oldPrice, ageGroup, rating, images, location, about} = product;
    const [isExpanded2, setIsExpanded2] = useState(false);

    const toggleExpand2 = () => {
        setIsExpanded2(!isExpanded2);
    };
    const visiblePrices = isExpanded2 ? prices : prices.slice(0, 1);
    const previewLength = Math.floor(about.length * 0.3);
    const previewText = about.slice(0, previewLength);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    if (!products) {
        return <div>Объявление не найдено</div>;
    }


    console.log('Found product:', product);

    if (!product) {
        return <div>Продукт не найден</div>;
    }

    const settings = (productId) => ({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => handleSlideChange(productId, current),
    });
    const handleSlideChange = (productId, current) => {
        setCurrentSlides((prevState) => ({
            ...prevState,
            [productId]: current,
        }));
    };


    return (
        <div className="container productPG">
            <Header_product/>
            <Slider {...settings(id)}>
                {images && images.map((image, i) => (
                    <img src={image} alt={`Product Image ${i + 1}`} key={i}/>
                ))}
            </Slider>
            <div className="slider-counter">
                {(currentSlides[id] || 0) + 1} из {images.length}
            </div>
            <div className="head__trener">
                <p>От {price} р за услугу</p>
                <h1>{name}</h1>
                <h2>{location}</h2>

                <div className="izb"></div>
            </div>

            <div className="btn__trener">
                {!isPhoneHidden && <a href="">Позвонить</a>}
                <a href="">Написать</a>
            </div>


            <div className="product__admin">
                <div onClick={toggleOpen}><h1>Управление</h1> <p style={{cursor: 'pointer'}}></p></div>


                <div className={`content product__admin ${isOpen ? 'open' : ''}`}>
                    <p className="tolkVlad">Раздел доступен только для владельца объявления</p>


                    <div className="btn__admin">
                        <a href=""><p></p> Редактировать</a>
                        <a href=""><p></p> Удалить</a>
                    </div>

                    <div className="srok__admin">
                        <span>Срок размещения вашего объявления истекает через <p>29 дн.</p></span>
                        <a href="">Продлить размещение</a>
                    </div>

                    <div className="switch-group prod__sw">
                        <label className="switch-label">
                            <span style={{fontWeight: 400}}>Скрыть мобильный номер</span>
                            <label className="switch">
                                <input type="checkbox" className="hidden" checked={isPhoneHidden}
                                       onChange={togglePhoneVisibility}/>
                                <span className="slider round"></span>
                            </label>
                        </label>
                    </div>

                    {/*<div className="switch-group prod__sw">*/}
                    {/*    <label className="switch-label">*/}
                    {/*        <span style={{ fontWeight: 400 }}>Разместить фотографии в общем перечне объявлений</span>*/}
                    {/*        <label className="switch">*/}
                    {/*            <input type="checkbox" className="hidden" />*/}
                    {/*            <span className="slider round"></span>*/}
                    {/*        </label>*/}
                    {/*    </label>*/}
                    {/*</div>*/}

                    <a href="">Поднять объявление</a>
                </div>
            </div>

            {/*<div className="uslAndPrice">*/}
            {/*    <h1>Услуги и цены</h1>*/}

            {/*    {visiblePrices.map((price, index) => (*/}
            {/*        <div key={index} style={{ marginTop: 4 }} className="prices">*/}
            {/*            <div>*/}
            {/*                <span>{price.name}</span>*/}
            {/*                <p>{price.duration}</p>*/}
            {/*            </div>*/}
            {/*            <span>{price.about ? price.about : 'Не указано'}</span>*/}
            {/*        </div>*/}
            {/*    ))}*/}

            {/*    {prices.length > 1 && (*/}
            {/*        <div className="plaska" onClick={() => setIsExpanded2(!isExpanded2)} style={{ cursor: 'pointer' }}>*/}
            {/*            {isExpanded2 ? 'Скрыть' : 'Все услуги'}*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Подробности</h1>


                <span>ИНН организации <p>{about}</p></span>
                <span>Сайт <a style={{color: "#283DC1", textDecoration: "none"}} href="">{about}</a></span>
                <span>Дополнительный телефон <a style={{color: "#283DC1", textDecoration: "none"}}
                                                href="">{about}</a></span>
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Режим работы</h1>


                <span>Режим работы <p>{about}</p></span>
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Акции и скидки</h1>


                <span>Подтягивается с бэка <p>{about}</p></span>
            </div>

            {/*<div className="about__product">*/}
            {/*    <h1 style={{marginBottom:4}}>Выезд к клиенту</h1>*/}


            {/*    <span><p>{about} Lorem ipsum.</p></span>*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Описание</h1>

                {/* Показываем либо сокращенный текст, либо полный */}
                <p>{isExpanded ? about : `${previewText}...`}</p>

                {/* Ссылка для раскрытия текста */}
                <a onClick={toggleExpand} style={{cursor: 'pointer'}}>
                    {isExpanded ? 'Свернуть' : 'Читать далее'}
                </a>
            </div>

            <a onClick={toggleSubscription}
               className={isSubscribed ? 'subscribed' : 'unsubscribed'}
               style={{cursor: 'pointer'}}>
                {isSubscribed ? 'Отписаться ' : 'Подписаться на уведомления'} <p></p>
            </a>

            <div className="info__poshe">
                {/*<span>Размещено: {data}</span>*/}
                {/*<span>Просмотров: {eyes} (сегодня: {realtimeEye})</span>*/}
                {/*<span>Подписчиков: {sub} (сегодня: {realtimeSub})</span>*/}
                {/*<span>Добавленно в избранное: {izb} (сегодня: {realtimeIzb})</span>*/}
            </div>
        </div>
    );
};

export const DryCleaningItem = () => {
    const {id} = useParams();
    const locationa = useLocation();
    const {products} = locationa.state || {};
    const [currentSlides, setCurrentSlides] = useState({});
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isPhoneHidden, setIsPhoneHidden] = useState(false);
    const togglePhoneVisibility = () => {
        setIsPhoneHidden(!isPhoneHidden);
    };

    const toggleSubscription = () => {
        setIsSubscribed(!isSubscribed);
    };
    console.log('Received products:', products);
    console.log('Product ID:', id);
    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const flatProducts = products.flat();
    const product = flatProducts.find(p => String(p.id) === String(id));
    const {name, price, oldPrice, ageGroup, rating, images, location, about} = product;
    const [isExpanded2, setIsExpanded2] = useState(false);

    const toggleExpand2 = () => {
        setIsExpanded2(!isExpanded2);
    };
    const visiblePrices = isExpanded2 ? prices : prices.slice(0, 1);
    const previewLength = Math.floor(about.length * 0.3);
    const previewText = about.slice(0, previewLength);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    if (!products) {
        return <div>Объявление не найдено</div>;
    }


    console.log('Found product:', product);

    if (!product) {
        return <div>Продукт не найден</div>;
    }

    const settings = (productId) => ({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => handleSlideChange(productId, current),
    });
    const handleSlideChange = (productId, current) => {
        setCurrentSlides((prevState) => ({
            ...prevState,
            [productId]: current,
        }));
    };


    return (
        <div className="container productPG">
            <Header_product/>
            <Slider {...settings(id)}>
                {images && images.map((image, i) => (
                    <img src={image} alt={`Product Image ${i + 1}`} key={i}/>
                ))}
            </Slider>
            <div className="slider-counter">
                {(currentSlides[id] || 0) + 1} из {images.length}
            </div>
            <div className="head__trener">
                <p>От {price} р за услугу</p>
                <h1>{name}</h1>
                <h2>{location}</h2>

                <div className="izb"></div>
            </div>

            <div className="btn__trener">
                {!isPhoneHidden && <a href="">Позвонить</a>}
                <a href="">Написать</a>
            </div>


            <div className="product__admin">
                <div onClick={toggleOpen}><h1>Управление</h1> <p style={{cursor: 'pointer'}}></p></div>


                <div className={`content product__admin ${isOpen ? 'open' : ''}`}>
                    <p className="tolkVlad">Раздел доступен только для владельца объявления</p>


                    <div className="btn__admin">
                        <a href=""><p></p> Редактировать</a>
                        <a href=""><p></p> Удалить</a>
                    </div>

                    <div className="srok__admin">
                        <span>Срок размещения вашего объявления истекает через <p>29 дн.</p></span>
                        <a href="">Продлить размещение</a>
                    </div>

                    <div className="switch-group prod__sw">
                        <label className="switch-label">
                            <span style={{fontWeight: 400}}>Скрыть мобильный номер</span>
                            <label className="switch">
                                <input type="checkbox" className="hidden" checked={isPhoneHidden}
                                       onChange={togglePhoneVisibility}/>
                                <span className="slider round"></span>
                            </label>
                        </label>
                    </div>

                    {/*<div className="switch-group prod__sw">*/}
                    {/*    <label className="switch-label">*/}
                    {/*        <span style={{ fontWeight: 400 }}>Разместить фотографии в общем перечне объявлений</span>*/}
                    {/*        <label className="switch">*/}
                    {/*            <input type="checkbox" className="hidden" />*/}
                    {/*            <span className="slider round"></span>*/}
                    {/*        </label>*/}
                    {/*    </label>*/}
                    {/*</div>*/}

                    <a href="">Поднять объявление</a>
                </div>
            </div>

            {/*<div className="uslAndPrice">*/}
            {/*    <h1>Услуги и цены</h1>*/}

            {/*    {visiblePrices.map((price, index) => (*/}
            {/*        <div key={index} style={{ marginTop: 4 }} className="prices">*/}
            {/*            <div>*/}
            {/*                <span>{price.name}</span>*/}
            {/*                <p>{price.duration}</p>*/}
            {/*            </div>*/}
            {/*            <span>{price.about ? price.about : 'Не указано'}</span>*/}
            {/*        </div>*/}
            {/*    ))}*/}

            {/*    {prices.length > 1 && (*/}
            {/*        <div className="plaska" onClick={() => setIsExpanded2(!isExpanded2)} style={{ cursor: 'pointer' }}>*/}
            {/*            {isExpanded2 ? 'Скрыть' : 'Все услуги'}*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Подробности</h1>


                <span>ИНН организации <p>{about}</p></span>
                <span>Сайт <a style={{color: "#283DC1", textDecoration: "none"}} href="">{about}</a></span>
                <span>Дополнительный телефон <a style={{color: "#283DC1", textDecoration: "none"}}
                                                href="">{about}</a></span>
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Режим работы</h1>


                <span>Режим работы <p>{about}</p></span>
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Акции и скидки</h1>


                <span>Подтягивается с бэка <p>{about}</p></span>
            </div>

            {/*<div className="about__product">*/}
            {/*    <h1 style={{marginBottom:4}}>Выезд к клиенту</h1>*/}


            {/*    <span><p>{about} Lorem ipsum.</p></span>*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Описание</h1>

                {/* Показываем либо сокращенный текст, либо полный */}
                <p>{isExpanded ? about : `${previewText}...`}</p>

                {/* Ссылка для раскрытия текста */}
                <a onClick={toggleExpand}>
                    {isExpanded ? 'Свернуть' : 'Читать далее'}
                </a>
            </div>

            <a onClick={toggleSubscription}
               className={isSubscribed ? 'subscribed' : 'unsubscribed'}
               style={{cursor: 'pointer'}}>
                {isSubscribed ? 'Отписаться ' : 'Подписаться на уведомления'} <p></p>
            </a>

            <div className="info__poshe">
                {/*<span>Размещено: {data}</span>*/}
                {/*<span>Просмотров: {eyes} (сегодня: {realtimeEye})</span>*/}
                {/*<span>Подписчиков: {sub} (сегодня: {realtimeSub})</span>*/}
                {/*<span>Добавленно в избранное: {izb} (сегодня: {realtimeIzb})</span>*/}
            </div>
        </div>
    );
};

export const TournamentItem = () => {
    const {id} = useParams();
    const locationa = useLocation();
    const {products} = locationa.state || {};
    const [currentSlides, setCurrentSlides] = useState({});
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isPhoneHidden, setIsPhoneHidden] = useState(false);
    const togglePhoneVisibility = () => {
        setIsPhoneHidden(!isPhoneHidden);
    };

    const toggleSubscription = () => {
        setIsSubscribed(!isSubscribed);
    };
    console.log('Received products:', products);
    console.log('Product ID:', id);
    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const flatProducts = products.flat();
    const product = flatProducts.find(p => String(p.id) === String(id));
    const {name, price, oldPrice, ageGroup, rating, images, location, about} = product;
    const [isExpanded2, setIsExpanded2] = useState(false);

    const toggleExpand2 = () => {
        setIsExpanded2(!isExpanded2);
    };
    const visiblePrices = isExpanded2 ? prices : prices.slice(0, 1);
    const previewLength = Math.floor(about.length * 0.3);
    const previewText = about.slice(0, previewLength);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    if (!products) {
        return <div>Объявление не найдено</div>;
    }


    console.log('Found product:', product);

    if (!product) {
        return <div>Продукт не найден</div>;
    }

    const settings = (productId) => ({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => handleSlideChange(productId, current),
    });
    const handleSlideChange = (productId, current) => {
        setCurrentSlides((prevState) => ({
            ...prevState,
            [productId]: current,
        }));
    };


    return (
        <div className="container productPG">
            <Header_product/>
            <Slider {...settings(id)}>
                {images && images.map((image, i) => (
                    <img src={image} alt={`Product Image ${i + 1}`} key={i}/>
                ))}
            </Slider>
            <div className="slider-counter">
                {(currentSlides[id] || 0) + 1} из {images.length}
            </div>
            <div className="head__trener">
                <p>От {price} р за услугу</p>
                <h1>{name}</h1>
                <h2>{location}</h2>

                <div className="izb"></div>
            </div>

            <div className="btn__trener">
                {!isPhoneHidden && <a href="">Позвонить</a>}
                <a href="">Написать</a>
            </div>


            <div className="product__admin">
                <div onClick={toggleOpen}><h1>Управление</h1> <p style={{cursor: 'pointer'}}></p></div>


                <div className={`content product__admin ${isOpen ? 'open' : ''}`}>
                    <p className="tolkVlad">Раздел доступен только для владельца объявления</p>


                    <div className="btn__admin">
                        <a href=""><p></p> Редактировать</a>
                        <a href=""><p></p> Удалить</a>
                    </div>

                    <div className="srok__admin">
                        <span>Срок размещения вашего объявления истекает через <p>29 дн.</p></span>
                        <a href="">Продлить размещение</a>
                    </div>

                    <div className="switch-group prod__sw">
                        <label className="switch-label">
                            <span style={{fontWeight: 400}}>Скрыть мобильный номер</span>
                            <label className="switch">
                                <input type="checkbox" className="hidden" checked={isPhoneHidden}
                                       onChange={togglePhoneVisibility}/>
                                <span className="slider round"></span>
                            </label>
                        </label>
                    </div>

                    {/*<div className="switch-group prod__sw">*/}
                    {/*    <label className="switch-label">*/}
                    {/*        <span style={{ fontWeight: 400 }}>Разместить фотографии в общем перечне объявлений</span>*/}
                    {/*        <label className="switch">*/}
                    {/*            <input type="checkbox" className="hidden" />*/}
                    {/*            <span className="slider round"></span>*/}
                    {/*        </label>*/}
                    {/*    </label>*/}
                    {/*</div>*/}

                    <a href="">Поднять объявление</a>
                </div>
            </div>

            {/*<div className="uslAndPrice">*/}
            {/*    <h1>Услуги и цены</h1>*/}

            {/*    {visiblePrices.map((price, index) => (*/}
            {/*        <div key={index} style={{ marginTop: 4 }} className="prices">*/}
            {/*            <div>*/}
            {/*                <span>{price.name}</span>*/}
            {/*                <p>{price.duration}</p>*/}
            {/*            </div>*/}
            {/*            <span>{price.about ? price.about : 'Не указано'}</span>*/}
            {/*        </div>*/}
            {/*    ))}*/}

            {/*    {prices.length > 1 && (*/}
            {/*        <div className="plaska" onClick={() => setIsExpanded2(!isExpanded2)} style={{ cursor: 'pointer' }}>*/}
            {/*            {isExpanded2 ? 'Скрыть' : 'Все услуги'}*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Подробности</h1>

                <span>Тип соревнования <p>{about}</p></span>
                <span>Период проведения <p>{about}</p></span>
                <span>Возрастная категория <p>{about}</p></span>
                <span>Формат <p>{about}</p></span>
                <span>Ледовая арена <p>{about}</p></span>
                <span>Адрес ледовой арены <p>{about}</p></span>
                <span>Следж-хоккей <p>{about}</p></span>
                <span>Сайт <a style={{color: "#283DC1", textDecoration: "none"}} href="">{about}</a></span>
                <span>Дополнительный телефон <a style={{color: "#283DC1", textDecoration: "none"}}
                                                href="">{about}</a></span>
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Режим работы</h1>


                <span>Режим работы <p>{about}</p></span>
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Акции и скидки</h1>


                <span>Подтягивается с бэка <p>{about}</p></span>
            </div>

            {/*<div className="about__product">*/}
            {/*    <h1 style={{marginBottom:4}}>Выезд к клиенту</h1>*/}


            {/*    <span><p>{about} Lorem ipsum.</p></span>*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Описание</h1>

                {/* Показываем либо сокращенный текст, либо полный */}
                <p>{isExpanded ? about : `${previewText}...`}</p>

                {/* Ссылка для раскрытия текста */}
                <a onClick={toggleExpand} style={{cursor: 'pointer'}}>
                    {isExpanded ? 'Свернуть' : 'Читать далее'}
                </a>
            </div>

            <a onClick={toggleSubscription}
               className={isSubscribed ? 'subscribed' : 'unsubscribed'}
               style={{cursor: 'pointer'}}>
                {isSubscribed ? 'Отписаться ' : 'Подписаться на уведомления'} <p></p>
            </a>

            <div className="info__poshe">
                {/*<span>Размещено: {data}</span>*/}
                {/*<span>Просмотров: {eyes} (сегодня: {realtimeEye})</span>*/}
                {/*<span>Подписчиков: {sub} (сегодня: {realtimeSub})</span>*/}
                {/*<span>Добавленно в избранное: {izb} (сегодня: {realtimeIzb})</span>*/}
            </div>
        </div>
    );
};

export const EquipmentItem = () => {
    const {id} = useParams();
    const locationa = useLocation();
    const {products} = locationa.state || {};
    const [currentSlides, setCurrentSlides] = useState({});
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isPhoneHidden, setIsPhoneHidden] = useState(false);
    const togglePhoneVisibility = () => {
        setIsPhoneHidden(!isPhoneHidden);
    };

    const toggleSubscription = () => {
        setIsSubscribed(!isSubscribed);
    };
    console.log('Received products:', products);
    console.log('Product ID:', id);
    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const flatProducts = products.flat();
    const product = flatProducts.find(p => String(p.id) === String(id));
    const {name, price, oldPrice, ageGroup, rating, images, location, about} = product;
    const [isExpanded2, setIsExpanded2] = useState(false);

    const toggleExpand2 = () => {
        setIsExpanded2(!isExpanded2);
    };
    const visiblePrices = isExpanded2 ? prices : prices.slice(0, 1);
    const previewLength = Math.floor(about.length * 0.3);
    const previewText = about.slice(0, previewLength);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    if (!products) {
        return <div>Объявление не найдено</div>;
    }


    console.log('Found product:', product);

    if (!product) {
        return <div>Продукт не найден</div>;
    }

    const settings = (productId) => ({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => handleSlideChange(productId, current),
    });
    const handleSlideChange = (productId, current) => {
        setCurrentSlides((prevState) => ({
            ...prevState,
            [productId]: current,
        }));
    };


    return (
        <div className="container productPG">
            <Header_product/>
            <Slider {...settings(id)}>
                {images && images.map((image, i) => (
                    <img src={image} alt={`Product Image ${i + 1}`} key={i}/>
                ))}
            </Slider>
            <div className="slider-counter">
                {(currentSlides[id] || 0) + 1} из {images.length}
            </div>
            <div className="head__trener">
                <p>От {price} р за услугу</p>
                <h1>{name}</h1>
                <h2>{location}</h2>

                <div className="izb"></div>
            </div>

            <div className="btn__trener">
                {!isPhoneHidden && <a href="">Позвонить</a>}
                <a href="">Написать</a>
            </div>


            <div className="product__admin">
                <div onClick={toggleOpen}><h1>Управление</h1> <p style={{cursor: 'pointer'}}></p></div>


                <div className={`content product__admin ${isOpen ? 'open' : ''}`}>
                    <p className="tolkVlad">Раздел доступен только для владельца объявления</p>


                    <div className="btn__admin">
                        <a href=""><p></p> Редактировать</a>
                        <a href=""><p></p> Удалить</a>
                    </div>

                    <div className="srok__admin">
                        <span>Срок размещения вашего объявления истекает через <p>29 дн.</p></span>
                        <a href="">Продлить размещение</a>
                    </div>

                    <div className="switch-group prod__sw">
                        <label className="switch-label">
                            <span style={{fontWeight: 400}}>Скрыть мобильный номер</span>
                            <label className="switch">
                                <input type="checkbox" className="hidden" checked={isPhoneHidden}
                                       onChange={togglePhoneVisibility}/>
                                <span className="slider round"></span>
                            </label>
                        </label>
                    </div>

                    {/*<div className="switch-group prod__sw">*/}
                    {/*    <label className="switch-label">*/}
                    {/*        <span style={{ fontWeight: 400 }}>Разместить фотографии в общем перечне объявлений</span>*/}
                    {/*        <label className="switch">*/}
                    {/*            <input type="checkbox" className="hidden" />*/}
                    {/*            <span className="slider round"></span>*/}
                    {/*        </label>*/}
                    {/*    </label>*/}
                    {/*</div>*/}

                    <a href="">Поднять объявление</a>
                </div>
            </div>

            {/*<div className="uslAndPrice">*/}
            {/*    <h1>Услуги и цены</h1>*/}

            {/*    {visiblePrices.map((price, index) => (*/}
            {/*        <div key={index} style={{ marginTop: 4 }} className="prices">*/}
            {/*            <div>*/}
            {/*                <span>{price.name}</span>*/}
            {/*                <p>{price.duration}</p>*/}
            {/*            </div>*/}
            {/*            <span>{price.about ? price.about : 'Не указано'}</span>*/}
            {/*        </div>*/}
            {/*    ))}*/}

            {/*    {prices.length > 1 && (*/}
            {/*        <div className="plaska" onClick={() => setIsExpanded2(!isExpanded2)} style={{ cursor: 'pointer' }}>*/}
            {/*            {isExpanded2 ? 'Скрыть' : 'Все услуги'}*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Подробности</h1>

                <span>Категория <p>{about}</p></span>
                <span>Состояние <p>{about}</p></span>
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Режим работы</h1>

                <span>Режим работы <p>{about}</p></span>
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Акции и скидки</h1>

                <span>Подтягивается с бэка <p>{about}</p></span>
            </div>

            {/*<div className="about__product">*/}
            {/*    <h1 style={{marginBottom:4}}>Выезд к клиенту</h1>*/}


            {/*    <span><p>{about} Lorem ipsum.</p></span>*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Описание</h1>

                {/* Показываем либо сокращенный текст, либо полный */}
                <p>{isExpanded ? about : `${previewText}...`}</p>

                {/* Ссылка для раскрытия текста */}
                <a onClick={toggleExpand} style={{cursor: 'pointer'}}>
                    {isExpanded ? 'Свернуть' : 'Читать далее'}
                </a>
            </div>

            <a onClick={toggleSubscription}
               className={isSubscribed ? 'subscribed' : 'unsubscribed'}
               style={{cursor: 'pointer'}}>
                {isSubscribed ? 'Отписаться ' : 'Подписаться на уведомления'} <p></p>
            </a>

            <div className="info__poshe">
                {/*<span>Размещено: {data}</span>*/}
                {/*<span>Просмотров: {eyes} (сегодня: {realtimeEye})</span>*/}
                {/*<span>Подписчиков: {sub} (сегодня: {realtimeSub})</span>*/}
                {/*<span>Добавленно в избранное: {izb} (сегодня: {realtimeIzb})</span>*/}
            </div>
        </div>
    );
};

export const TrainingItem = () => {
    const {id} = useParams();
    const locationa = useLocation();
    const {products} = locationa.state || {};
    const [currentSlides, setCurrentSlides] = useState({});
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isPhoneHidden, setIsPhoneHidden] = useState(false);
    const togglePhoneVisibility = () => {
        setIsPhoneHidden(!isPhoneHidden);
    };

    const toggleSubscription = () => {
        setIsSubscribed(!isSubscribed);
    };
    console.log('Received products:', products);
    console.log('Product ID:', id);
    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const flatProducts = products.flat();
    const product = flatProducts.find(p => String(p.id) === String(id));
    const {name, price, oldPrice, ageGroup, rating, images, location, about} = product;
    const [isExpanded2, setIsExpanded2] = useState(false);

    const toggleExpand2 = () => {
        setIsExpanded2(!isExpanded2);
    };
    const visiblePrices = isExpanded2 ? prices : prices.slice(0, 1);
    const previewLength = Math.floor(about.length * 0.3);
    const previewText = about.slice(0, previewLength);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    if (!products) {
        return <div>Объявление не найдено</div>;
    }


    console.log('Found product:', product);

    if (!product) {
        return <div>Продукт не найден</div>;
    }

    const settings = (productId) => ({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => handleSlideChange(productId, current),
    });
    const handleSlideChange = (productId, current) => {
        setCurrentSlides((prevState) => ({
            ...prevState,
            [productId]: current,
        }));
    };


    return (
        <div className="container productPG">
            <Header_product/>
            <Slider {...settings(id)}>
                {images && images.map((image, i) => (
                    <img src={image} alt={`Product Image ${i + 1}`} key={i}/>
                ))}
            </Slider>
            <div className="slider-counter">
                {(currentSlides[id] || 0) + 1} из {images.length}
            </div>
            <div className="head__trener">
                <p>От {price} р за услугу</p>
                <h1>{name}</h1>
                <h2>{location}</h2>

                <div className="izb"></div>
            </div>

            <div className="btn__trener">
                {!isPhoneHidden && <a href="">Позвонить</a>}
                <a href="">Написать</a>
            </div>


            <div className="product__admin">
                <div onClick={toggleOpen}><h1>Управление</h1> <p style={{cursor: 'pointer'}}></p></div>


                <div className={`content product__admin ${isOpen ? 'open' : ''}`}>
                    <p className="tolkVlad">Раздел доступен только для владельца объявления</p>


                    <div className="btn__admin">
                        <a href=""><p></p> Редактировать</a>
                        <a href=""><p></p> Удалить</a>
                    </div>

                    <div className="srok__admin">
                        <span>Срок размещения вашего объявления истекает через <p>29 дн.</p></span>
                        <a href="">Продлить размещение</a>
                    </div>

                    <div className="switch-group prod__sw">
                        <label className="switch-label">
                            <span style={{fontWeight: 400}}>Скрыть мобильный номер</span>
                            <label className="switch">
                                <input type="checkbox" className="hidden" checked={isPhoneHidden}
                                       onChange={togglePhoneVisibility}/>
                                <span className="slider round"></span>
                            </label>
                        </label>
                    </div>

                    {/*<div className="switch-group prod__sw">*/}
                    {/*    <label className="switch-label">*/}
                    {/*        <span style={{ fontWeight: 400 }}>Разместить фотографии в общем перечне объявлений</span>*/}
                    {/*        <label className="switch">*/}
                    {/*            <input type="checkbox" className="hidden" />*/}
                    {/*            <span className="slider round"></span>*/}
                    {/*        </label>*/}
                    {/*    </label>*/}
                    {/*</div>*/}

                    <a href="">Поднять объявление</a>
                </div>
            </div>

            {/*<div className="uslAndPrice">*/}
            {/*    <h1>Услуги и цены</h1>*/}

            {/*    {visiblePrices.map((price, index) => (*/}
            {/*        <div key={index} style={{ marginTop: 4 }} className="prices">*/}
            {/*            <div>*/}
            {/*                <span>{price.name}</span>*/}
            {/*                <p>{price.duration}</p>*/}
            {/*            </div>*/}
            {/*            <span>{price.about ? price.about : 'Не указано'}</span>*/}
            {/*        </div>*/}
            {/*    ))}*/}

            {/*    {prices.length > 1 && (*/}
            {/*        <div className="plaska" onClick={() => setIsExpanded2(!isExpanded2)} style={{ cursor: 'pointer' }}>*/}
            {/*            {isExpanded2 ? 'Скрыть' : 'Все услуги'}*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Подробности</h1>

                <span>Дата начала <p>{about}</p></span>
                <span>Дата завершения <p>{about}</p></span>
                <span>Возрастная категория <p>{about}</p></span>
                <span>Уровень игроков <p>{about}</p></span>
                <span>Следж-хоккей <p>{about}</p></span>
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Режим работы</h1>

                <span>Режим работы <p>{about}</p></span>
            </div>

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Акции и скидки</h1>

                <span>Подтягивается с бэка <p>{about}</p></span>
            </div>

            {/*<div className="about__product">*/}
            {/*    <h1 style={{marginBottom:4}}>Выезд к клиенту</h1>*/}


            {/*    <span><p>{about} Lorem ipsum.</p></span>*/}
            {/*</div>*/}

            <div className="about__product">
                <h1 style={{marginBottom: 4}}>Описание</h1>

                {/* Показываем либо сокращенный текст, либо полный */}
                <p>{isExpanded ? about : `${previewText}...`}</p>

                {/* Ссылка для раскрытия текста */}
                <a onClick={toggleExpand} style={{cursor: 'pointer'}}>
                    {isExpanded ? 'Свернуть' : 'Читать далее'}
                </a>
            </div>

            <a onClick={toggleSubscription}
               className={isSubscribed ? 'subscribed' : 'unsubscribed'}
               style={{cursor: 'pointer'}}>
                {isSubscribed ? 'Отписаться ' : 'Подписаться на уведомления'} <p></p>
            </a>

            <div className="info__poshe">
                {/*<span>Размещено: {data}</span>*/}
                {/*<span>Просмотров: {eyes} (сегодня: {realtimeEye})</span>*/}
                {/*<span>Подписчиков: {sub} (сегодня: {realtimeSub})</span>*/}
                {/*<span>Добавленно в избранное: {izb} (сегодня: {realtimeIzb})</span>*/}
            </div>
        </div>
    );
};

export const RentItem = ({starLight, starDark}) => {
    const {id} = useParams();
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const [selectedDay, setSelectedDay] = useState(null);
    const [selectedTimes, setSelectedTimes] = useState([]);
    const toggleTimeSelection = (time, price) => {
        if (selectedTimes.includes(time)) {

            setSelectedTimes(selectedTimes.filter(t => t !== time));
            setTotalPrice(prevPrice => prevPrice - price);
        } else {

            setSelectedTimes([...selectedTimes, time]);
            setTotalPrice(prevPrice => prevPrice + price);
        }
    };
    const [isModalMenuOpen, setIsModalMenuOpen] = useState(false);
    const openModalMenu = () => setIsModalMenuOpen(true);
    const closeModalMenu = () => setIsModalMenuOpen(false);

    const [today, setToday] = useState({
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
    });
    const months = [
        {month: 'Январь', days: 31},
        {month: 'Февраль', days: 28},
        {month: 'Март', days: 31},
        {month: 'Апрель', days: 30},
        {month: 'Май', days: 31},
        {month: 'Июнь', days: 30},
        {month: 'Июль', days: 31},
        {month: 'Август', days: 31},
        {month: 'Сентябрь', days: 30},
        {month: 'Октябрь', days: 30},
        {month: 'Ноябрь', days: 30},
        {month: 'Декабрь', days: 31},
    ];
    const reviews = [
        {
            profileImage: 'user-image.jpg',
            author: 'Виктор',
            date: '23.09.2024',
            text: 'Силовая тренировкаСиловая тренировкаСиловая тренировка...',
            likes: 321,
            dislikes: 321,
            rating: 4.5
        },
        {
            profileImage: 'user-image.jpg',
            author: 'Виктор',
            date: '23.09.2024',
            text: 'Силовая тренировкаСиловая тренировкаСиловая тренировка...',
            likes: 321,
            dislikes: 321,
            rating: 4.5
        },
        {
            profileImage: 'user-image.jpg',
            author: 'Виктор',
            date: '23.09.2024',
            text: 'Силовая тренировкаСиловая тренировкаСиловая тренировка...',
            likes: 321,
            dislikes: 321,
            rating: 4.5
        },
        {
            profileImage: 'user-image.jpg',
            author: 'Виктор',
            date: '23.09.2024',
            text: 'Силовая тренировкаСиловая тренировкаСиловая тренировка...',
            likes: 321,
            dislikes: 321,
            rating: 4.5
        },
    ];
    const timeOptions = {
        1: [{time: '12:00', price: 25000}, {time: '14:00', price: 30000}],
        2: [{time: '10:00', price: 20000}, {time: '16:00', price: 27000}],
    };
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);
    const openFullscreen = (index) => {
        setCurrentSlide(index);
        setIsFullscreen(true);
    };
    const closeFullscreen = () => {
        setIsFullscreen(false);
    };
    const settings = (productId) => ({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: currentSlide,
        afterChange: (current) => handleSlideChange(productId, current),
    });
    const handleSlideChange = (productId, current) => {
        setCurrentSlides((prevState) => ({
            ...prevState,
            [productId]: current,
        }));
        setCurrentSlide(current);
    };
    const weekDays = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
    const getDayOfWeek = (year, month, day) => {
        const date = new Date(year, month, day);
        return weekDays[date.getDay()];
    };
    const getFirstDayOfWeek = (year, month) => {
        const firstDay = new Date(year, month, 1);
        return firstDay.getDay();
    };
    const rotateWeekDays = (offset) => {
        const rotatedDays = [...weekDays];
        for (let i = 0; i < offset; i++) {
            rotatedDays.push(rotatedDays.shift());
        }
        return rotatedDays;
    };
    const selectMonth = (index) => {
        setSelectedMonth(index);
        setExpanded(false);
        setSelectedDay(null);
    };
    const selectDay = (day) => {
        setSelectedDay(day);
    };
    const toggleExpand3 = () => {
        setExpanded(!expanded);
    };

    const [currentSlides, setCurrentSlides] = useState({});
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isPhoneHidden, setIsPhoneHidden] = useState(false);
    const togglePhoneVisibility = () => {
        setIsPhoneHidden(!isPhoneHidden);
    };
    const handleApplySort = () => {
        setIsSortModalOpen(false);
    };
    const [totalPrice, setTotalPrice] = useState(0);
    const toggleSubscription = () => {
        setIsSubscribed(!isSubscribed);
    };
    console.log('Product ID:', id);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    const product = mainpg.find(p => String(p.id) === String(id));
    const {name, price, oldPrice, ageGroup, rating, images, location, about} = product;
    const [isExpanded2, setIsExpanded2] = useState(false);
    const toggleExpand2 = () => {
        setIsExpanded2(!isExpanded2);
    };
    const [selectedTab, setSelectedTab] = useState('All');
    const [isSortModalOpen, setIsSortModalOpen] = useState(false);
    const openSortModal = () => setIsSortModalOpen(true);
    const closeSortModal = () => setIsSortModalOpen(false);
    const visiblePrices = isExpanded2 ? prices : prices.slice(0, 1);
    const previewLength = Math.floor(about.length * 0.3);
    const previewText = about.slice(0, previewLength);
    const [sortOption, setSortOption] = useState('date');
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };
    const [sortType, setSortType] = useState(null);
    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };
    const toggleOpen1 = () => {
        setIsOpen1(!isOpen1);
    };
    const toggleOpen2 = () => {
        setIsOpen2(!isOpen2);
    };

    console.log('Found product:', product);
    if (!product) {
        return <div>Продукт не найден</div>;
    }
    const getLowestPriceFromApplications = (applications) => {

        const prices = applications.flatMap(app =>
            app.sessions.flatMap(session => [
                // parseInt(session.prepay.replace(' р', ''), 10),
                parseInt(session.onSpot.replace(' р', ''), 10)
            ])
        );


        if (prices.length === 0) {
            return null;
        }


        return Math.min(...prices);
    };
    const applications = [
        {
            id: 1,
            status: 'Approved',
            date: '23.01.24',
            time: '15:20',
            orderNumber: '02345643',
            user: 'Александр Тимофеевич Брынза',
            sessions: [
                {day: 'Пн', date: '23.01.2024', time: '18:30 - 19:30', prepay: '2000 р', onSpot: '23000 р'},
            ],
            comment: '',
            imgUrl: '/path/to/image.jpg',
        },
        {
            id: 2,
            status: 'Pending',
            date: '23.01.24',
            time: '15:20',
            orderNumber: '02345644',
            user: 'Александр Тимофеевич Брынза',
            sessions: [
                {day: 'Пн', date: '23.01.2024', time: '21:30 - 22:30', prepay: '2000 р', onSpot: '19000 р'},
            ],
            comment: '',
            imgUrl: '/path/to/image.jpg',
        }, {
            id: 3,
            status: 'Not Approved',
            date: '23.01.24',
            time: '15:20',
            orderNumber: '02345644',
            user: 'Александр Тимофеевич Брынза',
            sessions: [
                {day: 'Пн', date: '23.01.2024', time: '21:30 - 22:30', prepay: '2000 р', onSpot: '21000 р'},
            ],
            comment: '',
            imgUrl: '/path/to/image.jpg',
        },
    ];
    const lowestPrice = getLowestPriceFromApplications(applications);
    const getFilteredApplications = () => {
        const now = new Date();

        return applications
            .filter(app => (selectedTab === 'All' ? true : app.status === selectedTab))
            .filter(app => {
                const appDate = new Date(app.date);

                switch (sortType) {
                    case 'all-time':
                        return true;
                    case 'year':
                        return appDate >= new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
                    case 'half-year':
                        return appDate >= new Date(now.getFullYear(), now.getMonth() - 6, now.getDate());
                    case 'quarter':
                        return appDate >= new Date(now.getFullYear(), now.getMonth() - 3, now.getDate());
                    case 'week':
                        return appDate >= new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
                    case 'today':
                        return (
                            appDate.getDate() === now.getDate() &&
                            appDate.getMonth() === now.getMonth() &&
                            appDate.getFullYear() === now.getFullYear()
                        );
                    default:
                        return true;
                }
            });
    };
    const sortedApplications = getFilteredApplications();
    const handleAccept = (id) => {
        console.log(`Accepted application with id: ${id}`);
    };
    const handleReject = (id) => {
        console.log(`Rejected application with id: ${id}`);
    };


    return (
        <div className="container productPG">
            <Header_product/>
            <ProductImageSlider images={images} settings={settings} closeFullscreen={closeFullscreen} isFullscreen={isFullscreen} currentSlide={currentSlide} openFullscreen={openFullscreen} product={product}  currentSlides={currentSlides} handleSlideChange={handleSlideChange}/>
            <HeadProduct product={product} lowestPrice={lowestPrice} about={about} rating={rating}
             name={name} location={location}/>
            <BtnProduct isPhoneHidden={isPhoneHidden} />
            <Status handleAccept={handleAccept} closeSortModal={closeSortModal} handleReject={handleReject} isOpen1={isOpen1} isSortModalOpen={isSortModalOpen} selectedTab={selectedTab} setSelectedTab={setSelectedTab} setSortType={setSortType} sortedApplications={sortedApplications} toggleOpen1={toggleOpen1}/>
            <Control isPhoneHidden={isPhoneHidden} openModalMenu={openModalMenu} toggleOpen={toggleOpen} sortedPredloj={sortedPredloj} togglePhoneVisibility={togglePhoneVisibility} isOpen={isOpen}/>
            <Calendar months={months} getFirstDayOfWeek={getFirstDayOfWeek} getDayOfWeek={getDayOfWeek} price={price} selectedMonth={selectedMonth} totalPrice={totalPrice} selectedDay={selectedDay} selectDay={selectDay} today={today} rotateWeekDays={rotateWeekDays} selectMonth={selectMonth} isOpen2={isOpen2} selectedTimes={selectedTimes} timeOptions={timeOptions} toggleExpand3={toggleExpand3} toggleOpen2={toggleOpen2} toggleTimeSelection={toggleTimeSelection} expanded={expanded}/>
            <SmallDescription about={about} />
            <Grafik />
            <Description about={about} isExpanded={isExpanded} previewText={previewText} toggleExpand={toggleExpand} />
            <ReviewBlock reviews={reviews} starDark={starDark} starLight={starLight}/>
            <ForAvtor Stars={Stars} starDark={starDark} starLight={starLight}/>
            <Subscribed/>
            <Info />
        </div>
    );
};

{/*<div className="about__product">*/}
{/*    <h1 style={{marginBottom:4}}>Выезд к клиенту</h1>*/}


{/*    <span><p>{about} Lorem ipsum.</p></span>*/}
{/*</div>*/}