import "./MainPage.css";
import { MainPageNoContent } from "./MainPage";
import { currentScreenWidth, mobileScreenBreakpoint } from "../../utils";
import DefaultProductCard from "../ui/DefaultProductCard/DefaultProductCard";
import { mainpg } from "../products";
import SliderLine from "../ui/ScrollLine/ScrollLine";
import FunctionalButtonLink from "../ui/FunctionalButton/FunctionalButtonLink";
import MainToSectionHeading from "./MainPageToSectionHeading";

export default function MainPageAdverts() {
	const productsSliderSettings = {
		arrows: false,
		dots: true,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
	};

	const userAdverts = [1];

	return (
		<div className="main-page__my-adverts">
			<div className="main-page__my-adverts-container main-page__user-adverts">
				<MainToSectionHeading title={"Мои объявления"} to="/" />

				{userAdverts.length > 0 ? (
					<>
						<div className="main-page__my-adverts-list">
							{mainpg.slice(0, 3).map((card, index) => (
								<DefaultProductCard
									key={`advert-${index}`}
									card={card}
									imageWidth={140}
									cardHeight={140}
									horizontal={true}
								/>
							))}
						</div>

						<FunctionalButtonLink
							addClass={"main-page__my-adverts-show-more-button"}
							to="/">
							Показать еще
						</FunctionalButtonLink>
					</>
				) : (
					<>
						<MainPageNoContent text={"Объявлений пока нет"} />
						<button className="header__add-announcement-button">
							Добавить объявление
						</button>
					</>
				)}
			</div>
			<div className="main-page__my-adverts-container">
				<MainToSectionHeading title={"Недавние"} to="/" />
				{userAdverts.length > 0 ? (
					<>
						{currentScreenWidth > mobileScreenBreakpoint ? (
							<>
								<div className="main-page__my-adverts-list">
									{mainpg.slice(0, 3).map((card, index) => (
										<DefaultProductCard
											key={`advert-${index}`}
											card={card}
											imageWidth={140}
											cardHeight={140}
											horizontal={true}
										/>
									))}
								</div>
								<FunctionalButtonLink
									addClass={"main-page__my-adverts-show-more-button"}
									to="/">
									Показать еще
								</FunctionalButtonLink>
							</>
						) : (
							<SliderLine
								slidesArray={mainpg}
								slideAddClass={"main-page_coach-card"}
								noContentText={"Объявлений пока нет"}
								hasOnlineAppointment={false}
								linkToSection={"/"}
								slideType={"advert"}
							/>
						)}
					</>
				) : (
					<MainPageNoContent text={"Объявлений пока нет"} />
				)}
			</div>
			<div className="main-page__my-adverts-container">
				<MainToSectionHeading title={"Популярное"} to="/" />
				{userAdverts.length > 0 ? (
					<>
						{currentScreenWidth > mobileScreenBreakpoint ? (
							<>
								<div className="main-page__my-adverts-list">
									{mainpg.slice(0, 3).map((card, index) => (
										<DefaultProductCard
											key={`advert-${index}`}
											card={card}
											imageWidth={140}
											cardHeight={140}
											horizontal={true}
										/>
									))}
								</div>
								<FunctionalButtonLink
									addClass={"main-page__my-adverts-show-more-button"}
									to="/">
									Показать еще
								</FunctionalButtonLink>
							</>
						) : (
							<SliderLine
								slidesArray={mainpg}
								linkToSection={"/"}
								slideAddClass={"main-page_coach-card"}
								noContentText={"Объявлений пока нет"}
								hasOnlineAppointment={false}
								slideType={"advert"}
							/>
						)}
					</>
				) : (
					<MainPageNoContent text={"Объявлений пока нет"} />
				)}
			</div>
		</div>
	);
}
