import React, {useState} from "react";

export const SearchFilter = () => {
    const [searchQuery, setSearchQuery] = useState("");
    return (
        <label className="search__container">
            <input
                type="text"
                placeholder="Поиск"
                className="search-input pc-src filter-input"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button className="search__btn filter-input-btn">Найти</button>
        </label>
    )
}