import "./MainPage.css";
import { currentScreenWidth, mobileScreenBreakpoint } from "../../utils";
import { MainPageNoContent } from "./MainPage";
import FilterButton from "../ui/FilterButton/FilterButton";
import GreyButton from "../ui/GreyButton/GreyButton";
import FilterPanelToggleButton from "../ui/FilterPanelToggleButton/FilterPanelToggleButton";
import DarkButton from "../ui/DarkButton/DarkButton";
import Slider from "react-slick";
import MonthPicker from "../ui/MonthPicker/MonthPicker";
import MonthPickerSimplified from "../ui/MonthPickerSimplified/MonthPickerSimplified";
import SliderLine from "../ui/ScrollLine/ScrollLine";
import MainToSectionHeading from "./MainPageToSectionHeading";

export default function MainPageTrainingsSchedule() {
	const datesSliderSettings = {
		arrows: true,
		dots: false,
		speed: 500,
		slidesToShow: 10,
		slidesToScroll: 5,
		infinite: false,
		responsive: [
			{
				breakpoint: mobileScreenBreakpoint,
				settings: {
					arrows: false,
				},
			},
		],
	};

	const trainingsSliderSettings = {
		arrows: true,
		dots: false,
		speed: 500,
		slidesToShow: 2,
		slidesToScroll: 2,
		infinite: false,
		responsive: [
			{
				breakpoint: mobileScreenBreakpoint,
				settings: {
					arrows: false,
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: true,
				},
			},
		],
	};

	const trainings = [1, 1, 1, 1, 1, 1];

	return (
		<div className="main-page__trainings-schedule">
			<MainToSectionHeading title={"Расписание тренировок"} to="/" />
			<div className="main-page__trainings-schedule-calendar">
				<MonthPickerSimplified />
			</div>
			{trainings.length > 0 ? (
				<>
					<div className="main-page__trainings-filters-list-container">
					<div className="main-page__trainings-filters-list">
						<FilterPanelToggleButton
							addClass={
								"main-page__trainings-filter main-page__trainings-filter-toggler"
							}
						/>
						<FilterButton
							text={"Взрослые (>18)"}
							isActive={false}
							addClass={"main-page__trainings-filter"}
						/>
						<GreyButton
							addClass={"main-page__trainings-filter"}
						>Скидка</GreyButton>
						<GreyButton
							addClass={"main-page__trainings-filter"}
						>Онлайн-запись</GreyButton>
					</div>
					</div>
					<SliderLine
						slidesArray={trainings} // Pass the data for the slides
						slideAddClass={"main-page_training-card"} // Optionally, pass a class for each slide
						noContentText={"Предложений пока нет"} // Fallback text if there is no content
						slideType={"training"}
						slidesToScroll={2}
						slideWidth={
							currentScreenWidth > mobileScreenBreakpoint
								? 470
								: currentScreenWidth - 32
						}
					/>
				</>
			) : (
				<MainPageNoContent text={"Предложений пока нет"} />
			)}
		</div>
	);
}

export const MainPageTrainingCard = () => {
	return (
		<div
			className="main-page__trainings-card"
			style={{
				width:
					currentScreenWidth > mobileScreenBreakpoint
						? 470
						: currentScreenWidth - 32,
			}}>
			<div className="main-page__trainings-card-content-wrapper">
				<div className="main-page__trainings-card-top">
					<p>Двусторонняя игра</p>
					<p>19:00-20:30</p>
				</div>
				<div className="main-page__trainings-card-content">
					<div className="main-page__trainings-card-content-main">
						<p>НХЛ-центр</p>
						<p className="main-page__trainings-card-category">
							Взрослые <span>{"(>18)"}</span>
						</p>
						<p>Средний уровень</p>
						<p>Морозово</p>
						<p>Новоостаповская 5, стр.1</p>
						<p className="main-page__trainings-card-slots-remain">
							Осталось мест: 10
						</p>
					</div>
					<div className="main-page__trainings-card-content-additional">
						<div className="main-page__trainings-card-content-additional-tags">
							<div className="main-page__trainings-card-content-additional-tag blue-tag">
								Хоккейная школа
							</div>
							<div className="main-page__trainings-card-content-additional-tag red-tag">
								Следж-хоккей
							</div>
						</div>
						<div className="main-page__trainings-card-content-additional-prices">
							<div className="main-page__trainings-card-content-additional-price-info">
								<span className="main-page__trainings-card-content-additional-price-label">
									Пробная
								</span>
								<p>
									<span className="main-page__trainings-card-content-additional-price">
										1000p
									</span>
									<span className="main-page__trainings-card-content-additional-percent">
										30%
									</span>
									<span className="main-page__trainings-card-content-additional-discount">
										1500р
									</span>
								</p>
							</div>
							<div className="main-page__trainings-card-content-additional-price-info">
								<span className="main-page__trainings-card-content-additional-price-label">
									Разовая
								</span>
								<p>
									<span className="main-page__trainings-card-content-additional-price">
										1600p
									</span>
									<span className="main-page__trainings-card-content-additional-percent">
										30%
									</span>
									<span className="main-page__trainings-card-content-additional-discount">
										2000р
									</span>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<DarkButton
				text={"Забронировать"}
				addClass={"main-page__trainings-card-book-button"}
			/>
		</div>
	);
};
