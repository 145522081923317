import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MainPage from "./components/MainPage/MainPage";
import ResponsiveComponent from "./components/Equipment";
import Chat from "./components/Chat/Chat";
import { getStoredUserId, getUserToken } from "./actions/user";
import CMS from "./components/CMS/CMS";
import {RentItem} from "./components/ProductPage";
import { currentScreenWidth, mobileScreenBreakpoint } from "./utils";
import NavPanel from "./components/ui/NavPanel/NavPanel";

// import CMS from "./components/CMS/CMS";
// import {useAppDispatch} from "./store/hooks";
// import {resolutionReducer, setResolution} from "./store/SharedRedusers/defineResolutionSlice";
// import {useDispatch} from "react-redux";


function App() {
	const [currentUserToken, setCurrentUserToken] = useState(getUserToken());
	const [currentUserId, setCurrentUserId] = useState(getStoredUserId());


	return (
		<>
			<Router>
				<Routes>
					{/* Разделы */}
					<Route
						path="/"
						element={
							<MainPage
								currentUserId={currentUserId}
								currentUserToken={currentUserToken}
								setCurrentUserId={setCurrentUserId}
								setCurrentUserToken={setCurrentUserToken}
							/>
						}
					/>
					<Route path="/equipment" element={<ResponsiveComponent />} />
					<Route
						path="/chat"
						element={
							<Chat
								currentUserId={currentUserId}
								currentUserToken={currentUserToken}
							/>
						}
					/>
                    {/* Разделы */}
					{/*<Route path="/" element={<MainPage currentUserId={currentUserId} currentUserToken={currentUserToken} setCurrentUserId={setCurrentUserId} setCurrentUserToken={setCurrentUserToken}/> } />*/}
					<Route path="/equipment/:id" element={<RentItem />} />
				</Routes>
				<NavPanel />
			</Router>
		</>
	);
}

export default App;
