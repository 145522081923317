import "./MainPage.css";
import { currentScreenWidth, mobileScreenBreakpoint } from "../../utils";
import { mainpg } from "../products";
import DefaultProductCard from "../ui/DefaultProductCard/DefaultProductCard";
import { MainPageNoContent } from "./MainPage";
import SliderLine from "../ui/ScrollLine/ScrollLine";
import MainToSectionHeading from "./MainPageToSectionHeading";

export default function MainPageCoachRating() {
	const coaches = [1];

	return (
		<div className="main-page__coach-rating">
			<MainToSectionHeading title={"Рейтинг тренеров"} to="/" />
			<SliderLine
				slidesArray={mainpg} // Pass the data for the slides
				slideAddClass={"main-page_coach-card"} // Optionally, pass a class for each slide
				noContentText={"Объявлений пока нет"} // Fallback text if there is no content
				slideType={"advert"}
				hasOnlineAppointment={true}
				linkToSection={"/"}
				slideWidth={currentScreenWidth > mobileScreenBreakpoint ? 230 : 194}
			/>
		</div>
	);
}
