import React, { useState } from "react";

export const HeadListProduct = ({ selectedFormat, setSelectedFormat, setItemsPerPage, setCardsPerRow }) => {
    const [sortDropdown, setSortDropdown] = useState(false);
    const [formatDropdown, setFormatDropdown] = useState(false);
    const [activeFormat, setActiveFormat] = useState(selectedFormat);

    const handleSelectFormat = (format) => {
        setActiveFormat(format); // Устанавливаем активный формат
        setSelectedFormat(format);
        setFormatDropdown(false); // Закрываем дропдаун

        switch (format) {
            case "four-view":
                setCardsPerRow(4);
                setItemsPerPage(12);
                break;
            case "three-view":
                setCardsPerRow(3);
                setItemsPerPage(9);
                break;
            case "one-view":
                setCardsPerRow(1);
                setItemsPerPage(4);
                break;
            default:
                setCardsPerRow(4);
                setItemsPerPage(12);
        }
    };

    const renderIcon = (format) => {
        switch (format) {
            case "four-view":
                return  <img src="/images/icons/FourView.svg" alt=""/>;
            case "three-view":
                return <img src="/images/icons/ThrideView.svg" alt=""/>;
            case "one-view":
                return <img src="/images/icons/OneView.svg" alt=""/>;
            default:
                return null;
        }
    };

    return (
        <div className="HeadListProduct">
            <div
                className="HeadListProduct__dropdown-container left"
                onClick={() => setSortDropdown(!sortDropdown)}
            >
                <div>
                    <img src="/images/icons/SortBtn.svg" alt="" />
                    <span>Сортировка</span>
                    <img src="/images/icons/ArrowDown.svg" alt="" />
                </div>
                {sortDropdown && (
                    <ul className="HeadListProduct__dropdown">
                        <li>
                            <span className="dropdown__apply">По имени</span>
                            <img src="/images/icons/apply.svg" alt="" />
                        </li>
                        <li>
                            <span>По дате</span>
                            <img src="/images/icons/apply.svg" alt="" />
                        </li>
                    </ul>
                )}
            </div>

            <div className="HeadListProduct__rightside">
                <div
                    className="HeadListProduct__dropdown-container"
                    onClick={() => setFormatDropdown(!formatDropdown)}
                >
                    <div>
                        {renderIcon(selectedFormat)}
                        <span>Формат отображения</span>
                        <img src="/images/icons/ArrowDown.svg" alt="" />
                    </div>
                    {formatDropdown && (
                        <ul className="HeadListProduct__dropdown right">
                            {["four-view", "three-view", "one-view"].map((format) => (
                                <li
                                    key={format}
                                    className={activeFormat === format ? "active-format" : ""}
                                    onClick={() => handleSelectFormat(format)}
                                >
                                    <div>
                                        {renderIcon(format)}
                                        <span>
                                            {format === "four-view"
                                                ? "4 в ряд"
                                                : format === "three-view"
                                                    ? "Три в ряд"
                                                    : "Один в ряд"}
                                        </span>
                                        {activeFormat === format && (
                                            <img
                                                className="check"
                                                src="/images/icons/apply.svg"
                                                alt="Выбрано"
                                            />
                                        )}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        </div>
    );
};
