export const currentScreenWidth = window.innerWidth;
export const mobileScreenBreakpoint = 570;

export function formatDate(
	dateString,
	handleFormattedDate,
	timeOnly,
	dateOnly
) {
	const now = new Date();
	const date = new Date(dateString);

	const diffTime = now - date;
	const diffDays = diffTime / (1000 * 60 * 60 * 24);

	const options = {
		hour: "2-digit",
		minute: "2-digit",
		hour12: false,
	};

	const formatDateParts = (date) => {
		const day = String(date.getDate()).padStart(2, "0");
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const year = date.getFullYear();
		return `${day}.${month}.${year}`;
	};

	if (timeOnly) {
		if (handleFormattedDate)
			handleFormattedDate(`${date.toLocaleTimeString("en-GB", options)}`);
		return `${date.toLocaleTimeString("en-GB", options)}`;
	} else if (dateOnly) {
		if (diffDays < 1) {
			if (handleFormattedDate) handleFormattedDate(`Сегодня`);
			return `Сегодня`;
		} else if (diffDays < 2) {
			if (handleFormattedDate) handleFormattedDate(`Вчера`);
			return `Вчера`;
		} else {
			if (handleFormattedDate) handleFormattedDate(`${formatDateParts(date)}`);
			return `${formatDateParts(date)}`;
		}
	} else {
		if (diffDays < 1) {
			if (handleFormattedDate)
				handleFormattedDate(
					`Сегодня, ${date.toLocaleTimeString("en-GB", options)}`
				);
			return `Сегодня, ${date.toLocaleTimeString("en-GB", options)}`;
		} else if (diffDays < 2) {
			if (handleFormattedDate)
				handleFormattedDate(
					`Вчера, ${date.toLocaleTimeString("en-GB", options)}`
				);
			return `Вчера, ${date.toLocaleTimeString("en-GB", options)}`;
		} else {
			if (handleFormattedDate)
				handleFormattedDate(
					`${formatDateParts(date)}, ${date.toLocaleTimeString(
						"en-GB",
						options
					)}`
				);
			return `${formatDateParts(date)}, ${date.toLocaleTimeString(
				"en-GB",
				options
			)}`;
		}
	}
}

export function formatLastOnlineTime(lastOnlineTime) {
	const now = new Date();
	const lastOnline = new Date(lastOnlineTime);
	const timeDiff = now - lastOnline; // Time difference in milliseconds

	const minutes = Math.floor(timeDiff / (1000 * 60)); // Time difference in minutes
	const hours = Math.floor(timeDiff / (1000 * 60 * 60)); // Time difference in hours
	const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24)); // Time difference in days
	const weeks = Math.floor(days / 7); // Time difference in weeks
	const months = Math.floor(days / 30); // Time difference in months

	// If the user was online less than a minute ago

	const minutesString = () => {
		const string = minutes.toString();
		const lastDigit = string[string.length - 1];

		if(lastDigit === "1") {
			return "минуту"
		} else if (lastDigit > 2 && lastDigit < 5) {
			return "минуты"
		} else {
			return "минут"
		}
	}

	const hoursString = () => {
		const string = hours.toString();
		const lastDigit = string[string.length - 1];

		if(lastDigit === "1") {
			return "час"
		} else if (lastDigit > 2 && lastDigit < 5) {
			return "часа"
		} else {
			return "часов"
		}
	}

	if (minutes < 1) {
		return "Недавно";
	}

	// If the user was online less than an hour ago
	if (minutes < 60) {
		return `${minutes} ${minutesString()} назад`;
	}

	// If the user was online less than a day ago
	if (hours < 24) {
		return `${hours} ${hoursString()} назад`;
	}

	// If the user was online less than a week ago
	if (days < 7) {
		return `${days} дн. назад`;
	}

	// If the user was online less than a month ago
	if (weeks < 4) {
		return `${weeks} нед. назад`;
	}

	// If the user was online more than a month ago, show the full date
	const day = String(lastOnline.getDate()).padStart(2, "0");
	const month = String(lastOnline.getMonth() + 1).padStart(2, "0");
	const year = lastOnline.getFullYear();

	return `${day}.${month}.${year}`; // Return date in "dd.mm.yyyy" format
}
