import React from "react";

export const PriceFilterModalItem = ({priceRange, setPriceRange}) => (
    <>
        <label style={{fontWeight: 700}}>Стоимость, ₽</label>
        <div className="price-range">
            <label htmlFor="">
                <span>От</span>
                <input
                    type="number"
                    value={priceRange.min}
                    onChange={(e) =>
                        setPriceRange({...priceRange, min: e.target.value})
                    }
                    placeholder="0"
                />
            </label>

            <label>
                <span>До</span>
                <input
                    type="number"
                    value={priceRange.max}
                    onChange={(e) =>
                        setPriceRange({...priceRange, max: e.target.value})
                    }
                    placeholder="45000"
                />
            </label>
        </div>
        <div className="price-slider-container">
            <input
                type="range"
                min="0"
                max="22500"
                // value={priceRange.min}
                className="price-slider"
            />
            <input
                type="range"
                min="22500"
                max="45000"
                // value={priceRange.max}
                className="price-slider"
            />
        </div>
    </>
)