import "./MainPage.css";
import { currentScreenWidth, mobileScreenBreakpoint } from "../../utils";
import { MainPageNoContent } from "./MainPage";
import GreyButton from "../ui/GreyButton/GreyButton";
import Slider from "react-slick";
import SliderLine from "../ui/ScrollLine/ScrollLine";
import { mainpg } from "../products";
import MainToSectionHeading from "./MainPageToSectionHeading";

export default function MainPageCommunities() {
	const communitiesSliderSettings = {
		arrows: true,
		dots: false,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 4,
		infinite: false,
		responsive: [
			{
				breakpoint: mobileScreenBreakpoint,
				settings: {
					arrows: false,
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: true,
				},
			},
		],
	};

	const communities = [1];
	return (
		<div className="main-page__communities">
			<MainToSectionHeading title={"Сообщества"} to="/" />
			<div className="main-page__communities-filter-buttons">
				<GreyButton
					isActive={true}
					addClass={"main-page__communities-filter-button"}>
					Моя лента
				</GreyButton>
				<GreyButton addClass={"main-page__communities-filter-button"}>
					Мои подписки
				</GreyButton>
				<GreyButton addClass={"main-page__communities-filter-button"}>
					Мои каналы
				</GreyButton>
				<GreyButton addClass={"main-page__communities-filter-button"}>
					Все каналы
				</GreyButton>
			</div>
			<SliderLine
				slidesArray={mainpg}
				slideAddClass={"main-page_coach-card"}
				noContentText={"Объявлений пока нет"}
				slideType={"community"}
				linkToSection={"/"}
				slideWidth={currentScreenWidth > mobileScreenBreakpoint ? 234 : 203}
			/>
		</div>
	);
}

export const CommunityPreview = () => {
	return (
		<div className="main-page__communities__preview">
			<div className="main-page__communities__preview-top">
				<img
					src="images/main_page/placeholder_community_avatar.png"
					alt="preview_image"
				/>
				<GreyButton isActive={true} addClass={"main-page__preview-button"}>
					Подписаться
				</GreyButton>
			</div>
			<div className="main-page__communities__preview-bottom">
				<h3>Rush hockey school</h3>
				<div className="main-page__communities__preview-bottom__info">
					<p>Подписчики: 1890</p>
					<div>
						<span></span>
						<img src="images/main_page/lock_locked.svg" alt="locked" />
					</div>
				</div>
			</div>
		</div>
	);
};
