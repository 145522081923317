import { useEffect } from "react";
import styles from "./DefaultProductCard.module.css";

export default function DefaultProductCard({
	card,
	cardWidth,
	cardHeight,
	imageWidth,
	imageHeight,
	hasOnlineAppointment,
	horizontal,
	addClass,
}) {
	return (
		<div
			className={`${styles.default_product_card} ${horizontal ? styles.default_product_card_horizontal : ""} ${addClass ? addClass : ""}`}
			style={{ width: cardWidth, height: cardHeight }}>
			<div className={styles.default_product_card_images}>
				<img style={{width: imageWidth, height: imageHeight}} src="/images/main_page/placeholder_coach.jpg" alt="Фото" />
			</div>

			<div className={styles.default_product_card_info}>
				<div className={styles.default_product_card_name}>
					<h2>{card.name}</h2>
					<img src="/images/icons/Favorite.svg" alt="Фаворите" />
					</div>
				<div className={styles.default_product_card_price}>
					<p>от {card.price}р</p>
					{hasOnlineAppointment && (
						<span className={styles.default_product_card_online_appointment}>
							Онлайн-запись
						</span>
					)}
				</div>
				<p className={styles.default_product_card_address}>{card.location}</p>
				<p className={styles.default_product_card_rating}>
					{" "}
					{card.rating} <span>(0)</span>
				</p>
			</div>
		</div>
	);
}
