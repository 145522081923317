import {configureStore} from "@reduxjs/toolkit"
import {rootReducer} from "./rootReducer";
import {composeWithDevTools} from "@redux-devtools/extension";
import {apiReducers} from "./apiReducers";
import {apiMiddlewares} from "./apiMildware";
import {setupListeners} from "@reduxjs/toolkit/query";
import {emptySplitApi} from "../api/emptyApi";

const composeEnhancers = composeWithDevTools();

export const sportClickStore = configureStore({
    reducer: {
        [emptySplitApi.reducerPath]: emptySplitApi.reducer,
        ...rootReducer,
        },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(apiMiddlewares),
});

setupListeners(sportClickStore.dispatch)

export type RootState = ReturnType<typeof sportClickStore.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof sportClickStore.dispatch
export type AppStore = typeof sportClickStore


