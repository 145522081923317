import React, {useState} from "react";
import {mainpg} from "./../../products";

import {ProductScrollLine} from "./Product";

export const ListProductGrid = ({products, itemsPerPage, cardsPerRow}) => {
    const [currentPage, setCurrentPage] = useState(1); // Текущая страница
    const [loadedProducts, setLoadedProducts] = useState(
        mainpg.slice(0, itemsPerPage)
    ); // Загруженные товары
    const totalPages = Math.ceil(mainpg.length / itemsPerPage);

    // Загрузить дополнительные товары
    const handleLoadMore = () => {
        const nextProducts = mainpg.slice(
            loadedProducts.length,
            loadedProducts.length + itemsPerPage
        );
        setLoadedProducts((prevProducts) => [...prevProducts, ...nextProducts]);
    };

    // Переключение страницы
    const handlePageChange = (pageNumber) => {
        const startIndex = (pageNumber - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        setLoadedProducts(mainpg.slice(startIndex, endIndex)); // Загрузка товаров для выбранной страницы
        setCurrentPage(pageNumber); // Устанавливаем текущую страницу
        window.scrollTo({top: 0, behavior: "smooth"}); // Скролл вверх
    };

    return (
        <div className="product-grid-container">
            {/* Сетка товаров с динамическим количеством карточек в строке */}
            <div
                className="product-grid"
                style={{
                    gridTemplateColumns: `repeat(${cardsPerRow}, 1fr)`, // Устанавливаем количество карточек в строке
                }}
            >
                {loadedProducts.length > 0 ? (
                    loadedProducts.map((product, index) => (
                        <div className="product-item" key={index}>
                            <ProductScrollLine cardsPerRow={cardsPerRow} product={product}/>
                        </div>
                    ))
                ) : (
                    <div className="no-products">
                        <p>По выбранным фильтрам объявлений нет</p>
                    </div>
                )}
            </div>

            <div className="pagination-container">
                {totalPages > 1 && (
                    <div className="pagination">
                        <img
                            src={currentPage === 1
                                ? "/images/icons/next__gray.svg"
                                : "/images/icons/next.svg"}
                            className={`page-button btn__back ${currentPage === totalPages ? "disable" : ""}`}
                            onClick={() => handlePageChange(1)}
                            disabled={currentPage === 1}
                        />


                        {Array.from({length: totalPages}, (_, pageIndex) => pageIndex + 1).map(
                            (page) => (
                                <button
                                    key={page}
                                    className={`page-button ${currentPage === page ? "active" : ""}`}
                                    onClick={() => handlePageChange(page)}
                                >
                                    {page}
                                </button>
                            )
                        )}

                        <img
                            src={currentPage === totalPages
                                ? "/images/icons/next__gray.svg"
                                : "/images/icons/next.svg"}
                            className={`page-button btn__next ${currentPage === totalPages ? "disable" : ""}`}
                            onClick={() => handlePageChange(totalPages)}
                            disabled={currentPage === totalPages}
                        />
                    </div>
                )}

                {/* Кнопка загрузки дополнительных товаров */}
                {currentPage < totalPages && (
                    <button className="load-more-button" onClick={handleLoadMore}>
                        Загрузить еще
                    </button>
                )}
            </div>

        </div>
    );
};