import { useEffect, useRef, useState } from "react";

import styles from "./VideoPlayer.module.css";

export default function CustomVideoPlayer({ videoUrl, controls }) {
	const videoRef = useRef(null);
	const [isPlaying, setIsPlaying] = useState(false);
	const [progress, setProgress] = useState(0);
	const [volume, setVolume] = useState(1);

	const [isPlayingButtonVisible, setIsPlayingButtonVisible] = useState(true);

	// Handle play/pause
	const togglePlayPause = () => {
		if (videoRef.current.paused) {
			videoRef.current.play();
			setIsPlaying(true);

			setIsPlayingButtonVisible(true);

			setTimeout(() => {
				setIsPlayingButtonVisible(false);
			}, 100);
		} else {
			videoRef.current.pause();
			setIsPlaying(false);

			setIsPlayingButtonVisible(true);
		}
	};

	// Update progress bar as the video plays
	const updateProgress = () => {
		const currentProgress =
			(videoRef.current.currentTime / videoRef.current.duration) * 100;
		setProgress(currentProgress);
	};

	// Handle seeking (clicking on the progress bar)
	const handleProgressClick = (e) => {
		const progressBar = e.target;
		const newTime =
			(e.nativeEvent.offsetX / progressBar.offsetWidth) *
			videoRef.current.duration;
		videoRef.current.currentTime = newTime;
	};

	// Handle volume change
	const handleVolumeChange = (e) => {
		setVolume(e.target.value);
		videoRef.current.volume = e.target.value;
	};

	useEffect(() => {
		if (videoRef.current) {
			videoRef.current.removeAttribute("controls");
		}
	}, []);

	useEffect(() => {
		const video = videoRef.current;

		const preventFullscreen = (e) => {
			if (e) {
				e.preventDefault();
			}
		};

        setIsPlaying(false);

		video.addEventListener("fullscreenchange", preventFullscreen);
		return () => {
			video.removeEventListener("fullscreenchange", preventFullscreen);
		};
	}, []);

	return (
		<div className={styles.videoPlayer_container}>
			<video
				ref={videoRef}
				onTimeUpdate={updateProgress}
				onClick={togglePlayPause}
				className={styles.videoPlayer_videoElement}
				preload="metadata"
				autoPlay={false}
				controls={false}
				playsInline
				webkitPlaysInline>
				<source src={`${videoUrl}#t=0.1`} type="video/mp4" />
				Your browser does not support the video tag.
			</video>

			{/* Custom Controls */}
			<div className={styles.videoPlayer_controlsContainer}>
				<button
					onClick={togglePlayPause}
					className={`${styles.videoPlayer_play_pause_button} ${isPlayingButtonVisible ? styles.videoPlayer_play_pause_button_visible : ""}`}>
					<img
						src={
							isPlaying
								? "/images/video/pause-button.svg"
								: "/images/video/play-button.svg"
						}
						alt="Кнопка паузы"
					/>
				</button>

				{controls && (
					<>
						<div
							className={styles.videoPlayer_progressBar}
							onClick={handleProgressClick}>
							<div
								className={styles.videoPlayer_progress}
								style={{ width: `${progress}%` }}></div>
						</div>

						<input
							type="range"
							min="0"
							max="1"
							step="0.01"
							value={volume}
							onChange={handleVolumeChange}
							className={styles.videoPlayer_volumeSlider}
						/>
					</>
				)}
			</div>
		</div>
	);
}
