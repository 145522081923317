import { NavLink } from "react-router-dom";
import { currentScreenWidth, mobileScreenBreakpoint } from "../../utils";

export default function MainToSectionHeading({ title, to }) {
	return (
		<div className="main-page__content-top">
			<h1 className="main-page__main-content-wrapper-heading">{title}</h1>
			<NavLink to={to} className="main-page__to-section-button">
				<p>В раздел</p>
				<img
					src={
						currentScreenWidth > mobileScreenBreakpoint
							? "/images/main_page/arrow-right-scroll.svg"
							: "/images/main_page/arrow-right-to-section-grey.svg"
					}
					alt="В раздел"
				/>
			</NavLink>
		</div>
	);
}
