import axios from "axios";
import { getUserToken } from "./user";

export async function createChat(recipientId) {
	const token = getUserToken();
	if (token) {
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_PUBLIC_API_URL}/api/Dialogue`,
				"",
				{
					params: {
						userId2: recipientId.toString(),
					},
					headers: {
						accept: "text/plain",
						Authorization: "Bearer " + token,
						"content-type": "application/x-www-form-urlencoded",
					},
				}
			);

			return response;
		} catch (err) {
			console.log(err);
		}
	} else {
		console.log("Can't create chat: Unauthorized");
	}
}

export async function getUsers() {
	try {
		const response = await axios.get(
			`${process.env.REACT_APP_PUBLIC_API_URL}/api/InfoUserContraller/GetFirstUser`,
			{
				headers: {
					accept: "*/*",
				},
			}
		);

		return response;
	} catch (err) {
		console.log(err);
	}
}

export async function getUserChats() {
	const token = getUserToken();
	if (token) {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_PUBLIC_API_URL}/api/Dialogue`,
				{
					headers: {
						accept: "*/*",
						Authorization: "Bearer " + token,
					},
				}
			);

			return response;
		} catch (err) {
			console.log(err);
		}
	} else {
		console.log("Can't fetch chats: Unauthorized");
	}
}

export async function getSpecificChatMessages(chatId) {
	const token = getUserToken();
	if (token) {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_PUBLIC_API_URL}/api/DialogueMassege/getListFirst`,
				{
					params: {
						DialogueId: chatId.toString(),
					},
					headers: {
						accept: "*/*",
						Authorization: "Bearer " + token,
					},
				}
			);

			return response;
		} catch (err) {
			console.log(err);
		}
	} else {
		console.log("Can't fetch chat messages: Unauthorized");
	}
}

export async function getAllSpecificChatMessages(chatId) {
	const token = getUserToken();
	if (token) {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_PUBLIC_API_URL}/api/DialogueMassege/getAllList`,
				{
					params: {
						DialogueId: chatId.toString(),
					},
					headers: {
						accept: "*/*",
						Authorization: "Bearer " + token,
					},
				}
			);

			return response;
		} catch (err) {
			console.log(err);
		}
	} else {
		console.log("Can't fetch chat messages: Unauthorized");
	}
}

export async function getSpecificChatPinnedMessages(chatId) {
	const token = getUserToken();
	if (token) {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_PUBLIC_API_URL}/api/DialogueMassege/GetAttachMessage`,
				{
					params: {
						DialogueId: chatId.toString(),
					},
					headers: {
						accept: "*/*",
						Authorization: "Bearer " + token,
					},
				}
			);

			return response;
		} catch (err) {
			console.log(err);
		}
	} else {
		console.log("Can't fetch chat messages: Unauthorized");
	}
}

export async function getOneSpecificChatMessage(messageId) {
	const token = getUserToken();
	if (token) {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_PUBLIC_API_URL}/api/DialogueMassege`,
				{
					params: {
						DialogueId: messageId.toString(),
					},
					headers: {
						accept: "*/*",
						Authorization: "Bearer " + token,
					},
				}
			);

			return response;
		} catch (err) {
			console.log(err);
		}
	} else {
		console.log("Can't fetch chat messages: Unauthorized");
	}
}

export async function getChatMessagesBeforeSpecificOne(chatId, messageId) {
	const token = getUserToken();
	if (token) {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_PUBLIC_API_URL}/api/DialogueMassege/getListBefore`,
				{
					params: {
						DialogueId: chatId.toString(),
						DialogueMessageId: messageId.toString(),
					},
					headers: {
						accept: "*/*",
						Authorization: "Bearer " + token,
					},
				}
			);

			return response;
		} catch (err) {
			console.log(err);
		}
	} else {
		console.log("Can't fetch previous messages: Unauthorized");
	}
}

export async function getChatMessagesAfterSpecificOne(chatId, messageId) {
	const token = getUserToken();
	if (token) {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_PUBLIC_API_URL}/api/DialogueMassege/getListAfter`,
				{
					params: {
						DialogueId: chatId.toString(),
						DialogueMessageId: messageId.toString(),
					},
					headers: {
						accept: "*/*",
						Authorization: "Bearer " + token,
					},
				}
			);

			return response;
		} catch (err) {
			console.log(err);
		}
	} else {
		console.log("Can't fetch next messages: Unauthorized");
	}
}

export async function getCountBetweenMessages(
	chatId,
	fromMessageId,
	toMessageId
) {
	const token = getUserToken();
	if (token) {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_PUBLIC_API_URL}/api/DialogueMassege/CountMessagesBetweenMessages`,
				{
					params: {
						DialogueId: chatId.toString(),
						messageFrom: fromMessageId.toString(),
						messageTo: toMessageId.toString(),
					},
					headers: {
						accept: "*/*",
						Authorization: "Bearer " + token,
					},
				}
			);

			return response;
		} catch (err) {
			console.log(err);
		}
	} else {
		console.log("Can't count messages: Unauthorized");
	}
}

export async function sendMessage(chatId, messageText) {
	const token = getUserToken();
	if (token) {
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_PUBLIC_API_URL}/api/DialogueMassege`,
				{
					dialogueId: chatId.toString(),
					massege: messageText.toString(),
				},
				{
					headers: {
						accept: "*/*",
						Authorization: "Bearer " + token,
						"Content-Type": "application/json",
					},
				}
			);

			return response;
		} catch (err) {
			console.log(err);
		}
	} else {
		console.log("Can't send message: Unauthorized");
	}
}

export async function sendFileMessage(formData) {
	const token = getUserToken();
	if (token) {
		const response = await axios.post(
			`${process.env.REACT_APP_PUBLIC_API_URL}/api/DialogueMassege/FileMessage`,
			formData,
			{
				headers: {
					accept: "*/*",
					Authorization: "Bearer " + token,
					"Content-Type": "multipart/form-data",
				},
			}
		);
	} else {
		console.log("Can't send message: Unauthorized");
	}
}

export async function sendReplyMessage(chatId, replyMessageId, messageText) {
	const token = getUserToken();
	if (token) {
		const response = await axios.post(
			`${process.env.REACT_APP_PUBLIC_API_URL}/api/DialogueMassege/RedirectMessage`,
			{
				dialogueId: chatId.toString(),
				massege: messageText.toString(),
				redirectedDialogueMessageId: replyMessageId.toString(),
			},
			{
				headers: {
					accept: "*/*",
					Authorization: "Bearer " + token,
					"Content-Type": "application/json",
				},
			}
		);
	} else {
		console.log("Can't reply to message: Unauthorized");
	}
}

export async function checkNewMessage(messageId) {
	const token = getUserToken();
	if (token) {
		try {
			const response = await axios.put(
				`${process.env.REACT_APP_PUBLIC_API_URL}/api/DialogueMassege/Check`,
				"",
				{
					params: {
						Id: messageId.toString(),
					},
					headers: {
						accept: "*/*",
						Authorization: "Bearer " + token,
					},
				}
			);

			return response;
		} catch (err) {
			console.log(err);
		}
	} else {
		console.log("Can't check message: Unauthorized");
	}
}

export async function pinMessage(messageId) {
	const token = getUserToken();
	if (token) {
		try {
			const response = await axios.put(
				`${process.env.REACT_APP_PUBLIC_API_URL}/api/DialogueMassege/AttachedMessage`,
				{
					dialogueMessageId: messageId.toString(),
				},
				{
					headers: {
						accept: "*/*",
						Authorization: "Bearer " + token,
						"Content-Type": "application/json",
					},
				}
			);

			return response;
		} catch (err) {
			console.log(err);
		}
	} else {
		console.log("Can't pin message: Unauthorized");
	}
}

export async function unpinMessage(messageId) {
	const token = getUserToken();
	if (token) {
		try {
			const response = await axios.put(
				`${process.env.REACT_APP_PUBLIC_API_URL}/api/DialogueMassege/UnAttachedMessage`,
				{
					dialogueMessageId: messageId.toString(),
				},
				{
					headers: {
						accept: "*/*",
						Authorization: "Bearer " + token,
						"Content-Type": "application/json",
					},
				}
			);

			return response;
		} catch (err) {
			console.log(err);
		}
	} else {
		console.log("Can't unpin message: Unauthorized");
	}
}

export async function deleteMessage(messageId) {
	const token = getUserToken();
	if (token) {
		try {
			const response = await axios.delete(
				`${process.env.REACT_APP_PUBLIC_API_URL}/api/DialogueMassege`,
				{
					params: {
						DialogueMessageId: messageId.toString(),
					},
					headers: {
						accept: "*/*",
						Authorization: "Bearer " + token,
					},
				}
			);

			return response;
		} catch (err) {
			console.log(err);
		}
	} else {
		console.log("Can't delete message: Unauthorized");
	}
}

export async function getUsersOnlineStatus() {
	const token = getUserToken();
	if (token) {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_PUBLIC_API_URL}/api/Dialogue/GetOnlineUsersId`,
				{
					headers: {
						accept: "text/plain",
						Authorization: "Bearer " + token,
					},
				}
			);

			return response;
		} catch (err) {
			console.log(err);
		}
	} else {
		console.log("Can't get online users: Unauthorized");
	}
}
