import React from "react";

export const CategoryFilter = ({category, selectedCategory, handleCategoryClick}) => {
    return (
        <div className="filter-group">
            <div className="radio-group">
                {category
                    .filter((cat) => cat !== "Не выбрано")
                    .map((cat) => (
                        <div key={cat} className="switch-group">
                            <label className="switch-label">
                                <span style={{fontWeight: 700}}>{cat}</span>
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        className="hidden"
                                        checked={selectedCategory.includes(cat)}
                                        onChange={() => handleCategoryClick(cat)}
                                    />
                                    <span className="slider round"></span>
                                </label>
                            </label>
                        </div>
                    ))}
            </div>
        </div>
    );
};