import {createApi} from "@reduxjs/toolkit/query/react";
import {baseQuery} from "../../store/apiBase";

export const servicesApi = createApi({
    baseQuery: baseQuery,
    endpoints: builder => ({
        getServices: builder.query({
            query: () => `api/Services/GetList`,
            transformResponse: (response , meta, arg) => response.data,
        })
    }),

})

export const { useGetServicesQuery } = servicesApi;


