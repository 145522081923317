import "./MainPage.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useEffect, useState } from "react";
import { createChat, getUserChats, getUsers } from "../../actions/chat";
import Preloader from "../ui/Preloader/Preloader";
import FunctionalButton from "../ui/FunctionalButton/FunctionalButton";
import MainPageTopPanel from "./MainPageTopPanel";
import MainPageServices from "./MainPageServices";
import MainPageCoachRating from "./MainPageCoachRating";
import MainPageTrainingsSchedule from "./MainPageTrainingsSchedule";
import MainPageCommunities from "./MainPageCommunities";
import MainPageAdverts from "./MainPageAdverts";
import MainPageVideo from "./MainPageCoachVideo";

export default function MainPage({
	currentUserId,
	currentUserToken,
	setCurrentUserId,
	setCurrentUserToken,
}) {
	return (
		<>
			<Header
				setCurrentUserId={setCurrentUserId}
				setCurrentUserToken={setCurrentUserToken}
			/>
			<main className="main-page">
				<MainPageTopPanel />
				<MainPageContent />
			</main>
			<Footer />
		</>
	);
}

const MainPageContent = () => {
	return (
		<div className="main-page__main-content-wrapper">
			<div className="main-page__main-content">
				<MainPageServices />
				<MainPageCoachRating />
				<MainPageTrainingsSchedule />
				<MainPageCommunities />
				<MainPageVideo />
			</div>
			<MainPageAdverts />
			<div className="main-page__bottom-support_container">
				<div className="main-page__bottom-support">
					<p>Ответим на ваши вопросы в чате</p>
					<div className="footer__links-container">
						<a href="">
							<img
								className="whatsapp"
								src="/images/footer/whatsapp_support.svg"
								alt="Whatsapp чат"
							/>
						</a>
						<a href="">
							<img
								src="/images/footer/telegram_support.svg"
								alt="Telegram чат"
							/>
						</a>
						<a href="">
							<img src="/images/footer/chat_support.svg" alt="Чат" />
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export const MainPageNoContent = ({ text }) => {
	return (
		<div className="main-page__no-content">
			<p>{text}</p>
		</div>
	);
};

const ChatPicker = ({ currentUserToken, currentUserId }) => {
	const [users, setUsers] = useState(null);
	const [currentUserChats, setCurrentUserChats] = useState(null);
	const [selectedUser, setSelectedUser] = useState(0);
	const [isError, setIsError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [preloaderVisible, setPreloaderVisible] = useState(null);

	const createNewChat = () => {
		if (selectedUser) {
			const existingChat = currentUserChats.find(
				(chat) => chat.userName === `${JSON.parse(selectedUser).fullName}`
			);
			setIsError(false);
			setErrorMessage("");

			const errorTextTimeout = setTimeout(() => {
				setErrorMessage("");
				clearTimeout(errorTextTimeout);
			}, 5000);

			if (!existingChat) {
				setPreloaderVisible(true);
				createChat(JSON.parse(selectedUser).id).then(() => {
					setPreloaderVisible(false);
					setErrorMessage("Чат создан");
				});
			} else {
				setIsError(true);
				setErrorMessage("У вас уже есть чат с этим пользователем");
			}
		} else {
			setIsError(true);
			setErrorMessage("Выберите пользователя");
		}
	};

	useEffect(() => {
		if (currentUserToken) {
			getUsers()
				.then((response) => setUsers(response.data.result))
				.catch((err) => console.log(err));
			getUserChats(currentUserId)
				.then((response) => setCurrentUserChats(response.data.data))
				.catch((err) => console.log(err));
		}
	}, []);

	return (
		<>
			{users && (
				<div className="pick_user">
					<Preloader isVisible={preloaderVisible} />
					{users.length > 0 && (
						<div className="">
							<select
								name="user"
								id="userPick"
								value={selectedUser}
								onChange={(e) => setSelectedUser(e.target.value)}>
								<option value="0" disabled>
									Выберете пользователя для начала диалога
								</option>
								{users.map((user) => (
									<>
										{user.id !== currentUserId && (
											<option value={JSON.stringify(user)}>
												{user.fullName}
											</option>
										)}
									</>
								))}
							</select>
						</div>
					)}
					<FunctionalButton
						onClick={createNewChat}
						addClass={"main_page-button"}>
						Создать чат
					</FunctionalButton>
					<p className={isError ? "" : "success"}>{errorMessage}</p>
				</div>
			)}
		</>
	);
};

{
	/* <div className="title">
				<h2>Вместо всего что ниже, здесь будет главная страница</h2>
			</div>
			<div className="links">
				<FunctionalButtonLink addClass={"main_page-button"} to={"/equipment"}>
					Перейти в разделы
				</FunctionalButtonLink>
			</div>
			{currentUserId && (
				<ChatPicker
					currentUserToken={currentUserToken}
					currentUserId={currentUserId}
				/>
			)} */
}
