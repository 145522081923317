import React from "react";

export const Favorite = () => (
    <svg className="product-izb" width="15" height="18" viewBox="0 0 18 22" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M1.70538 1.03036C2.75093 0.283994 4.14516 0 5.61697 0H12.3862C13.9066 0 15.3097 0.305547 16.3483 1.09853C17.4227 1.91889 18 3.17799 18 4.82713V20.2476C18 21.6032 16.5512 22.4123 15.4095 21.7713L9.02651 18.178L2.58623 21.7783C1.44476 22.4165 0 21.606 0 20.2529V4.6325C0 3.01666 0.618563 1.80618 1.70538 1.03036ZM2.54532 2.23831C1.88673 2.70844 1.45836 3.4448 1.45836 4.6325V20.2529C1.45836 20.4399 1.67631 20.5994 1.88165 20.4846L8.67549 16.6866C8.89542 16.5636 9.16232 16.564 9.3819 16.6876L16.1162 20.4788C16.1161 20.4787 16.1164 20.4788 16.1162 20.4788C16.3233 20.5947 16.5416 20.4341 16.5416 20.2476V4.82713C16.5416 3.55787 16.1179 2.77281 15.4706 2.27862C14.7875 1.75705 13.7484 1.47764 12.3862 1.47764H5.61697C4.29484 1.47764 3.24518 1.73872 2.54532 2.23831Z"
              fill="#181829" fill-opacity="0.4"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M4.33594 7.87554C4.33594 7.4675 4.6624 7.13672 5.06512 7.13672H12.8557C13.2584 7.13672 13.5849 7.4675 13.5849 7.87554C13.5849 8.28358 13.2584 8.61436 12.8557 8.61436H5.06512C4.6624 8.61436 4.33594 8.28358 4.33594 7.87554Z"
              fill="#181829" fill-opacity="0.4"/>
    </svg>

)