import React from "react";

export const HeadProduct = ({lowestPrice, product, name, rating, location, about}) => {
    return (
        <div className="head__trener">
            <p>
                {lowestPrice !== null ? `От ${lowestPrice} р за услугу` : "Информации нет"}
            </p>
            <h1>{name}</h1>
            <p className="rating">
                <span>{product.rating}</span> (отзывов: 12)
            </p>
            <h2>{location}</h2>
            <a href={about}>Показать на карте</a>

            <div className="izb"></div>
        </div>
    )
}