import React from "react";

export const Switch = ({SwitchName}) => {

    return (
        <label className="switch-label">
            <span style={{fontWeight: 700}}>{SwitchName}</span>
            <label className="switch">
                <input
                    type="checkbox"
                    className="hidden"
                    // checked={""}
                    // onChange={""}
                />
                <span className="slider round"></span>
            </label>
        </label>
    )
};