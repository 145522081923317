import Slider from "react-slick";
import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";

export const ProductImageSlider = ({
                                       settings,
                                       images,
                                       openFullscreen,
                                       isFullscreen,
                                       closeFullscreen,
                                       currentSlide,
                                       handleSlideChange,
                                        product,
                                       currentSlides
                                   }) => {
    return (
        <>
            <div className="product-image-slider" >
                <Swiper
                    spaceBetween={10}
                    slidesPerView={1}
                    loop={true}
                    onSlideChange={(swiper) => handleSlideChange(product.id, swiper.activeIndex)}
                >
                    {product.images &&
                        product.images.map((image, i) => (
                            <SwiperSlide key={i}>
                                <img
                                    className="product-image"
                                    src={image}
                                    onClick={() => openFullscreen(i)}
                                    alt={`Product Image ${i + 1}`}
                                />
                            </SwiperSlide>
                        ))}
                </Swiper>
                <div className="slider-counter">
                    {(currentSlides[product.id] || 0) + 1} из {product.images.length}
                </div>
            </div>
            {isFullscreen && (
                <div className="fullscreen-overlay">
                    <div className="header__fullscreen">
                        <div className="leftside__full">
                            <button className="back-button" onClick={closeFullscreen}></button>
                            <span>{currentSlide + 1} из {images.length}</span>
                        </div>

                        <div className="rightside__full">
                            <a className="call sphere__icon"></a>
                            <a className="mail sphere__icon"></a>
                            {/*<a href="" className="dots__full"></a>*/}
                        </div>
                    </div>

                    <Slider {...settings(true)}>
                        {images && images.map((image, i) => (
                            <img src={image} alt={`Product Image ${i + 1}`} key={i}/>
                        ))}
                    </Slider>
                </div>
            )}
        </>
    )
}