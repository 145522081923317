import { fetchBaseQuery } from "@reduxjs/toolkit/query";

export const baseQuery = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_PUBLIC_API_URL,
	prepareHeaders: (headers, { getState }) => {
		const token = localStorage.getItem("token");

		if (token) {
			headers.set("authorization", `Bearer ${token}`);
		}
		return headers;
	},
});
