import React from "react";

export const Info = () => {
    return (
        <div className="info__poshe">
            <span>Размещено: {}</span>
            <span>Просмотров: <strong>3</strong>{} (сегодня: {})</span>
            <span>Подписчиков: {} (сегодня: {})</span>
            <span>Добавленно в избранное: {} (сегодня: {})</span>
        </div>
    )
}