import React from "react";

export const SmallDescription = ({about}) => {
    return (
        <div className="about__product">
            <h1 style={{marginBottom: 4, marginTop: 20}}>Подробности</h1>

            <span>ИНН организации <p>{about}</p></span>
            <span>Дополнительный телефон <a style={{color: "#283DC1", textDecoration: "none"}}
                                            href="">{about}</a></span>
            <span>Сайт <a style={{color: "#283DC1", textDecoration: "none"}} href="">{about}</a></span>
            <span>Размер площадки <p>{about}</p></span>
            <span>Тип поверхности <p>{about}</p></span>
            <span>Парковка <p>{about}</p></span>
            <span>Кафе / ресторан <p>{about}</p></span>
            <span>Хранение формы <p>{about}</p></span>
            <span>Химчистка <p>{about}</p></span>
            <span>Магазин хоккейной формы <p>{about}</p></span>
            <span>Заточка коньков <p>{about}</p></span>
            <span>Аренда хоккейной формы <p>{about}</p></span>
            <span>Трибуны <p>{about}</p></span>
            <span>Душевые <p>{about}</p></span>
            <span>Раздевалки <p>{about}</p></span>
            <span>Зона для ОФП <p>{about}</p></span>
            <span>Баня / сауна <p>{about}</p></span>
        </div>
    )
}