import {useNavigate} from "react-router-dom";
import React, {useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Favorite} from "./Favorite";

export const ProductScrollLine = ({groupedProducts, product, cardsPerRow}) => {
    const navigate = useNavigate();
    const [currentSlides, setCurrentSlides] = useState({});

    const handleSlideChange = (productId, current) => {
        setCurrentSlides((prevState) => ({
            ...prevState,
            [productId]: current,
        }));
    };

    const productClass = `product product-${cardsPerRow}-row ${cardsPerRow === 4 ? 'product-4-row' : ''}`;

    return (
        <div
            className={productClass}
            key={product.id}
            onClick={() => {
                navigate(`/equipment/${product.id}`, {
                    state: {products: groupedProducts},
                });
            }}
        >
            <div className="product-image-slider">
                <Swiper
                    spaceBetween={10}
                    slidesPerView={1}
                    loop={true}
                    onSlideChange={(swiper) => handleSlideChange(product.id, swiper.activeIndex)}
                >
                    {product.images &&
                        product.images.map((image, i) => (
                            <SwiperSlide key={i}>
                                <img
                                    className="product-image"
                                    src={image}
                                    alt={`Product Image ${i + 1}`}
                                />
                            </SwiperSlide>
                        ))}
                </Swiper>
                <div className="slider-counter">
                    {(currentSlides[product.id] || 0) + 1} из {product.images.length}
                </div>
            </div>

            <div className="product-info">
                <h2>{product.name}</h2>
                <p>
                    от {product.price}р{" "}
                    <span className="old-price">{product.oldPrice}р</span>
                </p>
                <p>{product.location}</p>
                <p className="rating"> {product.rating} <p>(0)</p></p>
                <Favorite/>
                <button className="AddToCart Second">
                    <svg width="39" height="25" viewBox="0 0 39 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14 1H16.088C17.384 1 18.4039 2.11599 18.2959 3.39997L17.3 15.3518C17.132 17.3078 18.6799 18.9878 20.6479 18.9878H33.4278C35.1557 18.9878 36.6677 17.5718 36.7997 15.8558L37.4477 6.85593C37.5917 4.86396 36.0797 3.24397 34.0757 3.24397H18.584"
                            stroke="#283DC1" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                            stroke-linejoin="round"/>
                        <path d="M22.3984 8.19922H36.7983" stroke="#283DC1" stroke-width="1.5" stroke-miterlimit="10"
                              stroke-linecap="round" stroke-linejoin="round"/>
                        <path
                            d="M33.9745 22.8542C33.9745 21.6709 33.0145 20.7109 31.8312 20.7109C30.648 20.7109 29.688 21.6709 29.688 22.8542C29.688 24.0374 30.648 24.9974 31.8312 24.9974C33.0145 24.9974 33.9745 24.0374 33.9745 22.8542Z"
                            fill="#283DC1"/>
                        <path
                            d="M24.5755 22.8542C24.5755 21.6709 23.6155 20.7109 22.4323 20.7109C21.2491 20.7109 20.2891 21.6709 20.2891 22.8542C20.2891 24.0374 21.2491 24.9974 22.4323 24.9974C23.6155 24.9974 24.5755 24.0374 24.5755 22.8542Z"
                            fill="#283DC1"/>
                        <path
                            d="M5.74449 11.3198H9V13.5358H5.74449V17H3.26453V13.5358H0V11.3198H3.26453V8H5.74449V11.3198Z"
                            fill="#283DC1"/>
                    </svg>

                    <span>В корзину</span>
                </button>
            </div>

        </div>
    );
};