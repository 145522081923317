import React, {useEffect, useState} from "react";
import "./ui/Bogdan_ref/css/ListPage.css";
import "./ui/Bogdan_ref/css/ListPageRender.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {mainpg} from "./products";
import {useNavigate} from "react-router-dom";
import Header from "./Header/Header";
import {ListProductGrid} from "./ui/Bogdan_ref/ListProductGrid";
import {Recommendation} from "./ui/Bogdan_ref/Recommendation";
import {HeadListProduct} from "./ui/Bogdan_ref/HeadListProduct";
import {FilterMenu} from "./ui/Bogdan_ref/FilterMenu";
import {SearchBar} from "./ui/Bogdan_ref/SearchBar";
import {Modal} from "./ui/Bogdan_ref/Modal";
import {OneBtnModal} from "./ui/Bogdan_ref/OneBtnModal";
import {ViewModeSelector} from "./ui/Bogdan_ref/ViewModeSelector";
import {SortModal} from "./ui/Bogdan_ref/SortModal";
import {Age} from "./ui/Bogdan_ref/Age";
import {CitySelector} from "./ui/Bogdan_ref/CitySelector";
import {PriceFilterModal} from "./ui/Bogdan_ref/PriceFilterModal";
import {ConditionModal} from "./ui/Bogdan_ref/ConditionModal";
import {FilterModal} from "./ui/Bogdan_ref/FilterModal";
import {ViewMode} from "./ui/Bogdan_ref/ViewMode";
import {TwoStepList} from "./ui/Bogdan_ref/TwoStepList";
import {CategoryFilter} from "./ui/Bogdan_ref/CategoryFilter";
import {FiltersLine} from "./ui/Bogdan_ref/FiltersLine";
import {useGetApiServicesGetListQuery} from "../api/SportClickApi";
import MainToSectionHeading from "./MainPage/MainPageToSectionHeading";
import SliderLine from "./ui/ScrollLine/ScrollLine";
import {currentScreenWidth, mobileScreenBreakpoint} from "../utils";

export const PcVersion = () =>{
	const [selectedFormat, setSelectedFormat] = useState("four-view");
	const [itemsPerPage, setItemsPerPage] = useState(12);
	const [cardsPerRow, setCardsPerRow] = useState(4);


	return(
		<div className="container pc">
			<Header/>


			<main className="main__productList">
				<div className="leftside__productList">
					<FilterMenu/>
				</div>

				<div className="rightside__productList">
					<HeadListProduct selectedFormat={selectedFormat} setSelectedFormat={setSelectedFormat} setItemsPerPage={setItemsPerPage} setCardsPerRow={setCardsPerRow}/>
					<ListProductGrid itemsPerPage={itemsPerPage} cardsPerRow={cardsPerRow}/>
					<Recommendation/>
					<div className="main-page__coach-rating">
						<MainToSectionHeading title={"Рейтинг тренеров"} to="/" />
						<SliderLine
							slidesArray={mainpg} // Pass the data for the slides
							slideAddClass={"main-page_coach-card"} // Optionally, pass a class for each slide
							noContentText={"Объявлений пока нет"} // Fallback text if there is no content
							slideType={"advert"}
							hasOnlineAppointment={true}
							linkToSection={"/"}
							slideWidth={currentScreenWidth > mobileScreenBreakpoint ? 230 : 194}
						/>
					</div>
				</div>
			</main>
		</div>
	)
};

////////////////////////////////////////

export const MobileListPage = () => {
	const [push, setPush] = useState(["arena", "school"]);
	const navigate = useNavigate();
	const handleNotification = async () => {
		const categoriesString = push.join(",");
		const url = `/api/user/setcategoriesnew?categoriesnew=${categoriesString}`;

		try {
			const response = await fetch(url, {
				method: "GET", // или 'POST' в зависимости от требований
			});

			if (response.ok) {
				console.log("Успешно отправлено!");
			} else {
				console.error("Ошибка при отправке уведомлений");
			}
		} catch (error) {
			console.error("Ошибка:", error);
		}
	};
	const [isOpen, setIsOpen] = useState(true);
	const [selectedAgeGroup, setSelectedAgeGroup] = useState(null);
	const [searchQuery, setSearchQuery] = useState("");
	const openModalOtobr = () => setIsModalOtobrOpen(true);
	const closeModalOtobr = () => setIsModalOtobrOpen(false);
	const [isModalOtobrOpen, setIsModalOtobrOpen] = useState(false);
	const [isGridView, setIsGridView] = useState(true);
	const [viewMode, setViewMode] = useState("grid");
	const toggleView = () => {
		setIsGridView(!isGridView);
	};
	const [sortType, setSortType] = useState(null);
	const [selectedCity, setSelectedCity] = useState("Москва");
	const [cityDropdownOpen, setCityDropdownOpen] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModalMenuOpen, setIsModalMenuOpen] = useState(false);
	const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
	const [priceRange, setPriceRange] = useState({ min: "", max: "" });
	const [selectedCondition, setSelectedCondition] = useState("Любое");
	const [selectedCategory, setSelectedCategory] = useState("");
	const [selectedDiscount, setSelectedDiscount] = useState("");
	const cities = [
		"Москва",
		"Санкт-Петербург",
		"Новосибирск",
		"Екатеринбург",
		"Казань",
	];
	const [currentSlides, setCurrentSlides] = useState({});
	const getActiveFiltersCount = () => {
		let count = 0;
		if (selectedCategory && selectedCategory !== "Не выбрано") count++;
		if (priceRange.min) count++;
		if (selectedCondition && selectedCondition !== "Любое") count++;
		if (selectedAgeGroup && selectedAgeGroup !== "Взрослые (>18 лет)") count++;
		for (let key in activeFilters) {
			if (activeFilters[key]) count++;
		}
		return count;
	};
	const settings = (productId) => ({
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		afterChange: (current) => handleSlideChange(productId, current), // передаем id товара и текущий слайд
	});
	const handleSlideChange = (productId, current) => {
		setCurrentSlides((prevState) => ({
			...prevState,
			[productId]: current,
		}));
	};
	const ageGroups = [
		"Взрослые (>18 лет)",
		"Подростки (15-17 лет)",
		"Подростки (12-14 лет)",
		"Дети (12-14 лет)",
		"Дети (8-12 лет)",
		"Дети (5-7 лет)",
	];
	const conditions = ["Любое", "Новое", "Б/У"];
	const category = [
		"Форма для полевого игрока",
		"Форма для вратаря",
		"Тренировочный инвентарь",
		"Прочее",
	];
	const openModal = () => setIsModalOpen(true);
	const closeModal = () => setIsModalOpen(false);
	const openModalMenu = () => setIsModalMenuOpen(true);
	const closeModalMenu = () => setIsModalMenuOpen(false);
	const openFilterModal = () => setIsFilterModalOpen(true);
	const closeFilterModal = () => setIsFilterModalOpen(false);
	const handleApplyFilter = () => {
		closeModal();
	};
	const handleClearSelection = () => {
		setSelectedAgeGroup("");
	};
	const handleCitySelect = (city) => {
		setSelectedCity(city);
		setCityDropdownOpen(false);
	};
	let filteredProducts = mainpg.filter(
		(product) =>
			product.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
			product.city === selectedCity &&
			(selectedAgeGroup === "" || product.ageGroup === selectedAgeGroup) &&
			(selectedCondition === "Любое" ||
				product.condition === selectedCondition) &&
			(selectedCategory.length === 0 ||
				selectedCategory.includes(product.category)) &&
			(!priceRange.min || product.price >= parseInt(priceRange.min)) &&
			(!priceRange.max || product.price <= parseInt(priceRange.max))
	);
	if (sortType === "price-asc") {
		filteredProducts.sort((a, b) => a.price - b.price);
	} else if (sortType === "price-desc") {
		filteredProducts.sort((a, b) => b.price - a.price);
	} else if (sortType === "rating") {
		filteredProducts.sort((a, b) => b.rating - a.rating);
	} else if (sortType === "date") {
		filteredProducts.sort((a, b) => b.dat - a.dat);
	}
	const groupedProducts = [];
	for (let i = 0; i < filteredProducts.length; i += 2) {
		groupedProducts.push(filteredProducts.slice(i, i + 2));
	}

	const handleApplySort = () => {
		closeSortModal();
	};
	const handleApplyAge = () => {
		closeAgeModal();
	};
	const [isPriceDropdownOpen, setIsPriceDropdownOpen] = useState(false);
	const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
	const [isConditionDropdownOpen, setIsConditionDropdownOpen] = useState(false);
	const togglePriceDropdown = () =>
		setIsPriceDropdownOpen(!isPriceDropdownOpen);
	const toggleCategoryDropdown = () =>
		setIsCategoryDropdownOpen(!isCategoryDropdownOpen);
	const toggleConditionDropdown = () =>
		setIsConditionDropdownOpen(!isConditionDropdownOpen);
	const [activeFilters, setActiveFilters] = useState({
		discount: false,
		online: false,
	});
	const [isFilterActive, setIsFilterActive] = useState(false);
	const handleApplyFilterModal = () => {
		if (selectedCategory.length > 0 || priceRange.min || selectedCondition) {
			setIsFilterActive(true);
		} else {
			setIsFilterActive(false);
		}
		closeFilterModal();
	};
	const handleFilterClick = (filter) => {
		setActiveFilters((prevFilters) => ({
			...prevFilters,
			[filter]: !prevFilters[filter],
		}));

		if (filter === "adults") {
			setIsModalOpen(true);
		}
	};
	const [isPriceModalOpen, setIsPriceModalOpen] = useState(false);
	const [isConditionModalOpen, setIsConditionModalOpen] = useState(false);
	const openPriceModal = () => setIsPriceModalOpen(true);
	const closePriceModal = () => setIsPriceModalOpen(false);
	const openConditionModal = () => setIsConditionModalOpen(true);
	const closeConditionModal = () => setIsConditionModalOpen(false);
	const handleClearPriceSelection = () => setPriceRange({ min: "", max: "" });
	const handleClearConditionSelection = () => setSelectedCondition("Любое");
	const handleApplyPriceFilter = () => {
		// Применяем фильтр по цене
		closePriceModal();
	};
	const handleApplyConditionFilter = () => {
		// Применяем фильтр по состоянию
		closeConditionModal();
	};
	const handleCategoryClick = (category) => {
		setSelectedCategory((prevSelectedCategory) => {
			if (prevSelectedCategory.includes(category)) {
				// Убираем категорию, если она уже выбрана
				return prevSelectedCategory.filter((cat) => cat !== category);
			} else {
				// Добавляем категорию, если она еще не выбрана
				return [...prevSelectedCategory, category];
			}
		});
	};
	const handleResetFilters = () => {
		setSelectedCity("Выберите город");
		setSelectedAgeGroup("Выберите возрастную группу");
		setPriceRange({ min: 0, max: 45000 });
		setSelectedCategory([]);
		setSelectedCondition("Любое");
	};
	const clearSearch = () => {
		setSearchQuery("");
	};
	const [isSortModalOpen, setIsSortModalOpen] = useState(false);
	const openSortModal = () => setIsSortModalOpen(true);
	const closeSortModal = () => setIsSortModalOpen(false);

	const [isAgeModalOpen, setIsAgeModalOpen] = useState(false);
	const openAgeModal = () => setIsAgeModalOpen(true);
	const closeAgeModal = () => setIsAgeModalOpen(false);

	const handleClear = () => {
		setSelectedAgeGroup(null);
	};


	return (
		<div className="MobileList">
			<SearchBar
				searchQuery={searchQuery}
				setSearchQuery={setSearchQuery}
				clearSearch={clearSearch}
			/>
			<OneBtnModal isOpen={isModalOtobrOpen} onClose={closeModalOtobr} onClear={() => setViewMode("grid")}>
				<ViewModeSelector viewMode={viewMode} onClose={closeModalOtobr} children={<CategoryFilter category={category} selectedCategory={selectedCategory} handleCategoryClick={handleCategoryClick}/>} setViewMode={setViewMode} />
			</OneBtnModal>
			<OneBtnModal isOpen={isSortModalOpen} onClose={closeSortModal} onClear={() => setSortType("price-asc")} children={<SortModal isOpen={isSortModalOpen} sortType={sortType} onClose={closeSortModal} onSortChange={setSortType}  onApply={handleApplySort}/>}/>
			<Modal onClear={handleClear} hasSelection={!!selectedAgeGroup} isOpen={isAgeModalOpen} onClose={closeAgeModal} children={<Age ageGroups={ageGroups} isOpen={isAgeModalOpen} setSelectedAgeGroup={setSelectedAgeGroup}  selectedAgeGroup={selectedAgeGroup} onClose={closeAgeModal}  onClear={() => setSortType(null)} onApply={handleApplyAge}/>}/>
			<CitySelector
				selectedCity={selectedCity}
				cities={cities}
				handleCitySelect={handleCitySelect}
				cityDropdownOpen={cityDropdownOpen}
				setCityDropdownOpen={setCityDropdownOpen}
			/>
			<FiltersLine
				getActiveFiltersCount={getActiveFiltersCount}
				openFilterModal={openFilterModal}
				openModal={openModal}
				category={category}
				selectedCategory={selectedCategory}
				handleCategoryClick={handleCategoryClick}
				priceRange={priceRange}
				openPriceModal={openPriceModal}
				selectedCondition={selectedCondition}
				openConditionModal={openConditionModal}
				selectedAgeGroup={selectedAgeGroup}
				setSelectedAgeGroup={setSelectedAgeGroup}
				openAgeModal={openAgeModal}
			/>
			<ViewMode sortType={sortType} viewMode={viewMode} openSortModal={openSortModal} openModalOtobr={openModalOtobr}/>
			<TwoStepList viewMode={viewMode} groupedProducts={groupedProducts} settings={settings} currentSlides={currentSlides}/>
			<Recommendation/>
			<PriceFilterModal
				isPriceModalOpen={isPriceModalOpen}
				closePriceModal={closePriceModal}
				priceRange={priceRange}
				setPriceRange={setPriceRange}
				handleApplyPriceFilter={handleApplyPriceFilter}
			/>
			<ConditionModal
				isOpen={isConditionModalOpen}
				onClose={closeConditionModal}
				conditions={conditions}
				selectedCondition={selectedCondition}
				onSelectCondition={setSelectedCondition}
				onClear={handleClearConditionSelection}
				onApply={handleApplyConditionFilter}
			/>
			<FilterModal
				isOpen={isFilterModalOpen}
				onClose={closeFilterModal}
				selectedCity={selectedCity}
				onCitySelect={handleCitySelect}
				cities={cities}
				priceRange={priceRange}
				setPriceRange={setPriceRange}
				category={category}
				setSelectedCity={setSelectedCity}
				selectedCategory={selectedCategory}
				onCategoryClick={handleCategoryClick}
				selectedCondition={selectedCondition}
				onConditionSelect={setSelectedCondition}
				onApply={handleApplyFilterModal}
				onReset={handleResetFilters}
				closeFilterModal={closeFilterModal}
			/>
		</div>
	);
};

const ResponsiveComponent = () => {
	const [isPcVersion, setIsPcVersion] = useState(window.innerWidth > 1280);

	const service = useGetApiServicesGetListQuery();
	useEffect(() => {
		const handleResize = () => {
			setIsPcVersion(window.innerWidth > 1280);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return isPcVersion ? <PcVersion /> : <MobileListPage />;
};

export default ResponsiveComponent;
