import { useState } from "react";
import styles from "./SearchInput.module.css";

export default function SearchInput({
	placeholder,
	customPlaceholder,
	setExternalValue,
	textRemoverElement,
	value,
	name,
	id,
}) {
	const [textRemoverVisible, setTextRemoverVisible] = useState(false);

	const handleInput = (e) => {
		if (setExternalValue) {
			setExternalValue(e.target.value);
			if (textRemoverElement) {
				if (e.target.value !== "") {
					setTextRemoverVisible(true);
				} else {
					setTextRemoverVisible(false);
				}
			}
		}
	};

	const removeText = () => {
		if (setExternalValue) {
			setExternalValue("");
			setTextRemoverVisible(false);
		}
	};

	return (
		<div className={styles.searchInput_wrapper}>
			<img
				className={styles.searchInput_searchIcon}
				src="/images/icons/search_icon.svg"
				alt="Поиск"
			/>
			<input
				type="text"
				placeholder={placeholder && placeholder}
				value={value && value}
				name={name & name}
				id={id && id}
				onInput={(e) => handleInput(e)}
			/>
			{customPlaceholder && value === "" && customPlaceholder}
			<div onClick={removeText}>
				{textRemoverElement && textRemoverVisible && textRemoverElement}
			</div>
		</div>
	);
}
