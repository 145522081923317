import React from "react";

export const CitySelector = ({selectedCity, cities, handleCitySelect, cityDropdownOpen, setCityDropdownOpen}) => (
    <div className="step step-3">
        <div className="custom-select">
			<span className="city"><img style={{width: 11, height: 14, objectFit: "cover"}} src="/images/icons/geo.svg"
                                        alt=""/>
<p>{selectedCity}</p></span>
            <button className="city city-button" onClick={() => setCityDropdownOpen(!cityDropdownOpen)}>
                <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M0 10.8889C0 10.4593 0.27467 10.1111 0.613493 10.1111H7.11378C7.4526 10.1111 7.72727 10.4593 7.72727 10.8889C7.72727 11.3184 7.4526 11.6666 7.11378 11.6666H0.613493C0.27467 11.6666 0 11.3184 0 10.8889Z"
                          fill="#3C3C3C"/>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M9.27246 3.11079C9.27246 2.68123 9.54713 2.33301 9.88595 2.33301H16.3862C16.7251 2.33301 16.9997 2.68123 16.9997 3.11079C16.9997 3.54034 16.7251 3.88856 16.3862 3.88856H9.88595C9.54713 3.88856 9.27246 3.54034 9.27246 3.11079Z"
                          fill="#3C3C3C"/>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M0 3.11111C0 1.39354 1.3835 0 3.09091 0C4.79832 0 6.18182 1.39354 6.18182 3.11111C6.18182 4.82868 4.79832 6.22222 3.09091 6.22222C1.3835 6.22222 0 4.82868 0 3.11111ZM3.09091 1.25011C2.07089 1.25011 1.24284 2.08296 1.24284 3.11111C1.24284 4.13926 2.07089 4.97211 3.09091 4.97211C4.11092 4.97211 4.93898 4.13926 4.93898 3.11111C4.93898 2.08296 4.11092 1.25011 3.09091 1.25011Z"
                          fill="#3C3C3C"/>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M10.8184 10.8885C10.8184 9.17075 12.202 7.77734 13.9101 7.77734C15.6178 7.77734 17.0002 9.17117 17.0002 10.8885C17.0002 12.6057 15.6178 13.9996 13.9101 13.9996C12.202 13.9996 10.8184 12.6062 10.8184 10.8885ZM13.9101 9.02746C12.8891 9.02746 12.0612 9.86045 12.0612 10.8885C12.0612 11.9165 12.8891 12.7495 13.9101 12.7495C14.9299 12.7495 15.7573 11.9169 15.7573 10.8885C15.7573 9.86003 14.9299 9.02746 13.9101 9.02746Z"
                          fill="#3C3C3C"/>
                </svg>
                <p>Выбрать город</p>
            </button>
            {cityDropdownOpen && (
                <div className="dropdown">
                    {cities.map((city) => (
                        <button key={city} onClick={() => handleCitySelect(city)}>
                            {city}
                        </button>
                    ))}
                </div>
            )}
        </div>
    </div>
);