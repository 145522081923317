import React from "react";

export const Control = ({toggleOpen, isPhoneHidden, togglePhoneVisibility, sortedPredloj, openModalMenu, isOpen}) => {
    return (
        <div className="product__admin">
            <div onClick={toggleOpen}><h1>Управление</h1> <p style={{cursor: 'pointer'}}></p></div>


            <div className={`content product__admin ${isOpen ? 'open' : ''}`}>
                <p className="tolkVlad">Раздел доступен только для владельца объявления</p>


                <div className="btn__admin">
                    <a href=""><p></p> Редактировать</a>
                    <a href=""><p></p> Удалить</a>
                </div>

                <div className="btn__admin">
                    <a href="" style={{color: "#1BA524", backgroundColor: "#D8FFD8"}}> Добавить объявление</a>
                </div>

                <div className="srok__admin">
                    <span>Вы можете поднять объявление в топ <strong>1 раз</strong> в течение <strong>14 дней</strong></span>
                    <span>Объявление поднимается каждые <strong>2 часа в течение 8 часов</strong> в рамках одного дня</span>
                    <span style={{marginBottom: 4}}>Место вашего объявления: <strong>33</strong> из 85</span>
                    <a href="">Продлить размещение</a>
                </div>

                <div className="switch-group prod__sw">
                    <label className="switch-label">
                        <span style={{fontWeight: 400}}>Скрыть мобильный номер</span>
                        <label className="switch">
                            <input type="checkbox" className="hidden" checked={isPhoneHidden}
                                   onChange={togglePhoneVisibility}/>
                            <span className="slider round"></span>
                        </label>
                    </label>
                </div>

                {/*<div className="switch-group prod__sw">*/}
                {/*    <label className="switch-label">*/}
                {/*        <span style={{ fontWeight: 400 }}>Разместить фотографии в общем перечне объявлений</span>*/}
                {/*        <label className="switch">*/}
                {/*            <input type="checkbox" className="hidden" />*/}
                {/*            <span className="slider round"></span>*/}
                {/*        </label>*/}
                {/*    </label>*/}
                {/*</div>*/}

                <a href="">Поднять объявление</a>

                <h1 style={{marginTop: 4}}>Предложения</h1>

                <div className="predloj-cards">
                    {sortedPredloj.map((predloj, index) => (
                        <div key={index} className="card-predloj">
                            <div className="ls">
                                <span>{predloj.day} <p></p> {predloj.dataPR}</span>

                                <h2>{predloj.time}</h2>
                            </div>

                            <div className="rs">
                                <div onClick={openModalMenu}><p onClick={openModalMenu}></p><p
                                    onClick={openModalMenu}></p><p onClick={openModalMenu}></p></div>

                                <h2>{predloj.price}</h2>
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </div>
    )
}
export const Status = ({toggleOpen1, isOpen1, setSelectedTab, selectedTab, isSortModalOpen,closeSortModal, setSortType,sortedApplications,handleReject, handleAccept, openSortModal, sortType,
                           handleApplySort,
                           id,
                           date,
                           time,
                           orderNumber,
                           status,
                           imgUrl,
                           user,
                           sessions,
                           day,
                           onSpot,
    app
                       }) => {
    return (
        <div className="product__admin">
            <div onClick={toggleOpen1}><h1>Статус заявок</h1> <p style={{cursor: 'pointer'}}></p></div>


            <div className={`content product__admin ${isOpen1 ? 'open' : ''}`}>
                <p className="tolkVlad">Раздел доступен только для владельца объявления</p>


                <div className="tabs">
                    <button
                        onClick={() => setSelectedTab('All')}
                        className={selectedTab === 'All' ? 'active' : ''}
                    >
                        Все заявки
                    </button>
                    <button
                        onClick={() => setSelectedTab('Pending')}
                        className={selectedTab === 'Pending' ? 'active' : ''}
                    >
                        На рассмотрении
                    </button>
                    <button
                        onClick={() => setSelectedTab('Approved')}
                        className={selectedTab === 'Approved' ? 'active' : ''}
                    >
                        Согласовано
                    </button>
                    <button
                        onClick={() => setSelectedTab('Not Approved')}
                        className={selectedTab === 'Not Approved' ? 'active' : ''}
                    >
                        Не согласовано
                    </button>
                </div>
                <div className="step step-5" style={{marginBottom: 0}}>
                    <div className="custom-select">
                        <button className="sort-button" onClick={openSortModal}><span></span> Сортировка</button>
                    </div>
                    <button className="notify-button"><img src="/Group.svg" alt=""/> Уведомлять о заявках</button>
                </div>

                {isSortModalOpen && (
                    <div className="modal">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button className="back-button" onClick={closeSortModal}></button>
                                <h2 style={{marginTop: 0, marginLeft: 24}}>Сортировка</h2>
                                <button className="clear-btn" onClick={() => setSortType(null)}>Очистить</button>
                            </div>
                            <div className="modal-body">
                                <div className="radio-group" style={{flexDirection: 'row'}}>
                                    <input
                                        type="radio"
                                        id="price-asc"
                                        name="sortType"
                                        value="all-time"
                                        checked={sortType === 'all-time'}
                                        onChange={(e) => setSortType(e.target.value)}
                                    />
                                    <label htmlFor="all-time">За все время</label>
                                </div>
                                <div className="radio-group" style={{flexDirection: 'row', marginTop: 0}}>
                                    <input
                                        type="radio"
                                        id="price-asc"
                                        name="sortType"
                                        value="year"
                                        checked={sortType === 'year'}
                                        onChange={(e) => setSortType(e.target.value)}
                                    />
                                    <label htmlFor="year">За год</label>
                                </div>
                                <div className="radio-group" style={{flexDirection: 'row'}}>
                                    <input
                                        type="radio"
                                        id="price-desc"
                                        name="sortType"
                                        value="half-year"
                                        checked={sortType === 'half-year'}
                                        onChange={(e) => setSortType(e.target.value)}
                                    />
                                    <label htmlFor="half-year">За полгода</label>
                                </div>
                                <div className="radio-group" style={{flexDirection: 'row'}}>
                                    <input
                                        type="radio"
                                        id="date"
                                        name="sortType"
                                        value="quarter"
                                        checked={sortType === 'quarter'}
                                        onChange={(e) => setSortType(e.target.value)}
                                    />
                                    <label htmlFor="quarter">За квартал</label>
                                </div>
                                <div className="radio-group" style={{flexDirection: 'row'}}>
                                    <input
                                        type="radio"
                                        id="status"
                                        name="sortType"
                                        value="week"
                                        checked={sortType === 'week'}
                                        onChange={(e) => setSortType(e.target.value)}
                                    />
                                    <label htmlFor="week">За неделю</label>
                                </div>
                                <div className="radio-group" style={{flexDirection: 'row'}}>
                                    <input
                                        type="radio"
                                        id="status"
                                        name="sortType"
                                        value="today"
                                        checked={sortType === 'today'}
                                        onChange={(e) => setSortType(e.target.value)}
                                    />
                                    <label htmlFor="today">За сегодня</label>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="apply-btn" onClick={handleApplySort}>Применить сортировку
                                </button>
                            </div>
                        </div>
                    </div>
                )}


                {sortedApplications.map(app => (
                    <div key={app.id} className="application-card" style={{marginBottom: 16}}>
                        <h3>Заявка от {app.date} • {app.time} <p>№ {app.orderNumber}</p></h3>
                        <div className="card-hr">
                            <div>
                                    <span
                                        className={`status ${app.status.toLowerCase()}`}>{app.status === 'Approved' ? 'Согласовано' : app.status === 'Pending' ? 'На рассмотрении' : 'Не согласовано'}
                                    </span>
                                <h3>{app.date} • {app.time}</h3>
                            </div>
                            <img src={app.imgUrl} alt="" className="user-image"/>
                        </div>
                        <p className="card-tt"><p>ФИО</p> <p>{app.user}</p></p>


                        {app.sessions.map((session, index) => (
                            <div key={index} className="session">
                                <p className="card-tt" style={{marginBottom: 0}}>
                                    <p>{session.day} • {session.date}</p>  <p>{session.time}</p></p>
                                {/*<p className="card-tt" style={{marginBottom:0}}><p>Предоплата картой</p>  <p>{session.prepay}</p></p>*/}
                                <p className="card-tt" style={{marginBottom: 0}}><p>Оплата на месте</p>
                                    <p>{session.onSpot}</p></p>
                            </div>
                        ))}


                        <textarea placeholder="Комментарий" value={app.comment}></textarea>

                        {app.status === 'Pending' && (
                            <div className="actions">
                                <button onClick={() => handleReject(app.id)}>Отклонить</button>
                                <button onClick={() => handleAccept(app.id)}>Принять</button>
                            </div>
                        )}

                        <button>Написать</button>
                    </div>
                ))}
            </div>
        </div>
    )
}