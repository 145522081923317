import React from "react";

export const ViewModeSelector = ({viewMode, setViewMode, children, onClose}) => {
    return (
        <>
            <div className="modal-header">
                <svg width="18" onClick={onClose} height="16" viewBox="0 0 18 16" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.29289 0.292893C7.68342 -0.0976311 8.31658 -0.0976311 8.70711 0.292893C9.09763 0.683417 9.09763 1.31658 8.70711 1.70711L3.414 7H17C17.5128 7 17.9355 7.38604 17.9933 7.88338L18 8C18 8.55228 17.5523 9 17 9H3.414L8.70711 14.2929C9.06759 14.6534 9.09532 15.2206 8.7903 15.6129L8.70711 15.7071C8.31658 16.0976 7.68342 16.0976 7.29289 15.7071L0.292893 8.70711L0.219689 8.62545C0.217372 8.62256 0.215071 8.61966 0.212786 8.61675C0.207285 8.60984 0.201753 8.6026 0.196334 8.59531C0.17849 8.57113 0.161719 8.54627 0.146068 8.52066C0.138607 8.50861 0.131499 8.49639 0.124671 8.48406C0.113794 8.46429 0.103377 8.44389 0.0936537 8.4231C0.0856789 8.4061 0.0781966 8.38879 0.0712256 8.37134C0.0633159 8.35158 0.0561225 8.3318 0.0495467 8.31174C0.0447288 8.29685 0.0400979 8.28146 0.0358453 8.26599C0.0298338 8.24444 0.0246396 8.22274 0.020165 8.20079C0.016702 8.18337 0.0136281 8.16595 0.0110178 8.14847C0.00376119 8.10036 0 8.05062 0 8L0.00396633 8.08925C0.0018949 8.06599 0.000634706 8.04268 0.000185966 8.01935L0 8C0 7.99359 6.03044e-05 7.9872 0.000180244 7.98082C0.000599384 7.95798 0.00186552 7.93433 0.00396633 7.91075C0.00576604 7.89015 0.00811212 7.8705 0.0110192 7.85104C0.013628 7.83405 0.0167024 7.81662 0.0202403 7.79927C0.02464 7.77725 0.0298335 7.75556 0.0357208 7.73416C0.0400976 7.71854 0.0447286 7.70315 0.0497379 7.68786C0.0561223 7.6682 0.0633158 7.64842 0.071104 7.62894C0.0781965 7.6112 0.0856789 7.5939 0.0936732 7.57678C0.103377 7.55611 0.113794 7.53571 0.124876 7.51572C0.131499 7.50361 0.138607 7.49139 0.145996 7.47929C0.161719 7.45372 0.17849 7.42887 0.196313 7.40484C0.225313 7.36567 0.257499 7.32829 0.292893 7.29289L0.212786 7.38325C0.237669 7.35153 0.264427 7.32136 0.292893 7.29289L7.29289 0.292893Z"
                        fill="#3C3C3C"/>
                </svg>


                <h2 className="modal-title">Формат отображения</h2>
            </div>

            <div className="modal-menu-content">
                {["grid", "solo", "list"].map((mode) => (
                    <div className="modal-stroke" key={mode}>
                        <input
                            type="radio"
                            id={`${mode}-view`}
                            name="viewMode"
                            value={mode}
                            checked={viewMode === mode}
                            onChange={() => setViewMode(mode)}
                        />
                        <label
                            htmlFor={`${mode}-view`}>{mode === "grid" ? "Сеткой" : mode === "solo" ? "По одному" : "Вертикально"}</label>
                    </div>
                ))}
            </div>
        </>
    )
}