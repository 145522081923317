import React from "react";

export const SearchBar = ({searchQuery, setSearchQuery, clearSearch}) => (
    <div className="step step-2">
        <svg width="18" height="19" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.29289 0.292893C7.68342 -0.0976311 8.31658 -0.0976311 8.70711 0.292893C9.09763 0.683417 9.09763 1.31658 8.70711 1.70711L3.414 7H17C17.5128 7 17.9355 7.38604 17.9933 7.88338L18 8C18 8.55228 17.5523 9 17 9H3.414L8.70711 14.2929C9.06759 14.6534 9.09532 15.2206 8.7903 15.6129L8.70711 15.7071C8.31658 16.0976 7.68342 16.0976 7.29289 15.7071L0.292893 8.70711L0.219689 8.62545C0.217372 8.62256 0.215071 8.61966 0.212786 8.61675C0.207285 8.60984 0.201753 8.6026 0.196334 8.59531C0.17849 8.57113 0.161719 8.54627 0.146068 8.52066C0.138607 8.50861 0.131499 8.49639 0.124671 8.48406C0.113794 8.46429 0.103377 8.44389 0.0936537 8.4231C0.0856789 8.4061 0.0781966 8.38879 0.0712256 8.37134C0.0633159 8.35158 0.0561225 8.3318 0.0495467 8.31174C0.0447288 8.29685 0.0400979 8.28146 0.0358453 8.26599C0.0298338 8.24444 0.0246396 8.22274 0.020165 8.20079C0.016702 8.18337 0.0136281 8.16595 0.0110178 8.14847C0.00376119 8.10036 0 8.05062 0 8L0.00396633 8.08925C0.0018949 8.06599 0.000634706 8.04268 0.000185966 8.01935L0 8C0 7.99359 6.03044e-05 7.9872 0.000180244 7.98082C0.000599384 7.95798 0.00186552 7.93433 0.00396633 7.91075C0.00576604 7.89015 0.00811212 7.8705 0.0110192 7.85104C0.013628 7.83405 0.0167024 7.81662 0.0202403 7.79927C0.02464 7.77725 0.0298335 7.75556 0.0357208 7.73416C0.0400976 7.71854 0.0447286 7.70315 0.0497379 7.68786C0.0561223 7.6682 0.0633158 7.64842 0.071104 7.62894C0.0781965 7.6112 0.0856789 7.5939 0.0936732 7.57678C0.103377 7.55611 0.113794 7.53571 0.124876 7.51572C0.131499 7.50361 0.138607 7.49139 0.145996 7.47929C0.161719 7.45372 0.17849 7.42887 0.196313 7.40484C0.225313 7.36567 0.257499 7.32829 0.292893 7.29289L0.212786 7.38325C0.237669 7.35153 0.264427 7.32136 0.292893 7.29289L7.29289 0.292893Z"
                fill="#3C3C3C"/>
        </svg>


        <label className="search__container">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                 className={searchQuery ? "search-icon hide" : "search-icon"}>
                <path
                    d="M19 19L13 13M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z"
                    stroke="#818189" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <input
                type="text"
                placeholder="Поиск"
                className={searchQuery ? "search-input hide" : "search-input"}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
            <svg className={searchQuery ? "clear-button visible" : "clear-button"} width="20" height="20"
                 viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="10" r="10" fill="#DADADA"/>
                <path d="M6 6L14 14M6 14L14 6" stroke="#818189" stroke-width="1.5"/>
            </svg>

        </label>
        <svg width="34" height="30" viewBox="0 0 34 30"
             className={searchQuery ? "clear-button hide" : "icon-btn favorite"} fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <rect width="34" height="30" rx="12" fill="#EAEAEA"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M23.6111 9.98712C23.6111 7.61708 21.9637 6.66699 19.5919 6.66699H14.0219C11.7229 6.66699 10 7.5523 10 9.82906V22.5145C10 23.1398 10.6841 23.5337 11.2382 23.2279L16.8284 20.1436L22.3703 23.2227C22.9253 23.5302 23.6111 23.1364 23.6111 22.5102V9.98712Z"
                  stroke="#818189" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.5684 12.4631H19.9788" stroke="#818189" stroke-width="1.2" stroke-linecap="round"
                  stroke-linejoin="round"/>
        </svg>
        <svg width="34" height="30" viewBox="0 0 34 30" className={searchQuery ? "clear-button hide" : "icon-btn cart"}
             fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="34" height="30" rx="12" fill="#EAEAEA"/>
            <path
                d="M8 6.66699H9.479C10.397 6.66699 11.1195 7.44199 11.043 8.33366L10.3375 16.6337C10.2185 17.992 11.315 19.1587 12.709 19.1587H21.7615C22.9855 19.1587 24.0565 18.1753 24.15 16.9837L24.609 10.7337C24.711 9.35033 23.64 8.22532 22.2205 8.22532H11.247"
                stroke="#818189" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                stroke-linejoin="round"/>
            <path d="M13.95 11.667H24.15" stroke="#818189" stroke-width="1.5" stroke-miterlimit="10"
                  stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M22.15 21.8448C22.15 21.0231 21.47 20.3564 20.6319 20.3564C19.7937 20.3564 19.1137 21.0231 19.1137 21.8448C19.1137 22.6665 19.7937 23.3332 20.6319 23.3332C21.47 23.3332 22.15 22.6665 22.15 21.8448Z"
                fill="#818189"/>
            <path
                d="M15.4923 21.8448C15.4923 21.0231 14.8123 20.3564 13.9742 20.3564C13.1361 20.3564 12.4561 21.0231 12.4561 21.8448C12.4561 22.6665 13.1361 23.3332 13.9742 23.3332C14.8123 23.3332 15.4923 22.6665 15.4923 21.8448Z"
                fill="#818189"/>
        </svg>

        <p className={searchQuery ? "clear-text visible" : "clear-text"}>Вернуться</p>
    </div>
);