import {ProductScrollLine} from "./Product";
import React from "react";

export const TwoStepList = ({viewMode, currentSlides, settings, groupedProducts}) => (
    <div
        className={`product-scroll-container ${
            viewMode === "grid"
                ? "grid-view"
                : viewMode === "list"
                    ? "list-view"
                    : "solo-view"
        }`}>
        <div className="products-row">
            {groupedProducts.length > 0 ? (
                groupedProducts.map((group, index) => (
                    <div className="product-column" key={index}>
                        {group.map((product) => (
                            <ProductScrollLine group={group} index={index} product={product}/>
                        ))}
                    </div>
                ))
            ) : (
                <div className="no-products">
                    <p>По выбранным фильтрам объявлений нет</p>
                </div>
            )}
        </div>
    </div>
)