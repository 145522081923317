import React from "react";

export const Modal = ({
                          isOpen, children, onClose, onClear,
                          hasSelection,
                      }) => {
    if (!isOpen) return null;

    const handleBackdropClick = (e) => {
        if (e.target.classList.contains("modal")) {
            onClose();
        }
    };

    return (
        <div className="modal" onClick={handleBackdropClick}>
            <div className="modal-content">
                {children}

                <button className={`apply-btn gray ${hasSelection ? "active" : ""}`} style={{borderRadius: 99}}
                        onClick={onClear}>
                    Очистить фильтр
                </button>
                <button className="apply-btn" style={{borderRadius: 99}} onClick={onClose}>
                    Применить
                </button>
            </div>
        </div>
    );
};