import { NavLink, useLocation } from "react-router-dom";
import styles from "./NavPanel.module.css";
import { useEffect } from "react";

export default function NavPanel() {
	const location = useLocation();
	return (
		<div className={styles.navPanel}>
			<NavLink className={styles.navPanel_button} to="/">
				<img
					src={
						location.pathname === "/"
							? "/images/nav_panel/home_active.svg"
							: "/images/nav_panel/home.svg"
					}
					alt="Домой"
				/>
				<p>Главное</p>
			</NavLink>
			<NavLink className={styles.navPanel_button}>
				<img src="/images/nav_panel/services.svg" alt="Домой" />
				<p>Сервисы</p>
			</NavLink>

			<button
				className={`${styles.navPanel_button} ${styles.navPanel_button_add}`}>
				<div className={styles.navPanel_button_add_icon}>
					<img src="/images/nav_panel/plus.svg" alt="Домой" />
				</div>
				<p>Добавить</p>
			</button>

			<NavLink to="/chat" className={styles.navPanel_button}>
				<img
					src={
						location.pathname === "/chat"
							? "/images/nav_panel/chat_active.svg"
							: "/images/nav_panel/chat.svg"
					}
					alt="Домой"
				/>
				<p>Входящие</p>
			</NavLink>
			<NavLink className={styles.navPanel_button}>
				<img src="/images/nav_panel/playBox.svg" alt="Домой" />
				<p>Журнал</p>
			</NavLink>
		</div>
	);
}
