import React, { useState } from 'react';

export const CityFilter = ({ cities, selectedCity, setSelectedCity }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeCity, setActiveCity] = useState(null);

    const handleCityClick = (city) => {
        setActiveCity(city);
    };

    const handleSave = () => {
        if (activeCity) {
            setSelectedCity(activeCity);
        }
        setIsOpen(false);
    };

    return (
        <div className="filter-group">
            <label style={{ fontWeight: 700 }}>Выбрать город</label>
            <div className="custom-select">
                <div className="dropdown-container">
                    <div
                        className="dropdown-trigger"
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <span className="dropdown-label">{selectedCity}</span>
                        <span className="dropdown-value"></span>
                    </div>
                </div>
                {isOpen && (
                    <div className="dropdown">
                        {cities.map((city) => (
                            <button
                                key={city}
                                className={city === activeCity ? 'active-city' : ''}
                                onClick={() => handleCityClick(city)}
                            >
                                {city}
                                {city === activeCity && <img src="/images/icons/apply.svg" alt="" />}
                            </button>
                        ))}
                        <button className="save" onClick={handleSave}>
                            Сохранить
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};
