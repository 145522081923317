import React, { useEffect, useRef, useState } from "react";
import styles from "./MonthPickerSimplified.module.css";

export default function MonthPickerSimplified() {
  const [selectedDay, setSelectedDay] = useState(null);

  const today = new Date();
  const currentDay = today.getDate();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();
  
  // Calculate the date 2 weeks from today
  const twoWeeksLater = new Date(today);
  twoWeeksLater.setDate(today.getDate() + 14);
  
  const formatDate = (year, month, day) => {
    const formattedMonth = (month + 1).toString().padStart(2, "0");
    const formattedDay = day.toString().padStart(2, "0");
    return `${year}-${formattedMonth}-${formattedDay}`;
  };
  
  const daysOfYear = [];
  let date = new Date(currentYear, currentMonth, currentDay);
  let currentMonthRendered = null;
  let currentYearRendered = null;
  
  while (date <= twoWeeksLater) {
    const monthIndex = date.getMonth();
    const day = date.getDate();
    const year = date.getFullYear();
    const timestamp = formatDate(year, monthIndex, day);
  
    // Add year header if necessary
    if (currentYearRendered !== year) {
      currentYearRendered = year;
      daysOfYear.push({
        type: "year",
        year,
      });
    }
  
    // Add month header if necessary
    if (currentMonthRendered !== monthIndex) {
      currentMonthRendered = monthIndex;
      daysOfYear.push({
        type: "month",
        name: date.toLocaleString("ru", { month: "short" }),
      });
    }
  
    // Add the day to the array
    const dayDate = new Date(year, monthIndex, day);
    daysOfYear.push({
      type: "day",
      day,
      month: date.toLocaleString("ru", { month: "short" }),
      monthIndex,
      year,
      date: dayDate,
      timestamp,
    });
  
    // Move to the next day
    date.setDate(date.getDate() + 1);
  }

  const weekDays = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];

  const getDayOfWeek = (date) => {
    return weekDays[date.getDay()]; 
  };

  const handleDaySelection = (timestamp) => {
    setSelectedDay((prevSelectedDay) =>
      prevSelectedDay === timestamp ? null : timestamp
    );
  };

  const [offset, setOffset] = useState(0);
  const scrollLineRef = useRef(null);
  const offsetScrollModifier = 300;

  const handleScrollLeft = () => {
    if (offset === 0) return;
    if (offset - offsetScrollModifier < 0) {
      setOffset(0);
    } else {
      setOffset((prev) => prev - offsetScrollModifier);
    }
  };

  const handleScrollRight = () => {
    if (scrollLineRef.current.offsetWidth) {
      if (offset + offsetScrollModifier >= scrollLineRef.current.offsetWidth)
        return;
      setOffset((prev) => prev + offsetScrollModifier);
    }
  };

  useEffect(() => {
    const currentDayIndex = daysOfYear.findIndex(
      (item) =>
        item.type === "day" &&
        item.day === currentDay &&
        item.monthIndex === currentMonth
    );

    if (currentDayIndex !== -1) {
      const dayElement = document.getElementById(
        `day-${daysOfYear[currentDayIndex].timestamp}`
      );
      if (dayElement && scrollLineRef.current) {
        const dayPosition = dayElement.offsetLeft;
        setOffset(dayPosition);
      }
    }
  }, [currentDay, currentMonth]);

  return (
    <div className={styles.calendar_container}>
      <button
        onClick={handleScrollLeft}
        className={`${styles.monthPicker_scrollButton} ${styles.monthPicker_scrollButtons_prev}`}
      >
        <img src="/images/main_page/arrow-left-month-picker.svg" alt="Влево" />
      </button>
      <div className={styles.calendar}>
        <div className={styles.dayLineWrapper}>
          <div
            className={styles.dayLine}
            ref={scrollLineRef}
            style={{
              transform: `translateX(${-offset}px)`,
            }}
          >
            {daysOfYear.map((item, index) => {
              if (item.type === "year") {
                return (
                  <div
                    key={`year-${item.year}-${index}`}
                    className={styles.separator}
                  >
                    <p>{item.year}</p>
                  </div>
                );
              }

              if (item.type === "month") {
                return (
                  <div
                    key={`month-${item.name}-${index}`}
                    className={styles.separator}
                  >
                    <p>{item.name}</p>
                  </div>
                );
              }

              return (
                <div
                  id={`day-${item.timestamp}`}
                  key={`day-${item.timestamp}-${index}`}
                  className={`${styles.day} ${
                    selectedDay === item.timestamp ? styles.daySelected : ""
                  } ${
                    item.monthIndex === currentMonth && item.day === currentDay
                      ? styles.dayToday
                      : ""
                  }`}
                  onClick={() => handleDaySelection(item.timestamp)}
                >
                  <p className={styles.dayNumber}>
                    {item.day},{" "}
                    <span className={styles.weekDay}>
                      {getDayOfWeek(item.date)} 
                    </span>
                  </p>
                  <p className={styles.dayInfoSlot}>17 мест</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <button
        onClick={handleScrollRight}
        className={`${styles.monthPicker_scrollButton} ${styles.monthPicker_scrollButtons_next}`}
      >
        <img
          src="/images/main_page/arrow-right-month-picker.svg"
          alt="Вправо"
        />
      </button>
    </div>
  );
}
