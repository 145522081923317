"use client";

import "./Header.css";
import AuthorizationPopup from "../Authorization/AuthorizationPopup";
import { NavLink, useLocation } from "react-router-dom";
import { useState } from "react";
import { currentScreenWidth, mobileScreenBreakpoint } from "../../utils";
import GreyButton from "../ui/GreyButton/GreyButton";

export default function Header({
	additionalClass,
	sectionName,
	openModalMenu,
	setCurrentUserId,
	setCurrentUserToken,
}) {
	const [authOpen, setAuthOpen] = useState(false);

	const toggleAuthPopup = (state) => {
		setAuthOpen(state);
	};

	const location = useLocation();

	const getBack = () => {
		if (window.history.length > 1) {
			window.history.back();
		} else {
			window.history.location("/");
		}
	};

	return (
		<>
			{location.pathname !== "/" &&
			currentScreenWidth <= mobileScreenBreakpoint ? (
				<header className="header__mobile-content">
					<span className="header-back-button" onClick={() => getBack()}></span>
					<h1>{sectionName}</h1>
				</header>
			) : (
				<header className={`header ${additionalClass ? additionalClass : ""}`}>
					<img src="logo_source.png" alt="logo" />
					<div className="header__main-buttons">
						<GreyButton
							isActive={false}
							to={"/"}
							addClass={"header_grey-button"}>
							Главное
						</GreyButton>
						<GreyButton
							isActive={true}
							to={"/"}
							addClass={"header_grey-button"}>
							Сервисы
						</GreyButton>
					</div>
					<button className="header__add-announcement-button">
						Добавить объявление +
					</button>
					<nav className="header__nav-buttons">
						<button className="header__nav-button">
							<NavLink to="/chat">
								<div className="header__nav-button-image">
									<img src="/images/header/chat_pc.svg" alt="profile" />
								</div>
								<span>Входящие</span>
							</NavLink>
						</button>
						<button className="header__nav-button">
							<div className="header__nav-button-image">
								<img src="/images/header/journal.svg" alt="profile" />
							</div>

							<span>Журнал</span>
						</button>
						<button className="header__nav-button">
							<div className="header__nav-button-image">
								<img src="/images/header/desired.svg" alt="profile" />
							</div>

							<span>Желаемое</span>
						</button>
						<button className="header__nav-button">
							<div className="header__nav-button-image">
								<img src="/images/header/cart.svg" alt="profile" />
							</div>

							<span>Корзина</span>
						</button>
						<button className="header__nav-button">
							<div className="header__nav-button-image">
								<img src="/images/header/notifications.svg" alt="profile" />
							</div>

							<span>Уведомления</span>
						</button>
						<button
							onClick={() => toggleAuthPopup(true)}
							className="header__nav-button">
							<div className="header__nav-button-image">
								<img src="/images/header/login_logout.svg" alt="profile" />
							</div>

							<span>Войти</span>
						</button>
					</nav>
					<nav className="header__nav-buttons mobile">
						<img src="/images/header/cart_mobile.svg" alt="Корзина" />
						<img src="/images/header/desired_mobile.svg" alt="Избранное" />
						<img
							src="/images/header/login_logout_mobile.svg"
							alt="Войти"
							onClick={() => toggleAuthPopup(true)}
						/>
					</nav>
					<AuthorizationPopup
						isOpen={authOpen}
						toggle={toggleAuthPopup}
						setCurrentUserId={setCurrentUserId}
						setCurrentUserToken={setCurrentUserToken}
					/>
				</header>
			)}
		</>
	);
}
