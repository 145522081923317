import {SelectAge} from "./SelectAge";
import {PriceFilterItem} from "./PriceFilterItem";
import React from "react";

export const FiltersLine = ({
                                getActiveFiltersCount,
                                openFilterModal,
                                selectedAgeGroup,
                                setSelectedAgeGroup,
                                openAgeModal,
                                openModal,
                                category,
                                selectedCategory,
                                handleCategoryClick,
                                priceRange,
                                openPriceModal,
                                selectedCondition,
                                openConditionModal,
                                children
                            }) => (
    <div className="step step-4">
        <div className="filters">
			  <span
                  className={`filter-button ${getActiveFiltersCount() > 0 ? "active" : ""}`} onClick={openFilterModal}>
				              {getActiveFiltersCount() < 1 ? (
                                  <svg width="22" height="18" viewBox="0 0 22 18" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                      <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M0 14C0 13.4477 0.355455 13 0.793932 13H9.20607C9.64454 13 10 13.4477 10 14C10 14.5523 9.64454 15 9.20607 15H0.793932C0.355455 15 0 14.5523 0 14Z"
                                            fill="#4C4C4C"/>
                                      <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M12 4C12 3.44772 12.3555 3 12.7939 3H21.2061C21.6445 3 22 3.44772 22 4C22 4.55228 21.6445 5 21.2061 5H12.7939C12.3555 5 12 4.55228 12 4Z"
                                            fill="#4C4C4C"/>
                                      <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M0 4C0 1.7917 1.79041 0 4 0C6.20959 0 8 1.7917 8 4C8 6.2083 6.20959 8 4 8C1.79041 8 0 6.2083 0 4ZM4 1.60729C2.67998 1.60729 1.60838 2.6781 1.60838 4C1.60838 5.3219 2.67998 6.39271 4 6.39271C5.32002 6.39271 6.39162 5.3219 6.39162 4C6.39162 2.6781 5.32002 1.60729 4 1.60729Z"
                                            fill="#4C4C4C"/>
                                      <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M14 14C14 11.7915 15.7906 10 18.0011 10C20.211 10 22 11.7921 22 14C22 16.2079 20.211 18 18.0011 18C15.7906 18 14 16.2085 14 14ZM18.0011 11.6073C16.6798 11.6073 15.6084 12.6783 15.6084 14C15.6084 15.3217 16.6798 16.3927 18.0011 16.3927C19.3208 16.3927 20.3916 15.3223 20.3916 14C20.3916 12.6777 19.3208 11.6073 18.0011 11.6073Z"
                                            fill="#4C4C4C"/>
                                  </svg>

                              ) : (
                                  <svg width="22" height="18" viewBox="0 0 22 18" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                      <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M0 14C0 13.4477 0.355455 13 0.793932 13H9.20607C9.64454 13 10 13.4477 10 14C10 14.5523 9.64454 15 9.20607 15H0.793932C0.355455 15 0 14.5523 0 14Z"
                                            fill="white"/>
                                      <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M12 4C12 3.44772 12.3555 3 12.7939 3H21.2061C21.6445 3 22 3.44772 22 4C22 4.55228 21.6445 5 21.2061 5H12.7939C12.3555 5 12 4.55228 12 4Z"
                                            fill="white"/>
                                      <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M0 4C0 1.7917 1.79041 0 4 0C6.20959 0 8 1.7917 8 4C8 6.2083 6.20959 8 4 8C1.79041 8 0 6.2083 0 4ZM4 1.60729C2.67998 1.60729 1.60838 2.6781 1.60838 4C1.60838 5.3219 2.67998 6.39271 4 6.39271C5.32002 6.39271 6.39162 5.3219 6.39162 4C6.39162 2.6781 5.32002 1.60729 4 1.60729Z"
                                            fill="white"/>
                                      <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M14 14C14 11.7915 15.7906 10 18.0011 10C20.211 10 22 11.7921 22 14C22 16.2079 20.211 18 18.0011 18C15.7906 18 14 16.2085 14 14ZM18.0011 11.6073C16.6798 11.6073 15.6084 12.6783 15.6084 14C15.6084 15.3217 16.6798 16.3927 18.0011 16.3927C19.3208 16.3927 20.3916 15.3223 20.3916 14C20.3916 12.6777 19.3208 11.6073 18.0011 11.6073Z"
                                            fill="white"/>
                                  </svg>

                              )}

                  {getActiveFiltersCount() > 0 && <span className="filter-count">{getActiveFiltersCount()}</span>}
			  </span>

            <SelectAge openAgeModal={openAgeModal} selectedAgeGroup={selectedAgeGroup}
                       setSelectedAgeGroup={setSelectedAgeGroup}/>

            {category.map((cat) => (
                <div
                    key={cat}
                    className={`filter ${selectedCategory.includes(cat) ? "active" : ""}`}
                    onClick={() => handleCategoryClick(cat)}>
                    {cat}
                </div>
            ))}

            <PriceFilterItem priceRange={priceRange}/>
        </div>
    </div>
);