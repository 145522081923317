import React from "react";

export const ConditionModal = ({
                                   isOpen,
                                   onClose,
                                   conditions,
                                   selectedCondition,
                                   onSelectCondition,
                                   onClear,
                                   onApply,
                               }) => {
    if (!isOpen) return null;

    return (
        <div className="modal">
            <div className="modal-content">
                <div className="modal-header">
                    <button className="back-button" onClick={onClose}></button>
                    <h2>Состояние</h2>
                    <button className="clear-btn" onClick={onClear}>
                        Очистить
                    </button>
                </div>
                <div className="modal-body">
                    {conditions.map((condition) => (
                        <div key={condition} className="radio-group">
                            <input
                                type="radio"
                                id={condition}
                                name="condition"
                                value={condition}
                                checked={selectedCondition === condition}
                                onChange={(e) => onSelectCondition(e.target.value)}
                            />
                            <label htmlFor={condition}>{condition}</label>
                        </div>
                    ))}
                </div>
                <div className="modal-footer">
                    <button className="apply-btn" onClick={onApply}>
                        Применить фильтр
                    </button>
                </div>
            </div>
        </div>
    );
};