import React from "react";

export const Description = ({isExpanded, about, previewText, toggleExpand}) => {
    return (
        <div className="about__product">
            <h1 style={{marginBottom: 4}}>Описание</h1>

            {/* Показываем либо сокращенный текст, либо полный */}
            <p>{isExpanded ? about : `${previewText}...`}</p>

            {/* Ссылка для раскрытия текста */}
            <a onClick={toggleExpand} style={{cursor: 'pointer'}}>
                {isExpanded ? 'Свернуть' : 'Читать далее'}
            </a>
        </div>
    )
}