import styles from "./FilterPanelToggleButton.module.css";

export default function FilterPanelToggleButton({
	isActive,
	selectedFiltersAmount,
	addClass,
}) {
	return (
		<div
			className={`${styles.filter_panel_toggle_button} ${
				isActive ? styles.filter_panel_toggle_button_active : ""
			} ${addClass ? addClass : ""}`}>
			<img
				src={
					isActive
						? "/images/buttons/filter_toggler_light.png"
						: "/images/buttons/filter_toggler_dark.svg"
				}
				alt="Фильтр кнопка"
			/>
			{isActive && selectedFiltersAmount && selectedFiltersAmount > 0 && (
				<span>{selectedFiltersAmount}</span>
			)}
		</div>
	);
}
