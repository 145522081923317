// products.js
export const zapis = [
	{
		id: 1,
		name: "Энергетик",
		price: 1400,
		size: 50,
		maxSize: 79,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		data: "12.08.2005 - 06.10.2024",
		ageGroup: "Начальный (< 1 года)",
		condition: "Новое",
		images: ["./img.png", "./img.png", "./img.png", "./img.png"],
		category: "Хоккейная школа",
		indicator: "Онлайн-запись",
		floorGroup: "2 года",
		about: "adsad",
	},
	{
		id: 2,
		data: "12.08.2005 - 06.10.2024",
		name: "ЖК «Динамо»",
		size: 50,
		maxSize: 79,
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Любое",
		images: ["./img.png", "./img.png"],
		category: "Хоккейная школа",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 3,
		data: "12.08.2005 - 06.10.2024",
		name: "Энергетик",
		price: 1300,
		size: 50,
		maxSize: 79,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 4,
		data: "12.08.2005 - 06.10.2024",
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Любое",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 5,
		data: "12.08.2005 - 06.10.2024",
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		category: "Не выбрано",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 6,
		data: "12.08.2005 - 06.10.2024",
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Любое",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 7,
		data: "12.08.2005 - 06.10.2024",
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
	},
	{
		id: 8,
		data: "12.08.2005 - 06.10.2024",
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 9,
		data: "12.08.2005 - 06.10.2024",
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		category: "Не выбрано",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 10,
		data: "12.08.2005 - 06.10.2024",
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 11,
		data: "12.08.2005 - 06.10.2024",
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 12,
		data: "12.08.2005 - 06.10.2024",
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 13,
		data: "12.08.2005 - 06.10.2024",
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		size: 50,
		maxSize: 79,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 14,
		data: "12.08.2005 - 06.10.2024",
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		category: "Онлайн-запись",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
];

export const strax = [
	{
		id: 1,
		name: "Энергетик",
		price: 1400,
		size: 50,
		maxSize: 79,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		data: "12.08.2005 - 06.10.2024",
		ageGroup: "Начальный (< 1 года)",
		condition: "Новое",
		images: ["./img.png", "./img.png", "./img.png", "./img.png"],
		category: "Хоккейная школа",
		indicator: "Онлайн-запись",
		floorGroup: "2 года",
		about: "adsad",
	},
	{
		id: 2,
		name: "ЖК «Динамо»",
		size: 50,
		maxSize: 79,
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Любое",
		images: ["./img.png", "./img.png"],
		category: "Хоккейная школа",
		indicator: "Онлайн-запись",
		about: "",
	},
	{
		id: 3,
		name: "Энергетик",
		price: 1300,
		size: 50,
		maxSize: 79,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "",
	},
	{
		id: 4,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Любое",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "",
	},
	{
		id: 5,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		category: "Не выбрано",
		indicator: "Онлайн-запись",
		about: "",
	},
	{
		id: 6,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Любое",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "",
	},
	{
		id: 7,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "",
	},
	{
		id: 8,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "",
	},
	{
		id: 9,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		category: "Не выбрано",
		indicator: "Онлайн-запись",
		about: "",
	},
	{
		id: 10,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "",
	},
	{
		id: 11,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "",
	},
	{
		id: 12,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "",
	},
	{
		id: 13,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		size: 50,
		maxSize: 79,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "",
	},
	{
		id: 14,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		category: "Онлайн-запись",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "",
	},
];

export const blog = [
	{
		id: 1,
		name: "Энергетик",
		price: 1400,
		size: 50,
		maxSize: 79,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		data: "12.08.2005 - 06.10.2024",
		ageGroup: "Начальный (< 1 года)",
		condition: "Новое",
		images: ["./img.png", "./img.png", "./img.png", "./img.png"],
		category: "Хоккейная школа",
		indicator: "Онлайн-запись",
		floorGroup: "2 года",
		sub: "300",
		about: "adsad",
	},
	{
		id: 2,
		name: "Энергетик",
		price: 1400,
		size: 50,
		maxSize: 79,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		data: "12.08.2005 - 06.10.2024",
		ageGroup: "Начальный (< 1 года)",
		condition: "Новое",
		images: ["./img.png", "./img.png", "./img.png", "./img.png"],
		category: "Хоккейная школа",
		indicator: "Онлайн-запись",
		floorGroup: "2 года",
		sub: "300",
		about: "adsad",
	},
	{
		id: 3,
		name: "Энергетик",
		price: 1400,
		size: 50,
		maxSize: 79,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		data: "12.08.2005 - 06.10.2024",
		ageGroup: "Начальный (< 1 года)",
		condition: "Новое",
		images: ["./img.png", "./img.png", "./img.png", "./img.png"],
		category: "Хоккейная школа",
		indicator: "Онлайн-запись",
		floorGroup: "2 года",
		sub: "300",
		about: "adsad",
	},
	{
		id: 4,
		name: "Энергетик",
		price: 1400,
		size: 50,
		maxSize: 79,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		data: "12.08.2005 - 06.10.2024",
		ageGroup: "Начальный (< 1 года)",
		condition: "Новое",
		images: ["./img.png", "./img.png", "./img.png", "./img.png"],
		category: "Хоккейная школа",
		indicator: "Онлайн-запись",
		floorGroup: "2 года",
		sub: "300",
		about: "adsad",
	},
	{
		id: 5,
		name: "Энергетик",
		price: 1400,
		size: 50,
		maxSize: 79,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		data: "12.08.2005 - 06.10.2024",
		ageGroup: "Начальный (< 1 года)",
		condition: "Новое",
		images: ["./img.png", "./img.png", "./img.png", "./img.png"],
		category: "Хоккейная школа",
		indicator: "Онлайн-запись",
		floorGroup: "2 года",
		sub: "300",
		about: "adsad",
	},
	{
		id: 6,
		name: "Энергетик",
		price: 1400,
		size: 50,
		maxSize: 79,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		data: "12.08.2005 - 06.10.2024",
		ageGroup: "Начальный (< 1 года)",
		condition: "Новое",
		images: ["./img.png", "./img.png", "./img.png", "./img.png"],
		category: "Хоккейная школа",
		indicator: "Онлайн-запись",
		floorGroup: "2 года",
		sub: "300",
		about: "adsad",
	},
];

export const vratari = [
	{
		id: 1,
		name: "Энергетик",
		price: 1400,
		size: 50,
		maxSize: 79,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Начальный (< 1 года)",
		condition: "Новое",
		images: ["./img.png", "./img.png", "./img.png", "./img.png"],
		category: "Хоккейная школа",
		indicator: "Онлайн-запись",
		floorGroup: "2 года",
		about: "adsad",
	},
	{
		id: 2,
		name: "ЖК «Динамо»",
		size: 50,
		maxSize: 79,
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Любое",
		images: ["./img.png", "./img.png"],
		category: "Хоккейная школа",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 3,
		name: "Энергетик",
		price: 1300,
		size: 50,
		maxSize: 79,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 4,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Любое",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 5,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		category: "Не выбрано",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 6,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Любое",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 7,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 8,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 9,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		category: "Не выбрано",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 10,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 11,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 12,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 13,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		size: 50,
		maxSize: 79,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 14,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		category: "Онлайн-запись",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
];

export const sudyi = [
	{
		id: 1,
		name: "Энергетикsss",
		price: 1400,
		size: 50,
		maxSize: 79,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 5.0,
		city: "Москва",
		ageGroup: "Любитель",
		condition: "Новое",
		images: ["./img.png", "./img.png", "./img.png", "./img.png"],
		category: "Хоккейная школа",
		indicator: "Онлайн-запись",
		floorGroup: "2 года",
		about: "adsad",
	},
	{
		id: 2,
		name: "ЖК «Динамо»",
		size: 50,
		maxSize: 79,
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Любое",
		images: ["./img.png", "./img.png"],
		category: "Хоккейная школа",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 3,
		name: "Энергетик",
		price: 1300,
		size: 50,
		maxSize: 79,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 3.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 4,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Любое",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 5,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 2.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		category: "Не выбрано",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 6,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Любое",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 7,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 2.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 8,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 9,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 2.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		category: "Не выбрано",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 10,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 11,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 2.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 12,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 13,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		size: 50,
		maxSize: 79,
		location: "Новостаповская 5 ст2",
		rating: 2.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 14,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		category: "Онлайн-запись",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
];

export const arenda = [
	{
		id: 1,
		name: "Энергетик",
		price: 1400,
		size: 50,
		maxSize: 79,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Начальный (< 1 года)",
		condition: "Новое",
		images: ["./img.png", "./img.png", "./img.png", "./img.png"],
		category: "Хоккейная школа",
		indicator: "Онлайн-запись",
		floorGroup: "2 года",
		about: "adsad",
	},
	{
		id: 2,
		name: "ЖК «Динамо»",
		size: 50,
		maxSize: 79,
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Любое",
		images: ["./img.png", "./img.png"],
		category: "Хоккейная школа",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 3,
		name: "Энергетик",
		price: 1300,
		size: 50,
		maxSize: 79,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 4,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Любое",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 5,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		category: "Не выбрано",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 6,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Любое",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 7,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 8,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 9,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		category: "Не выбрано",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 10,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 11,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 12,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 13,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		size: 50,
		maxSize: 79,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 14,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		category: "Онлайн-запись",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
];

export const trener = [
	{
		id: 1,
		name: "Энергетиsк",
		price: 1400,
		size: 50,
		maxSize: 79,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Начальный (< 1 года)",
		condition: "Новое",
		images: ["./../img.png", "./img.png", "./img.png", "./img.png"],
		category: "Хоккейная школа",
		indicator: "Онлайн-запись",
		floorGroup: "2 года",
		about: "",
	},
	{
		id: 2,
		name: "ЖК «Динамо»",
		size: 50,
		maxSize: 79,
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Любое",
		images: ["./img.png", "./img.png"],
		category: "Хоккейная школа",
		indicator: "Онлайн-запись",
		about: "sadsad",
	},
	{
		id: 3,
		name: "Энергетик",
		price: 1300,
		size: 50,
		maxSize: 79,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "sadsad",
	},
	{
		id: 4,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Любое",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "sadsad",
	},
	{
		id: 5,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		category: "Не выбрано",
		indicator: "Онлайн-запись",
		about: "sadsad",
	},
	{
		id: 6,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Любое",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "sadsad",
	},
	{
		id: 7,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "sadsad",
	},
	{
		id: 8,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "sadsad",
	},
	{
		id: 9,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		category: "Не выбрано",
		indicator: "Онлайн-запись",
		about: "sadsad",
	},
	{
		id: 10,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "sadsad",
	},
	{
		id: 11,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "sadsad",
	},
	{
		id: 12,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "sadsad",
	},
	{
		id: 13,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		size: 50,
		maxSize: 79,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "sadsad",
	},
	{
		id: 14,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		category: "Онлайн-запись",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "sadsad",
	},
];

export const bron = [
	{
		id: 1,
		name: "Энергетик",
		price: 1400,
		size: 50,
		maxSize: 79,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Начальный (< 1 года)",
		condition: "Новое",
		images: ["./img.png", "./img.png", "./img.png", "./img.png"],
		category: "Хоккейная школа",
		indicator: "Онлайн-запись",
		floorGroup: "2 года",
	},
	{
		id: 2,
		name: "ЖК «Динамо»",
		size: 50,
		maxSize: 79,
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Любое",
		images: ["./img.png", "./img.png"],
		category: "Хоккейная школа",
		indicator: "Онлайн-запись",
	},
	{
		id: 3,
		name: "Энергетик",
		price: 1300,
		size: 50,
		maxSize: 79,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
	},
	{
		id: 4,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Любое",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
	},
	{
		id: 5,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		category: "Не выбрано",
		indicator: "Онлайн-запись",
	},
	{
		id: 6,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Любое",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
	},
	{
		id: 7,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
	},
	{
		id: 8,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
	},
	{
		id: 9,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		category: "Не выбрано",
		indicator: "Онлайн-запись",
	},
	{
		id: 10,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
	},
	{
		id: 11,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
	},
	{
		id: 12,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
	},
	{
		id: 13,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		size: 50,
		maxSize: 79,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
	},
	{
		id: 14,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		category: "Онлайн-запись",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
	},
];

export const remont = [
	{
		id: 1,
		name: "Энергетик",
		price: 1400,
		size: 50,
		maxSize: 79,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Начальный (< 1 года)",
		condition: "Новое",
		images: ["./img.png", "./img.png", "./img.png", "./img.png"],
		category: "Хоккейная школа",
		indicator: "Онлайн-запись",
		floorGroup: "2 года",
		about: "adsad",
	},
	{
		id: 2,
		name: "ЖК «Динамо»",
		size: 50,
		maxSize: 79,
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Любое",
		images: ["./img.png", "./img.png"],
		category: "Хоккейная школа",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 3,
		name: "Энергетик",
		price: 1300,
		size: 50,
		maxSize: 79,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 4,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Любое",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 5,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		category: "Не выбрано",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 6,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Любое",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 7,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 8,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 9,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		category: "Не выбрано",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 10,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 11,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 12,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 13,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		size: 50,
		maxSize: 79,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 14,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		category: "Онлайн-запись",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
];

export const poshiv = [
	{
		id: 1,
		name: "Энергетик",
		price: 1400,
		size: 50,
		maxSize: 79,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Начальный (< 1 года)",
		condition: "Новое",
		images: ["./img.png", "./img.png", "./img.png", "./img.png"],
		category: "Хоккейная школа",
		indicator: "Онлайн-запись",
		floorGroup: "2 года",
		about: "adsad",
	},
	{
		id: 2,
		name: "ЖК «Динамо»",
		size: 50,
		maxSize: 79,
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Любое",
		images: ["./img.png", "./img.png"],
		category: "Хоккейная школа",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 3,
		name: "Энергетик",
		price: 1300,
		size: 50,
		maxSize: 79,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 4,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Любое",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 5,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		category: "Не выбрано",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 6,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Любое",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 7,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 8,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 9,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		category: "Не выбрано",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 10,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 11,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 12,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 13,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		size: 50,
		maxSize: 79,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 14,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		category: "Онлайн-запись",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
];

export const xim = [
	{
		id: 1,
		name: "Энергетик",
		price: 1400,
		size: 50,
		maxSize: 79,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Начальный (< 1 года)",
		condition: "Новое",
		images: ["./img.png", "./img.png", "./img.png", "./img.png"],
		category: "Хоккейная школа",
		indicator: "Онлайн-запись",
		floorGroup: "2 года",
		about: "adsad",
	},
	{
		id: 2,
		name: "ЖК «Динамо»",
		size: 50,
		maxSize: 79,
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Любое",
		images: ["./img.png", "./img.png"],
		category: "Хоккейная школа",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 3,
		name: "Энергетик",
		price: 1300,
		size: 50,
		maxSize: 79,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 4,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Любое",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 5,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		category: "Не выбрано",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 6,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Любое",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 7,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 8,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 9,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		category: "Не выбрано",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 10,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 11,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 12,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 13,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		size: 50,
		maxSize: 79,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 14,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		category: "Онлайн-запись",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
];

export const mainpg = [
	{
		id: 1,
		name: "Энергетик",
		price: 1400,
		size: 50,
		maxSize: 79,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Начальный (< 1 года)",
		condition: "Новое",
		images: ["./img.png", "./img.png", "./img.png", "./img.png"],
		category: "Хоккейная школа",
		indicator: "Онлайн-запись",
		floorGroup: "2 года",
		about: "adsad",
	},
	{
		id: 2,
		name: "ЖК «Динамо»",
		size: 50,
		maxSize: 79,
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Любое",
		images: ["./img.png", "./img.png"],
		category: "Хоккейная школа",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 3,
		name: "Энергетик",
		price: 1300,
		size: 50,
		maxSize: 79,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 4,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Любое",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 5,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		category: "Не выбрано",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 6,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Любое",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 7,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 8,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		images: ["./img.png", "./img.png"],
		category: "Онлайн-запись",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 9,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		category: "Не выбрано",
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 10,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 11,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 12,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 13,
		name: "Энергетик",
		price: 1300,
		oldPrice: 1500,
		size: 50,
		maxSize: 79,
		location: "Новостаповская 5 ст2",
		rating: 0.0,
		city: "Москва",
		ageGroup: "Взрослые (>18 лет)",
		condition: "Новое",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
	{
		id: 14,
		name: "ЖК «Динамо»",
		price: 1400,
		oldPrice: 1600,
		location: "Новостаповская 5 ст2",
		rating: 4.5,
		city: "Санкт-Петербург",
		ageGroup: "Подростки (15-17 лет)",
		condition: "Б/У",
		category: "Онлайн-запись",
		images: ["./img.png", "./img.png"],
		indicator: "Онлайн-запись",
		about: "adsad",
	},
];