import React from "react";

export const ViewMode = ({openSortModal, sortType, openModalOtobr, viewMode}) => {
    const renderIcon = () => {
        switch (viewMode) {
            case "grid":
                return (
                    <svg width="14" height="14" style={{marginRight: 8}} viewBox="0 0 16 16" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M5.66668 1H2.00001C1.44773 1 1.00001 1.44772 1.00001 2L1 5.66667C1 6.21895 1.44771 6.66667 2 6.66667H5.66666C6.21895 6.66667 6.66666 6.21895 6.66666 5.66667L6.66668 2C6.66668 1.44772 6.21896 1 5.66668 1Z"
                            stroke="#3C3C3C" stroke-width="1.5"/>
                        <path
                            d="M14 1H10.3333C9.78104 1 9.33332 1.44772 9.33332 2V5.66667C9.33332 6.21895 9.78104 6.66667 10.3333 6.66667H14C14.5523 6.66667 15 6.21895 15 5.66667V2C15 1.44772 14.5523 1 14 1Z"
                            stroke="#3C3C3C" stroke-width="1.5"/>
                        <path
                            d="M14 9.33333H10.3333C9.78104 9.33333 9.33332 9.78105 9.33332 10.3333V14C9.33332 14.5523 9.78104 15 10.3333 15H14C14.5523 15 15 14.5523 15 14V10.3333C15 9.78105 14.5523 9.33333 14 9.33333Z"
                            stroke="#3C3C3C" stroke-width="1.5"/>
                        <path
                            d="M5.66666 9.33333H2.00001C1.44773 9.33333 1.00001 9.78105 1.00001 10.3333V14C1.00001 14.5523 1.44773 15 2.00001 15H5.66666C6.21895 15 6.66666 14.5523 6.66666 14V10.3333C6.66666 9.78105 6.21895 9.33333 5.66666 9.33333Z"
                            stroke="#3C3C3C" stroke-width="1.5"/>
                    </svg>
                );
            case "solo":
                return (
                    <svg width="14" height="14" style={{marginRight: 8}} viewBox="0 0 18 18" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <rect x="1.25" y="1.25" width="15.5" height="15.5" rx="2.75" stroke="#3C3C3C"
                              stroke-width="1.5"/>
                    </svg>


                );
            case "list":
                return (
                    <svg width="14" height="14" style={{marginRight: 8}} viewBox="0 0 15 16" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.22222 3.33333H15M7.22222 12.6667H15M2.18008 1H4.54023C5.19197 1 5.72031 1.52233 5.72031 2.16667V4.5C5.72031 5.14433 5.19197 5.66667 4.54023 5.66667H2.18008C1.52834 5.66667 1 5.14433 1 4.5V2.16667C1 1.52233 1.52834 1 2.18008 1ZM2.18008 10.3333H4.54023C5.19197 10.3333 5.72031 10.8557 5.72031 11.5V13.8333C5.72031 14.4777 5.19197 15 4.54023 15H2.18008C1.52834 15 1 14.4777 1 13.8333V11.5C1 10.8557 1.52834 10.3333 2.18008 10.3333Z"
                            stroke="#3C3C3C" stroke-width="1.5"/>
                    </svg>

                );
            default:
                return null;
        }
    };

    const sortOptions = {
        "price-asc": "Сначала дешевле",
        "price-desc": "Сначала дороже",
        date: "По дате",
        rating: "По рейтингу",
    };

    return (
        <div className="step step-5">
            <div className="custom-select">
                <button className="sort-button" onClick={openSortModal}>
                    <img src="/images/icons/SortBtn.svg" alt=""
                         style={{width: 11, height: 17, objectFit: "cover", overflow: "visible"}}/>
                    {sortOptions[sortType] || "Сортировка"}
                </button>
            </div>
            <button className="notify-button" onClick={openModalOtobr}>

                {renderIcon()}
                Формат отображения
            </button>
        </div>
    )
}