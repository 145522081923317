import React from "react";

export const Grafik = () => {
    return (
        <div className="about__product">
            <h1 style={{marginBottom: 4}}>Режим работы</h1>

            <span>Режим работы <p>1</p></span>
        </div>
    )
}