import { NavLink } from "react-router-dom";
import styles from "./GreyButton.module.css";

export default function GreyButton({ children, isActive, addClass, to }) {
	return (
		<>
			{to ? (
				<NavLink
                to={to}
					className={`${styles.grey_button} ${isActive ? styles.grey_button_active : ""} ${addClass ? addClass : ""}`}>
					{children}
				</NavLink>
			) : (
				<button
					className={`${styles.grey_button} ${isActive ? styles.grey_button_active : ""} ${addClass ? addClass : ""}`}>
					{children}
				</button>
			)}
		</>
	);
}
