import styles from "./FilterButton.module.css";

export default function FilterButton({ text, isActive, addClass }) {
	return (
		<div className={`${styles.filter_button} ${isActive ? styles.filter_button_active : ""} ${addClass ? addClass : ""}`}>
			<p>{text}</p>
			<img src={isActive ? "/images/buttons/filter_button-caret-light.png" : "/images/buttons/filter_button-caret-dark.png"} alt="Каретка" />
		</div>
	);
}
