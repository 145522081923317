import Slider from "react-slick";
import FunctionalButton from "../ui/FunctionalButton/FunctionalButton";
import { mobileScreenBreakpoint } from "../../utils";

export default function MainPageBenefitsSlider() {
	const settings = {
		arrows: true,
		dots: true,
		infinite: true,
		autoplay: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: mobileScreenBreakpoint,
				settings: {
					arrows: false,
				},
			},
		],
	};

	return (
		<Slider {...settings} className="main_page-product-benefits">
			<div className="main_page-product-benefits-slide hokey">
				<div className="main_page-product-benefits-slide-text">
					<h2>Заниматься хоккеем с нами - удобно!</h2>
					<p>
						Расписание тренировок, нужный инвентарь и любимые турниры всегда под
						рукой
					</p>
				</div>
				<div className="main_page-product-benefits-slide-image">
					<img
						className="hockey_stick-image"
						src="images/main_page/hockey_stick.png"
						alt="Клюшка"
					/>
					<img
						className="hockey_puck-image"
						src="images/main_page/hockey_puck.png"
						alt="Шайба"
					/>
				</div>
			</div>
			<div className="main_page-product-benefits-slide get-discount">
				<div className="main_page-product-benefits-slide-text">
					<h2>Поможем получить скидку на любой товар</h2>
					<FunctionalButton addClass={"get-discount-button"}>
						Получить скидку
					</FunctionalButton>
				</div>
				<div className="main_page-product-benefits-slide-image">
					<img
						className="hockey_stick-image"
						src="images/main_page/discounts_small.png"
						alt="Кубики"
					/>
				</div>
			</div>
			<div className="main_page-product-benefits-slide tournaments">
				<div className="main_page-product-benefits-slide-text">
					<h2>
						Занимайтесь хоккеем, бронируйте тренировки и побеждайте в турнирах
					</h2>
				</div>
				<div className="main_page-product-benefits-slide-image">
					<img
						className="hockey_stick-image"
						src="images/main_page/medal.png"
						alt="Медаль"
					/>
				</div>
			</div>
			<div className="main_page-product-benefits-slide discounts">
				<div className="main_page-product-benefits-slide-text">
					<h2>Записывайтесь на тренировки со скидкой</h2>
				</div>
				<div className="main_page-product-benefits-slide-image">
					<img
						className="hockey_stick-image"
						src="images/main_page/gift.png"
						alt="Подарок"
					/>
				</div>
			</div>
			<div className="main_page-product-benefits-slide rent">
				<div className="main_page-product-benefits-slide-text">
					<h2>Бронируйте ледовые площадки и залы</h2>
				</div>
				<div className="main_page-product-benefits-slide-image">
					<img
						className="hockey_stick-image"
						src="images/main_page/calendar.png"
						alt="Календарь"
					/>
				</div>
			</div>
			<div className="main_page-product-benefits-slide buy-stuff">
				<div className="main_page-product-benefits-slide-text">
					<h2>Покупайте спортивную форму и инвентарь</h2>
				</div>
				<div className="main_page-product-benefits-slide-image">
					<img
						className="hockey_stick-image"
						src="images/main_page/cart.png"
						alt="Корзина"
					/>
				</div>
			</div>
			<div className="main_page-product-benefits-slide coaches">
				<div className="main_page-product-benefits-slide-text">
					<h2>Выбирайте лучших тренеров</h2>
				</div>
				<div className="main_page-product-benefits-slide-image">
					<img
						className="arrow"
						src="images/main_page/arrow.png"
						alt="Клюшка"
					/>
					<img
						className="trophy"
						src="images/main_page/trophy.png"
						alt="Шайба"
					/>
				</div>
			</div>
			<div className="main_page-product-benefits-slide get-better">
				<div className="main_page-product-benefits-slide-text">
					<h2>Помогите нам стать лучше</h2>
					<div className="footer__links-container">
						<a href="">
							<img
							className="whatsapp"
								src="/images/footer/whatsapp_support.svg"
								alt="Whatsapp чат"
							/>
						</a>
						<a href="">
							<img
								src="/images/footer/telegram_support.svg"
								alt="Telegram чат"
							/>
						</a>
						<a href="">
							<img src="/images/footer/chat_support.svg" alt="Чат" />
						</a>
					</div>
				</div>
				<div className="main_page-product-benefits-slide-image">
					<img
						className="arrow"
						src="images/main_page/heart.png"
						alt="Сердечко"
					/>
				</div>
			</div>
		</Slider>
	);
}
