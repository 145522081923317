import React from "react";

export const OneBtnModal = ({isOpen, children, onClose, onClear}) => {
    if (!isOpen) return null;
    const handleBackdropClick = (e) => {
        if (e.target.classList.contains("modal")) {
            onClose();
        }
    };

    return (
        <div className="modal" onClick={handleBackdropClick}>
            <div className="modal-content">
                {children}

                <button className="apply-btn" style={{borderRadius: 99, marginBottom: 0}} onClick={onClose}>
                    Закрыть
                </button>
            </div>
        </div>
    );
};