export default function MainPageServices() {
	return (
		<div className="main-page__services">
			<h1 className="main-page__main-content-wrapper-heading">
				Основные сервисы
			</h1>
			<div className="main-page__services-card-container">
				<div className="main-page__services-cards-top">
					<div className="main-page__service training">
						<p>Забронировать тренировку</p>
						<img
							className="main-page__service-image"
							src="/images/main_page/hokey_man.png"
							alt="Хоккей"
						/>
					</div>
					<div className="main-page__service rent">
						<p>Арендовать лед и зал</p>
						<img
							className="main-page__service-image"
							src="/images/main_page/gym.png"
							alt="Хоккей"
						/>
						<img
							className="main-page__service-additional-image"
							src="/images/main_page/arena.png"
							alt="Хоккей"
						/>
					</div>
				</div>
				<div className="main-page__services-cards-bottom">
					<div className="main-page__service tournament">
						<p>Записаться на турнир</p>
						<img
							className="main-page__service-image"
							src="/images/main_page/hokey_winners.png"
							alt="Хоккей"
						/>
					</div>
					<div className="main-page__service equipment">
						<p>Купить форму и инвентарь</p>
						<img
							className="main-page__service-image"
							src="/images/main_page/hokey_equipment.png"
							alt="Хоккей"
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
