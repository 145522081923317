import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {Switch} from "./Switch";
import {SelectFilter} from "./SelectFilter";
import {SearchFilter} from "./SearchFilter";
import {CityFilter} from "./CityFilter";

export const FilterMenu = () => {
    const SwitchName = ("Название фильтра 1");
    const SelectFilterName1 = ("Вратарь");
    const cities = [
        "Москва",
        "Санкт-Петербург",
        "Новосибирск",
        "Екатеринбург",
        "Казань",
    ];
    const ageGroups = [
        "Взрослые (>18 лет)",
        "Подростки (15-17 лет)",
        "Подростки (12-14 лет)",
        "Дети (12-14 лет)",
        "Дети (8-12 лет)",
        "Дети (5-7 лет)",
    ];
    const handleCitySelect = (city) => {
        setSelectedCity(city);
        setCityDropdownOpen(false);
    };
    const [selectedCity, setSelectedCity] = useState("Москва");
    const [cityDropdownOpen, setCityDropdownOpen] = useState(false);
    const [priceRange, setPriceRange] = useState({min: "", max: ""});
    const {id} = useParams();
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const [selectedDay, setSelectedDay] = useState(null);
    const [selectedTimes, setSelectedTimes] = useState([]);
    const [today, setToday] = useState({
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
    });
    const months = [
        {month: 'Январь', days: 31},
        {month: 'Февраль', days: 28},
        {month: 'Март', days: 31},
        {month: 'Апрель', days: 30},
        {month: 'Май', days: 31},
        {month: 'Июнь', days: 30},
        {month: 'Июль', days: 31},
        {month: 'Август', days: 31},
        {month: 'Сентябрь', days: 30},
        {month: 'Октябрь', days: 30},
        {month: 'Ноябрь', days: 30},
        {month: 'Декабрь', days: 31},
    ];
    const timeOptions = {
        1: [{time: '12:00', price: 25000}, {time: '14:00', price: 30000}],
        2: [{time: '10:00', price: 20000}, {time: '16:00', price: 27000}],
    };
    const price = useState("2000")
    const toggleTimeSelection = (time, price) => {
        if (selectedTimes.includes(time)) {

            setSelectedTimes(selectedTimes.filter(t => t !== time));
            setTotalPrice(prevPrice => prevPrice - price);
        } else {

            setSelectedTimes([...selectedTimes, time]);
            setTotalPrice(prevPrice => prevPrice + price);
        }
    };
    const weekDays = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
    const getDayOfWeek = (year, month, day) => {
        const date = new Date(year, month, day);
        return weekDays[date.getDay()];
    };
    const getFirstDayOfWeek = (year, month) => {
        const firstDay = new Date(year, month, 1);
        return firstDay.getDay();
    };
    const rotateWeekDays = (offset) => {
        const rotatedDays = [...weekDays];
        for (let i = 0; i < offset; i++) {
            rotatedDays.push(rotatedDays.shift());
        }
        return rotatedDays;
    };
    const selectMonth = (index) => {
        setSelectedMonth(index);
        setExpanded(false);
        setSelectedDay(null);
    };
    const selectDay = (day) => {
        setSelectedDay(day);
    };
    const toggleExpand3 = () => {
        setExpanded(!expanded);
    };
    const [totalPrice, setTotalPrice] = useState(0);
    const [isOpen2, setIsOpen2] = useState(false);
    const [selectedTab, setSelectedTab] = useState('All');
    const toggleOpen2 = () => {
        setIsOpen2(!isOpen2);
    };

    return (
        <div className="filterMenu">
            <div className="filterMenu__head">
                <h1>Выбранный фильтры</h1>
                <div className="clear">Очистить</div>
            </div>

            <SelectFilter SelectFilterName1={SelectFilterName1}/>

            <SearchFilter/>


            <main className="filterMenuMain">
                <CityFilter setSelectedCity={setSelectedCity} selectedCity={selectedCity} cities={cities}/>

                <div className="filter-group"
                     style={{display: "flex", flexDirection: "column", gap: 16, marginTop: 8,}}>
                    <label style={{fontWeight: 700}}>Стоимость, ₽</label>
                    <div className="price-range">
                        <input
                            type="number"
                            value={priceRange.min}
                            onChange={(e) =>
                                setPriceRange({...priceRange, min: e.target.value})
                            }
                            placeholder="От 0"
                        />
                        <input
                            type="number"
                            value={priceRange.max}
                            onChange={(e) =>
                                setPriceRange({...priceRange, max: e.target.value})
                            }
                            placeholder="До 45000"
                        />
                    </div>
                    <div className="price-slider-container">
                        <input
                            type="range"
                            min="0"
                            max="22500"
                            // value={priceRange.min}
                            className="price-slider"
                        />
                        <input
                            type="range"
                            min="22500"
                            max="45000"
                            // value={priceRange.max}
                            className="price-slider"
                        />
                    </div>

                    <div className="filter-item">
                        <Switch SwitchName={SwitchName}/>
                    </div>

                    <button
                        className="apply-btn"
                        style={{marginTop: 16}}>
                        Применить фильтр
                    </button>

                    <div className="calendar">
                        <div onClick={toggleOpen2}>
                            <h1>Дата и время</h1>
                            <p></p>
                        </div>

                        <div className={`mxw  ${isOpen2 ? 'open' : ''}`}>
                            <div className="months">
                                {months.map((m, index) => (
                                    <button
                                        key={index}
                                        className={`month-button ${selectedMonth === index ? 'active' : ''}`}
                                        onClick={() => selectMonth(index)}
                                    >
                                        {m.month}
                                    </button>
                                ))}
                            </div>

                            {selectedMonth !== null && (
                                <div className={`days ${expanded ? 'days-active' : ''}`}>
                                    {expanded && (
                                        <div className="year-display">
                                            <h2>{months[selectedMonth].month} 2024</h2> {/* Добавляем строку с годом */}
                                        </div>
                                    )}

                                    <div className="days-grid-cont">


                                        <div className={`days-grid ${expanded ? 'grid-active' : ''}`}>
                                            {rotateWeekDays(getFirstDayOfWeek(2024, selectedMonth)).map((day, index) => (
                                                <div key={index} className="week-day">
                                                    {day}
                                                </div>
                                            ))}
                                            {selectedMonth !== null && selectedMonth > 0 && months[selectedMonth] && (
                                                Array.from({length: getFirstDayOfWeek(2024, selectedMonth)}).map((_, index) => {
                                                    const prevMonthIndex = selectedMonth === 0 ? 11 : selectedMonth - 1; // Декабрь для января
                                                    const prevMonthDays = months[prevMonthIndex]?.days || 0; // Количество дней в предыдущем месяце
                                                    const day = prevMonthDays - getFirstDayOfWeek(2024, selectedMonth) + index + 1;
                                                    return (
                                                        <div key={`prev-${index}`} className="day gray-out">
                                                            <div>{day}</div>
                                                            {/* Предыдущие числа серым */}
                                                        </div>
                                                    );
                                                })
                                            )}


                                            {selectedMonth !== null && months[selectedMonth]?.days && (
                                                Array.from({length: months[selectedMonth].days}, (_, i) => i + 1).map(day => (
                                                    <div
                                                        key={day}
                                                        className={`day ${selectedDay === day ? 'selected-day' : ''} ${day === today.day && selectedMonth === today.month && today.year === 2024 ? '' : ''}`}
                                                        onClick={() => selectDay(day)}>
                                                        <div>{day}<p>, {getDayOfWeek(2024, selectedMonth, day)}</p>
                                                        </div>
                                                        <div className="priced">{price} р.</div>

                                                        {day === today.day && selectedMonth === today.month && today.year === 2024 && (
                                                            <div className="point"></div>
                                                        )}
                                                    </div>
                                                )).slice(0, expanded ? months[selectedMonth].days : 7)
                                            )}
                                            {!expanded && (
                                                <p className="next-month-heading">{months[selectedMonth + 1]?.month.slice(0, months[selectedMonth + 1]?.month === 'June' || months[selectedMonth + 1]?.month === 'July' ? 4 : 3)}</p>
                                            )}

                                            {selectedMonth !== null && months[selectedMonth]?.days && (
                                                Array.from({length: 42 - (getFirstDayOfWeek(2024, selectedMonth) + months[selectedMonth].days)}).map((_, index) => (
                                                    <div key={`next-${index}`} className="day gray-out">
                                                        <div>{index + 1}</div>
                                                        {/* Следующие числа серым */}
                                                    </div>
                                                )).slice(0, expanded ? undefined : 7)
                                            )}
                                        </div>

                                        <button className="expand-button" onClick={toggleExpand3}>
                                            {expanded ? <div className="arrow-up"></div> :
                                                <div className="arrow-down"></div>}
                                        </button>
                                    </div>
                                </div>
                            )}

                            {selectedDay && (
                                <div className="time-options">
                                    {timeOptions[selectedDay]?.length > 0 ? (
                                        timeOptions[selectedDay].map((option, index) => (
                                            <div
                                                key={index}
                                                className={`time-option ${selectedTimes.includes(option.time) ? 'selected-time' : ''}`}
                                                onClick={() => toggleTimeSelection(option.time, option.price)}
                                            >
                                                {option.time} · {option.price}р.
                                            </div>
                                        ))
                                    ) : (
                                        <div className="iskl">Пока предложений нету</div>
                                    )}
                                </div>
                            )}

                            {selectedTimes.length > 0 && (
                                <button className="book-button">
                                    Забронировать · {totalPrice} р
                                </button>
                            )}
                        </div>

                    </div>
                </div>
            </main>
        </div>
    );
};