import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
	endpoints: (build) => ({
		getApiCityGetList: build.query<
			GetApiCityGetListApiResponse,
			GetApiCityGetListApiArg
		>({
			query: () => ({ url: `/api/City/getList` }),
		}),
		postApiCity: build.mutation<PostApiCityApiResponse, PostApiCityApiArg>({
			query: (queryArg) => ({
				url: `/api/City`,
				method: "POST",
				body: queryArg.cityAddDtoRequest,
			}),
		}),
		putApiCity: build.mutation<PutApiCityApiResponse, PutApiCityApiArg>({
			query: (queryArg) => ({
				url: `/api/City`,
				method: "PUT",
				body: queryArg.cityEditDeleteDtoRequest,
			}),
		}),
		deleteApiCity: build.mutation<
			DeleteApiCityApiResponse,
			DeleteApiCityApiArg
		>({
			query: (queryArg) => ({
				url: `/api/City`,
				method: "DELETE",
				body: queryArg.cityEditDeleteDtoRequest,
			}),
		}),
		getApiCoachesGetCoaches: build.query<
			GetApiCoachesGetCoachesApiResponse,
			GetApiCoachesGetCoachesApiArg
		>({
			query: () => ({ url: `/api/Coaches/GetCoaches` }),
		}),
		getApiCountryGetList: build.query<
			GetApiCountryGetListApiResponse,
			GetApiCountryGetListApiArg
		>({
			query: () => ({ url: `/api/Country/getList` }),
		}),
		postApiCountry: build.mutation<
			PostApiCountryApiResponse,
			PostApiCountryApiArg
		>({
			query: (queryArg) => ({
				url: `/api/Country`,
				method: "POST",
				params: {
					name: queryArg.name,
				},
			}),
		}),
		putApiCountry: build.mutation<
			PutApiCountryApiResponse,
			PutApiCountryApiArg
		>({
			query: (queryArg) => ({
				url: `/api/Country`,
				method: "PUT",
				body: queryArg.countryEditDeleteDtoRequest,
			}),
		}),
		deleteApiCountry: build.mutation<
			DeleteApiCountryApiResponse,
			DeleteApiCountryApiArg
		>({
			query: (queryArg) => ({
				url: `/api/Country`,
				method: "DELETE",
				body: queryArg.countryEditDeleteDtoRequest,
			}),
		}),
		getGetEvents: build.query<GetGetEventsApiResponse, GetGetEventsApiArg>({
			query: (queryArg) => ({
				url: `/GetEvents`,
				params: {
					eventType: queryArg.eventType,
					startDate: queryArg.startDate,
					endDate: queryArg.endDate,
				},
			}),
		}),
		getGetEvent: build.query<GetGetEventApiResponse, GetGetEventApiArg>({
			query: (queryArg) => ({
				url: `/GetEvent`,
				params: {
					eventId: queryArg.eventId,
				},
			}),
		}),
		getApiAuthorizationLogInLogin: build.query<
			GetApiAuthorizationLogInLoginApiResponse,
			GetApiAuthorizationLogInLoginApiArg
		>({
			query: (queryArg) => ({
				url: `/api/Authorization/LogIn/Login`,
				params: {
					usernameOrEmail: queryArg.usernameOrEmail,
					password: queryArg.password,
					rememberUser: queryArg.rememberUser,
				},
			}),
		}),
		getApiAuthorizationLogInVrem: build.query<
			GetApiAuthorizationLogInVremApiResponse,
			GetApiAuthorizationLogInVremApiArg
		>({
			query: () => ({ url: `/api/Authorization/LogIn/Vrem` }),
		}),
		postGetProductsFromFile: build.mutation<
			PostGetProductsFromFileApiResponse,
			PostGetProductsFromFileApiArg
		>({
			query: (queryArg) => ({
				url: `/GetProductsFromFile`,
				method: "POST",
				body: queryArg.body,
			}),
		}),
		deleteApiS3Files: build.mutation<
			DeleteApiS3FilesApiResponse,
			DeleteApiS3FilesApiArg
		>({
			query: (queryArg) => ({
				url: `/api/S3Files`,
				method: "DELETE",
				params: {
					id: queryArg.id,
				},
			}),
		}),
		postApiS3Files: build.mutation<
			PostApiS3FilesApiResponse,
			PostApiS3FilesApiArg
		>({
			query: (queryArg) => ({
				url: `/api/S3Files`,
				method: "POST",
				body: queryArg.body,
			}),
		}),
		getApiServicesGetList: build.query<
			GetApiServicesGetListApiResponse,
			GetApiServicesGetListApiArg
		>({
			query: () => ({ url: `/api/Services/GetList` }),
		}),
		getApiServicesGetService: build.query<
			GetApiServicesGetServiceApiResponse,
			GetApiServicesGetServiceApiArg
		>({
			query: (queryArg) => ({
				url: `/api/Services/GetService`,
				params: {
					id: queryArg.id,
				},
			}),
		}),
		postApiServicesAddTimeSlots: build.mutation<
			PostApiServicesAddTimeSlotsApiResponse,
			PostApiServicesAddTimeSlotsApiArg
		>({
			query: (queryArg) => ({
				url: `/api/Services/AddTimeSlots`,
				method: "POST",
				body: queryArg.body,
				params: {
					Id: queryArg.id,
					ServiceCategory: queryArg.serviceCategory,
					StartTime: queryArg.startTime,
					EndTime: queryArg.endTime,
					RemainingQuantity: queryArg.remainingQuantity,
					Media: queryArg.media,
					Description: queryArg.description,
					Label: queryArg.label,
					Price: queryArg.price,
					serviceId: queryArg.serviceId,
				},
			}),
		}),
		postApiAuthorizationSingInRegister: build.mutation<
			PostApiAuthorizationSingInRegisterApiResponse,
			PostApiAuthorizationSingInRegisterApiArg
		>({
			query: (queryArg) => ({
				url: `/api/Authorization/SingIn/Register`,
				method: "POST",
				body: queryArg.applicationUserDtoRequest,
			}),
		}),
		postApiAuthorizationSingInSendEmeilсonfirmationTokenByEmail: build.mutation<
			PostApiAuthorizationSingInSendEmeilсonfirmationTokenByEmailApiResponse,
			PostApiAuthorizationSingInSendEmeilсonfirmationTokenByEmailApiArg
		>({
			query: (queryArg) => ({
				url: `/api/Authorization/SingIn/SendEmeil\u0421onfirmationTokenByEmail`,
				method: "POST",
				params: {
					emeil: queryArg.emeil,
				},
			}),
		}),
		postApiAuthorizationSingInEmeilсonfirmation: build.mutation<
			PostApiAuthorizationSingInEmeilсonfirmationApiResponse,
			PostApiAuthorizationSingInEmeilсonfirmationApiArg
		>({
			query: (queryArg) => ({
				url: `/api/Authorization/SingIn/Emeil\u0421onfirmation`,
				method: "POST",
				params: {
					email: queryArg.email,
					token: queryArg.token,
				},
			}),
		}),
		getApiSportControlerGetList: build.query<
			GetApiSportControlerGetListApiResponse,
			GetApiSportControlerGetListApiArg
		>({
			query: () => ({ url: `/api/SportControler/getList` }),
		}),
		postApiSportControler: build.mutation<
			PostApiSportControlerApiResponse,
			PostApiSportControlerApiArg
		>({
			query: (queryArg) => ({
				url: `/api/SportControler`,
				method: "POST",
				params: {
					name: queryArg.name,
				},
			}),
		}),
		putApiSportControler: build.mutation<
			PutApiSportControlerApiResponse,
			PutApiSportControlerApiArg
		>({
			query: (queryArg) => ({
				url: `/api/SportControler`,
				method: "PUT",
				body: queryArg.sportEditDeleteDtoRequest,
			}),
		}),
		deleteApiSportControler: build.mutation<
			DeleteApiSportControlerApiResponse,
			DeleteApiSportControlerApiArg
		>({
			query: (queryArg) => ({
				url: `/api/SportControler`,
				method: "DELETE",
				body: queryArg.sportEditDeleteDtoRequest,
			}),
		}),
	}),
	overrideExisting: false,
});
export { injectedRtkApi as SportClickApi };
export type GetApiCityGetListApiResponse = /** status 200 OK */ CityRead[];
export type GetApiCityGetListApiArg = void;
export type PostApiCityApiResponse = /** status 200 OK */ string;
export type PostApiCityApiArg = {
	cityAddDtoRequest: CityAddDtoRequest;
};
export type PutApiCityApiResponse = unknown;
export type PutApiCityApiArg = {
	cityEditDeleteDtoRequest: CityEditDeleteDtoRequest;
};
export type DeleteApiCityApiResponse = unknown;
export type DeleteApiCityApiArg = {
	cityEditDeleteDtoRequest: CityEditDeleteDtoRequest;
};
export type GetApiCoachesGetCoachesApiResponse =
	/** status 200 OK */ CoachDtoIEnumerableRequestResponse;
export type GetApiCoachesGetCoachesApiArg = void;
export type GetApiCountryGetListApiResponse =
	/** status 200 OK */ CountryRead[];
export type GetApiCountryGetListApiArg = void;
export type PostApiCountryApiResponse = /** status 200 OK */ string;
export type PostApiCountryApiArg = {
	name?: string;
};
export type PutApiCountryApiResponse = unknown;
export type PutApiCountryApiArg = {
	countryEditDeleteDtoRequest: CountryEditDeleteDtoRequest;
};
export type DeleteApiCountryApiResponse = unknown;
export type DeleteApiCountryApiArg = {
	countryEditDeleteDtoRequest: CountryEditDeleteDtoRequest;
};
export type GetGetEventsApiResponse =
	/** status 200 OK */ EventsListItemDtoArrayRequestResponse;
export type GetGetEventsApiArg = {
	eventType?: EventTypeEnum;
	startDate?: string;
	endDate?: string;
};
export type GetGetEventApiResponse =
	/** status 200 OK */ EventsDtoRequestResponse;
export type GetGetEventApiArg = {
	eventId?: string;
};
export type GetApiAuthorizationLogInLoginApiResponse = unknown;
export type GetApiAuthorizationLogInLoginApiArg = {
	usernameOrEmail?: string;
	password?: string;
	rememberUser?: boolean;
};
export type GetApiAuthorizationLogInVremApiResponse = unknown;
export type GetApiAuthorizationLogInVremApiArg = void;
export type PostGetProductsFromFileApiResponse =
	/** status 200 OK */ Int32RequestResponse;
export type PostGetProductsFromFileApiArg = {
	body: {
		file?: Blob;
	};
};
export type DeleteApiS3FilesApiResponse = unknown;
export type DeleteApiS3FilesApiArg = {
	id?: number;
};
export type PostApiS3FilesApiResponse = /** status 200 OK */ string;
export type PostApiS3FilesApiArg = {
	body: {
		Extension?: string;
		file?: Blob;
	};
};
export type GetApiServicesGetListApiResponse =
	/** status 200 OK */ ServiceListItemDtoIEnumerableRequestResponse;
export type GetApiServicesGetListApiArg = void;
export type GetApiServicesGetServiceApiResponse =
	/** status 200 OK */ ServiceDtoRequestResponse;
export type GetApiServicesGetServiceApiArg = {
	id?: string;
};
export type PostApiServicesAddTimeSlotsApiResponse =
	/** status 200 OK */ BooleanRequestResponse;
export type PostApiServicesAddTimeSlotsApiArg = {
	id?: string;
	serviceCategory?: ServiceCategory;
	startTime?: string;
	endTime?: string;
	remainingQuantity?: number;
	media?: string[];
	description?: string;
	label?: string;
	price?: number;
	serviceId?: string;
	body: {
		Files?: Blob[];
	};
};
export type PostApiAuthorizationSingInRegisterApiResponse = unknown;
export type PostApiAuthorizationSingInRegisterApiArg = {
	applicationUserDtoRequest: ApplicationUserDtoRequest;
};
export type PostApiAuthorizationSingInSendEmeilсonfirmationTokenByEmailApiResponse =
	unknown;
export type PostApiAuthorizationSingInSendEmeilсonfirmationTokenByEmailApiArg =
	{
		emeil?: string;
	};
export type PostApiAuthorizationSingInEmeilсonfirmationApiResponse = unknown;
export type PostApiAuthorizationSingInEmeilсonfirmationApiArg = {
	email?: string;
	token?: string;
};
export type GetApiSportControlerGetListApiResponse =
	/** status 200 OK */ Sport[];
export type GetApiSportControlerGetListApiArg = void;
export type PostApiSportControlerApiResponse = unknown;
export type PostApiSportControlerApiArg = {
	name?: string;
};
export type PutApiSportControlerApiResponse = unknown;
export type PutApiSportControlerApiArg = {
	sportEditDeleteDtoRequest: SportEditDeleteDtoRequest;
};
export type DeleteApiSportControlerApiResponse = unknown;
export type DeleteApiSportControlerApiArg = {
	sportEditDeleteDtoRequest: SportEditDeleteDtoRequest;
};
export type Country = {};
export type CountryRead = {
	id?: string;
	createDateTime?: string;
	eneble?: boolean;
	name?: string | null;
	cities?: City[] | null;
};
export type AgeGroup = {
	id?: string;
	createDateTame?: string[] | null;
	enabled?: boolean | null;
	name?: string[] | null;
	description?: string[] | null;
	leagues?: League[] | null;
};
export type LegaueType = {
	id?: number;
	name?: string | null;
	leagues?: League[] | null;
};
export type League = {
	id?: string;
	createDateTime?: string;
	activeStatus?: number;
	ageGroupeId?: string;
	cytiId?: string;
	price?: number;
	name?: string[] | null;
	sledge?: boolean | null;
	checkDate?: string;
	isShowPhone?: boolean | null;
	isShowPhoto?: boolean | null;
	typeId?: number | null;
	dateBeging?: string;
	dateEnd?: string;
	address?: string[] | null;
	phone?: string[] | null;
	discription?: string | null;
	arenaId?: string;
	ageGroupe?: AgeGroup;
	arena?: Arena;
	type?: LegaueType;
};
export type SportArena = {
	sportId?: number;
	sport?: Sport;
	arenaId?: string;
	arena?: Arena;
};
export type Offer = {
	id?: string;
	createDateTime?: string;
	cityId?: string;
	name?: string | null;
	price?: number | null;
	description?: string | null;
	sports?: Sport[] | null;
	sportsOffers?: SportOffer[] | null;
};
export type SportOffer = {
	sportId?: number;
	sport?: Sport;
	offerId?: string;
	offer?: Offer;
};
export type Sport = {
	id?: number;
	name?: string | null;
	avatarId?: string | null;
	sportArenas?: SportArena[] | null;
	sportOffers?: SportOffer[] | null;
};
export type Arena = {
	id?: string;
	createDateTime?: string | null;
	enable?: boolean | null;
	cityId?: string | null;
	name?: string | null;
	type?: string[] | null;
	lengthM?: number;
	widthM?: number;
	inn?: string | null;
	phone?: string[] | null;
	website?: string[] | null;
	address?: string[] | null;
	discount?: string[] | null;
	description?: string | null;
	city?: City;
	leagues?: League[] | null;
	sports?: Sport[] | null;
	sportArenas?: SportArena[] | null;
};
export type ArenaRead = {
	id?: string;
	createDateTime?: string | null;
	enable?: boolean | null;
	cityId?: string | null;
	name?: string | null;
	type?: string[] | null;
	lengthM?: number;
	widthM?: number;
	inn?: string | null;
	phone?: string[] | null;
	website?: string[] | null;
	address?: string[] | null;
	discount?: string[] | null;
	description?: string | null;
	city?: City;
	leagues?: League[] | null;
	sports?: Sport[] | null;
	sportArenas?: SportArena[] | null;
};
export type City = {
	id?: string;
	craeteDateTime?: string | null;
	enabled?: boolean;
	name?: string | null;
	countryId?: string;
	country?: Country;
	arenas?: Arena[] | null;
};
export type CityRead = {
	id?: string;
	craeteDateTime?: string | null;
	enabled?: boolean;
	name?: string | null;
	countryId?: string;
	country?: CountryRead;
	arenas?: ArenaRead[] | null;
};
export type CityAddDtoRequest = {
	name?: string | null;
	countryId?: string;
};
export type CityEditDeleteDtoRequest = {
	id?: string;
	name?: string | null;
};
export type CustomServicePropertyType = 0 | 1 | 2 | 3 | 4 | 5;
export type CustomPropValueDto = {
	id?: number;
	propertyType?: CustomServicePropertyType;
	label?: string | null;
	value?: any | null;
};
export type ServiceCategory = 0 | 1 | 3 | 4 | 5;
export type ServiceOfferItemDto = {
	id?: string;
	serviceCategory?: ServiceCategory;
	startTime?: string | null;
	endTime?: string | null;
	remainingQuantity?: number;
	files?: Blob[] | null;
	media?: string[] | null;
	description?: string | null;
	label?: string | null;
	price?: number;
};
export type ServiceDto = {
	id?: string;
	serviceName?: string | null;
	isActive?: boolean;
	isArchived?: boolean;
	isOfferExpired?: boolean;
	expiredDate?: string | null;
	remainingQuantity?: number;
	isTimeSlots?: boolean;
	dateCreated?: string;
	dateModified?: string | null;
	customProperties?: CustomPropValueDto[] | null;
	sport?: string | null;
	arena?: string | null;
	address?: string | null;
	additionalInformation?: string | null;
	media?: string[] | null;
	description?: string | null;
	serviceItems?: ServiceOfferItemDto[] | null;
};
export type CoachDto = {
	id?: string;
	fio?: string | null;
	yearExp?: number;
	achievements?: string | null;
	media?: string[] | null;
	sportName?: string | null;
	services?: ServiceDto[] | null;
	customPropValues?: CustomPropValueDto[] | null;
};
export type CoachDtoIEnumerableRequestResponse = {
	data?: CoachDto[] | null;
	success?: boolean;
	message?: string | null;
};
export type CountryEditDeleteDtoRequest = {
	id?: string;
	name?: string | null;
};
export type EventTypeEnum = 0;
export type EventsListItemDto = {
	id?: string;
	name?: string | null;
	description?: string | null;
	eventType?: EventTypeEnum;
	startDate?: string;
	endDate?: string;
	maxParticipants?: number;
	totalParticipants?: number;
};
export type EventsListItemDtoArrayRequestResponse = {
	data?: EventsListItemDto[] | null;
	success?: boolean;
	message?: string | null;
};
export type EventsDto = {
	id?: string;
	name?: string | null;
	description?: string | null;
	eventType?: EventTypeEnum;
	startDate?: string;
	endDate?: string;
	maxParticipants?: number;
	totalParticipants?: number;
	customPropValues?: CustomPropValueDto[] | null;
};
export type EventsDtoRequestResponse = {
	data?: EventsDto;
	success?: boolean;
	message?: string | null;
};
export type Int32RequestResponse = {
	data?: number;
	success?: boolean;
	message?: string | null;
};
export type ServiceListItemDto = {
	id?: string;
	serviceName?: string | null;
	minPrice?: number;
	address?: string | null;
	photos?: string[] | null;
	arenaName?: string | null;
};
export type ServiceListItemDtoIEnumerableRequestResponse = {
	data?: ServiceListItemDto[] | null;
	success?: boolean;
	message?: string | null;
};
export type ServiceDtoRequestResponse = {
	data?: ServiceDto;
	success?: boolean;
	message?: string | null;
};
export type BooleanRequestResponse = {
	data?: boolean;
	success?: boolean;
	message?: string | null;
};
export type ApplicationUserDtoRequest = {
	username?: string | null;
	email?: string | null;
	password?: string | null;
	name?: string | null;
	surname?: string | null;
	patronymic?: string | null;
	dateOfBirth?: string;
	sendingMail?: boolean;
};
export type SportEditDeleteDtoRequest = {
	id?: number;
	name?: string | null;
};
export const {
	useGetApiCityGetListQuery,
	usePostApiCityMutation,
	usePutApiCityMutation,
	useDeleteApiCityMutation,
	useGetApiCoachesGetCoachesQuery,
	useGetApiCountryGetListQuery,
	usePostApiCountryMutation,
	usePutApiCountryMutation,
	useDeleteApiCountryMutation,
	useGetGetEventsQuery,
	useGetGetEventQuery,
	useGetApiAuthorizationLogInLoginQuery,
	useGetApiAuthorizationLogInVremQuery,
	usePostGetProductsFromFileMutation,
	useDeleteApiS3FilesMutation,
	usePostApiS3FilesMutation,
	useGetApiServicesGetListQuery,
	useGetApiServicesGetServiceQuery,
	usePostApiServicesAddTimeSlotsMutation,
	usePostApiAuthorizationSingInRegisterMutation,
	usePostApiAuthorizationSingInSendEmeilсonfirmationTokenByEmailMutation,
	usePostApiAuthorizationSingInEmeilсonfirmationMutation,
	useGetApiSportControlerGetListQuery,
	usePostApiSportControlerMutation,
	usePutApiSportControlerMutation,
	useDeleteApiSportControlerMutation,
} = injectedRtkApi;
