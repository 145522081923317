import "./MainPage.css";
import { currentScreenWidth, mobileScreenBreakpoint } from "../../utils";
import { mainpg } from "../products";
import SliderLine from "../ui/ScrollLine/ScrollLine";
import MainToSectionHeading from "./MainPageToSectionHeading";
import CustomVideoPlayer from "../ui/VideoPlayer/VideoPlayer";

export default function MainPageVideo() {

	return (
		<div className="main-page__coach-rating">
			<MainToSectionHeading title={"Видеообзоры"} to="/" />
			<SliderLine
				slidesArray={mainpg} // Pass the data for the slides
				slideAddClass={"main-page_coach-card"} // Optionally, pass a class for each slide
				noContentText={"Объявлений пока нет"} // Fallback text if there is no content
				slideType={"video"}
				hasOnlineAppointment={true}
				linkToSection={"/"}
				slideWidth={currentScreenWidth > mobileScreenBreakpoint ? 180 : 100}
				slidesToScroll={currentScreenWidth > mobileScreenBreakpoint ? 5 : 4}
			/>
		</div>
	);
}

export const MainPageVideoCard = () => {
	return (
		<div className="main_page-video">
			<CustomVideoPlayer videoUrl={"/videos/placeholder_video.mp4"} controls={false} />
		</div>
	);
};
