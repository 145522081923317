// Define a type for the slice state
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../createStore";

export interface ResolutionState {
    isMobile: boolean;
}

// Define the initial state using that type
const initialState: ResolutionState = {
    isMobile: false,
}

export const resolutionSlice = createSlice({
    name: 'resolution',
    initialState,
    reducers: {
        setResolution:(state, action: PayloadAction<boolean>)=>{
            state.isMobile = action.payload;
        }
    }
})

export const {setResolution} = resolutionSlice.actions;

export const selectIsMobileState = (state: RootState)=> state.resolution.isMobile;

export const resolutionReducer = resolutionSlice.reducer;
