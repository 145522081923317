import React from "react";

export const Subscribed = ({toggleSubscription, isSubscribed}) => {
    return (
        <a onClick={toggleSubscription}
           className={isSubscribed ? 'subscribed' : 'unsubscribed'}
           style={{cursor: 'pointer'}}>
            {isSubscribed ? 'Отписаться ' : 'Подписаться на уведомления'} <p></p>
        </a>
    )
}