import "./MainPage.css";
import SearchInput from "../ui/SearchInput/SearchInput";
import MainPageBenefitsSlider from "./MainPageBenefits";
import { currentScreenWidth, mobileScreenBreakpoint } from "../../utils";
import GreyButton from "../ui/GreyButton/GreyButton";
import { useState } from "react";

export default function MainPageTopPanel() {
	const [searchValue, setSearchValue] = useState("");

	const sports = [
		{
			imageSrc: "/images/main_page/hokey.png",
			name: "Хоккей",
		},

		{
			imageSrc: "/images/main_page/soccer.png",
			name: "Футбол",
		},

		{
			imageSrc: "/images/main_page/tennis.png",
			name: "Теннис",
		},

		{
			imageSrc: "/images/main_page/carting.png",
			name: "Картинг",
		},

		{
			imageSrc: "/images/main_page/basketball.png",
			name: "Баскетбол",
		},

		{
			imageSrc: "/images/main_page/volleyball.png",
			name: "Волейбол",
		},

		{
			imageSrc: "/images/main_page/dance.png",
			name: "Танцы",
		},
	];

	return (
		<div className="main-page__top-panel">
			<div className="main-page___sport-sections-container">
				<div className="main-page___sport-sections">
					{sports.map((sport) => (
						<MainPageSportButton
							isActive={sport.name === "Хоккей"}
							key={sport.name}
							imageSrc={sport.imageSrc}
							text={sport.name}
						/>
					))}
				</div>
			</div>
			<div className="main-page__top-content">
				<div className="main-page__search-and-info">
					<button className="main-page__all-categories-button">
						<img src="images/main_page/burger.svg" alt="Бургер :)" />
						<p>Все категории</p>
					</button>
					<div className="main-page__search-input">
						<SearchInput
							placeholder={
								currentScreenWidth > mobileScreenBreakpoint
									? "Поиск услуги / товара"
									: null
							}
							customPlaceholder={
								currentScreenWidth > mobileScreenBreakpoint ? null : (
									<span className="main-page__search-input-custom-placeholder">
										Искать в <b>Москве</b>
									</span>
								)
							}
							value={searchValue}
							setExternalValue={setSearchValue}
							textRemoverElement={<img className="main-page__search-input-text-remover" src="/images/main_page/input-text-remover.svg" alt="Убрать текст" />}
						/>
						{currentScreenWidth >= mobileScreenBreakpoint ? (
							<button className="main-page__search-search-button">Поиск</button>
						) : (
							<button className="main-page__search-filter-toggler">
								<img
									src={"/images/buttons/filter_toggler_dark.svg"}
									alt="Фильтр кнопка"
								/>
							</button>
						)}
					</div>
					<div className="main-page__adverts-info">
						<p className="total-posted">
							<span></span>Размещено объявлений: 345
						</p>
						<p className="last-order">
							<span></span>Последний заказ: 1 мин назад
						</p>
					</div>
					<button className="main-page__city-button">
						<p>Санкт-Петербург</p>
						<img src="images/main_page/location_indicator.png" alt="Город" />
					</button>
				</div>
				<MainPageBenefitsSlider />
			</div>
		</div>
	);
}

const MainPageSportButton = ({ imageSrc, text, isActive }) => {
	return (
		<GreyButton
			addClass={`main-page___sport-section ${isActive ? "active" : ""}`}>
			<img src={imageSrc} alt="Вид спорта" />
			<p>{text}</p>
		</GreyButton>
	);
};
