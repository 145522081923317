import React, { useRef, useState } from "react";
import { currentScreenWidth, mobileScreenBreakpoint } from "../../../utils";
import { CommunityPreview } from "../../MainPage/MainPageCommunities";
import { MainPageTrainingCard } from "../../MainPage/MainPageTrainingsSchedule";
import DefaultProductCard from "../DefaultProductCard/DefaultProductCard";
import styles from "./ScrollLine.module.css";
import { NavLink } from "react-router-dom";
import { MainPageVideoCard } from "../../MainPage/MainPageCoachVideo";

export default function SliderLine({
	slideWidth,
	slideHeight,
	slidesToScroll,
	slidesArray,
	noContentText,
	slideAddClass,
	hasOnlineAppointment,
	linkToSection,
	type,
	slideType,
}) {
	const [offset, setOffset] = useState(0);

	const scrollLineRef = useRef(null);

	const currentSlidesToScroll = slidesToScroll ? slidesToScroll : 4;
	const currentSlideWidth = slideWidth ? slideWidth : 200;

	const offsetScrollModifier =
		currentSlideWidth * currentSlidesToScroll + 20 * currentSlidesToScroll;

	const handleScrollLeft = () => {
		if (offset === 0) return;

		setOffset((prev) => prev - offsetScrollModifier);
	};

	const handleScrollRight = () => {
		if (scrollLineRef.current.offsetWidth) {
			if (offset + offsetScrollModifier >= scrollLineRef.current.offsetWidth)
				return;

			setOffset((prev) => prev + offsetScrollModifier);
		}
	};

	return (
		<div className={styles.sliderLine_wrapper}>
			<button
				onClick={handleScrollLeft}
				className={`${styles.sliderLine_scrollButton} ${styles.sliderLine_scrollButton_prev}`}>
				<img src="/images/main_page/arrow-left-scroll.svg" alt="Влево" />
			</button>
			<div className={styles.sliderLine_wrapper}>
				{slidesArray.length > 0 ? (
					<div
						ref={scrollLineRef}
						className={styles.sliderLine}
						style={{
							transform: `translateX(${-offset}px)`,
						}}>
						{type === "tiles" ? (
							<>
								{slidesArray.map((card, index) => {
									if (index % 4 === 0) {
										const cardsInSlide = slidesArray.slice(index, index + 4);

										return (
											<div
												key={`slide-tile-${index}`}
												className="main-page__my-advert-slide">
												{cardsInSlide.map((card, cardIndex) => (
													<DefaultProductCard
														cardWidth={172}
														imageHeight={172}
														key={cardIndex}
														addClass={"product-card"}
														card={card}
													/>
												))}
											</div>
										);
									}
									return null;
								})}
							</>
						) : (
							<>
								{slidesArray.map((card, index) => (
									<React.Fragment key={`fragment-slide-item-${index}`}>
										{slideType === "advert" ? (
											<DefaultProductCard
												key={`slide-item-${index}`}
												card={card}
												cardWidth={
													currentScreenWidth > mobileScreenBreakpoint
														? 230
														: 194
												}
												hasOnlineAppointment={hasOnlineAppointment}
												addClass={slideAddClass && slideAddClass}
											/>
										) : slideType === "training" ? (
											<MainPageTrainingCard key={`slide-item-${index}`} />
										) : slideType === "community" ? (
											<CommunityPreview key={`slide-item-${index}`} />
										) : slideType === "video" ? (
											<MainPageVideoCard  key={`slide-item-${index}`} />
										) : (
											<p>No slide</p>
										)}
									</React.Fragment>
								))}
								{linkToSection && (
									<NavLink
										to={linkToSection}
										style={{ width: currentSlideWidth }}
										className={styles.sliderLine_section_button}>
										<button>
											<img
												src="/images/main_page/caret_right.svg"
												alt="В раздел"
											/>
										</button>
										<p>В раздел</p>
									</NavLink>
								)}
							</>
						)}
					</div>
				) : (
					<SliderLineNoContent text={noContentText} />
				)}
			</div>
			<button
				onClick={handleScrollRight}
				className={`${styles.sliderLine_scrollButton} ${styles.sliderLine_scrollButton_next}`}>
				<img src="/images/main_page/arrow-right-scroll.svg" alt="Вправо" />
			</button>
		</div>
	);
}

export const SliderLineNoContent = ({ text }) => {
	return (
		<div className={styles.sliderLine_noContent}>
			<p>{text}</p>
		</div>
	);
};
