import React from "react";

export const Calendar = ({toggleOpen2,months,isOpen2,selectedMonth,selectMonth,rotateWeekDays,getFirstDayOfWeek,getDayOfWeek,price, toggleExpand3, selectedDay, timeOptions, toggleTimeSelection, selectedTimes, totalPrice, selectDay, today, expanded}) => {
    return (
        <div className="calendar">
            <div onClick={toggleOpen2}>
                <h1>Дата и время</h1>
                <p></p>
            </div>

            <div className={`mxw  ${isOpen2 ? 'open' : ''}`}>
                <div className="months">
                    {months.map((m, index) => (
                        <button
                            key={index}
                            className={`month-button ${selectedMonth === index ? 'active' : ''}`}
                            onClick={() => selectMonth(index)}
                        >
                            {m.month}
                        </button>
                    ))}
                </div>

                {selectedMonth !== null && (
                    <div className={`days ${expanded ? 'days-active' : ''}`}>
                        {expanded && (
                            <div className="year-display">
                                <h2>{months[selectedMonth].month} 2024</h2> {/* Добавляем строку с годом */}
                            </div>
                        )}

                        <div className="days-grid-cont">


                            <div className={`days-grid ${expanded ? 'grid-active' : ''}`}>
                                {rotateWeekDays(getFirstDayOfWeek(2024, selectedMonth)).map((day, index) => (
                                    <div key={index} className="week-day">
                                        {day}
                                    </div>
                                ))}
                                {selectedMonth !== null && selectedMonth > 0 && months[selectedMonth] && (
                                    Array.from({length: getFirstDayOfWeek(2024, selectedMonth)}).map((_, index) => {
                                        const prevMonthIndex = selectedMonth === 0 ? 11 : selectedMonth - 1; // Декабрь для января
                                        const prevMonthDays = months[prevMonthIndex]?.days || 0; // Количество дней в предыдущем месяце
                                        const day = prevMonthDays - getFirstDayOfWeek(2024, selectedMonth) + index + 1;
                                        return (
                                            <div key={`prev-${index}`} className="day gray-out">
                                                <div>{day}</div>
                                                {/* Предыдущие числа серым */}
                                            </div>
                                        );
                                    })
                                )}


                                {selectedMonth !== null && months[selectedMonth]?.days && (
                                    Array.from({length: months[selectedMonth].days}, (_, i) => i + 1).map(day => (
                                        <div
                                            key={day}
                                            className={`day ${selectedDay === day ? 'selected-day' : ''} ${day === today.day && selectedMonth === today.month && today.year === 2024 ? '' : ''}`}
                                            onClick={() => selectDay(day)}>
                                            <div>{day}<p>, {getDayOfWeek(2024, selectedMonth, day)}</p></div>
                                            <div className="priced">{price} р.</div>

                                            {day === today.day && selectedMonth === today.month && today.year === 2024 && (
                                                <div className="point"></div>
                                            )}
                                        </div>
                                    )).slice(0, expanded ? months[selectedMonth].days : 7)
                                )}
                                {!expanded && (
                                    <p className="next-month-heading">{months[selectedMonth + 1]?.month.slice(0, months[selectedMonth + 1]?.month === 'June' || months[selectedMonth + 1]?.month === 'July' ? 4 : 3)}</p>
                                )}

                                {selectedMonth !== null && months[selectedMonth]?.days && (
                                    Array.from({length: 42 - (getFirstDayOfWeek(2024, selectedMonth) + months[selectedMonth].days)}).map((_, index) => (
                                        <div key={`next-${index}`} className="day gray-out">
                                            <div>{index + 1}</div>
                                            {/* Следующие числа серым */}
                                        </div>
                                    )).slice(0, expanded ? undefined : 7)
                                )}
                            </div>

                            <button className="expand-button" onClick={toggleExpand3}>
                                {expanded ? <div className="arrow-up"></div> : <div className="arrow-down"></div>}
                            </button>
                        </div>
                    </div>
                )}

                {selectedDay && (
                    <div className="time-options">
                        {timeOptions[selectedDay]?.length > 0 ? (
                            timeOptions[selectedDay].map((option, index) => (
                                <div
                                    key={index}
                                    className={`time-option ${selectedTimes.includes(option.time) ? 'selected-time' : ''}`}
                                    onClick={() => toggleTimeSelection(option.time, option.price)}
                                >
                                    {option.time} · {option.price}р.
                                </div>
                            ))
                        ) : (
                            <div className="iskl">Пока предложений нету</div>
                        )}
                    </div>
                )}

                {selectedTimes.length > 0 && (
                    <button className="book-button">
                        Забронировать · {totalPrice} р
                    </button>
                )}
            </div>

        </div>
    )
}