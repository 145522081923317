import {Modal} from "./Modal";
import React from "react";

export const PriceFilterModal = ({
                                     isPriceModalOpen,
                                     closePriceModal,
                                     priceRange,
                                     setPriceRange,
                                     handleApplyPriceFilter,
                                 }) => (
    <Modal isOpen={isPriceModalOpen} onClose={closePriceModal}>
        <h2>Цена</h2>
        <div>
            <label>Цена от:</label>
            <input
                type="number"
                value={priceRange.min}
                onChange={(e) => setPriceRange({...priceRange, min: e.target.value})}
            />
        </div>
        <div>
            <label>Цена до:</label>
            <input
                type="number"
                value={priceRange.max}
                onChange={(e) => setPriceRange({...priceRange, max: e.target.value})}
            />
        </div>
        <button onClick={handleApplyPriceFilter}>Применить фильтр</button>
    </Modal>
);