import React, {useState} from "react";
import "./css/header.css";
import {HeaderCMS} from "./components/Headers/HeaderCMS";
import {HeaderAdmin} from "./components/Headers/HeaderAdmin";
import {SportsTable} from "./components/Tables/SportsTable";
import {CitySelect} from "./ui/CityDropdown";

////////////////////////

function CMS() {

    const cities = [
        "Москва",
        "Санкт-Петербург",
        "Новосибирск",
        "Екатеринбург",
        "Казань",
    ];
    const [selectedCity, setSelectedCity] = useState("Москва");

    return (
        <>
            <HeaderCMS/>
            <HeaderAdmin HeaderTitle={"Адмнинстрирование сайта"}/>
            <SportsTable TitleTable={"Виды спорта"} AddBtn={"Добавить вид спорта"}/>


            <CitySelect DropdownTitle={"Города"} cities={cities} selectedCity={selectedCity} setSelectedCity={setSelectedCity}  />
            {/*<FilterPanel searchQuery={searchQuery} setSearchQuery={setSearchQuery}/>*/}
        </>
    );
}

export default CMS;
