import React from "react";

export const SelectFilter = ({SelectFilterName1}) => {
    return (
        <>
            <ul className="select-filter__container">
                <li className="select-filter"><span>{SelectFilterName1}</span>
                    <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg"
                         className="filter-delete">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M4.99995 4.63878L9.13848 0.5L10 1.36158L5.86148 5.50035L9.99929 9.63842L9.13777 10.5L4.99995 6.36193L0.86223 10.4999L0.00070497 9.63833L4.13843 5.50035L0 1.36167L0.861525 0.500094L4.99995 4.63878Z"
                              fill="#818189"/>
                    </svg>

                </li>
            </ul>
        </>
    )
};