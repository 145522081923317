import React from "react";

export const ForAvtor = ({Stars,starDark,starLight}) => {
    return (
        <div className="avtor-block">
            <div className="ls-av">
                <h1>Александр Тимофеевич Брынза</h1>
                <div className="rating-summary">
                    <Stars rating={4.2} starLight={starLight} starDark={starDark} />
                    <span className="reviews-count">(отзывов: 12)</span>
                </div>
                <p>Размещено объявлений: <strong>6</strong></p>
            </div>
            <div className="rs-av"><img src="/Img.png" alt=""/></div>
        </div>
    )
}