import React from "react";

export const Recommendation = () => {

    return (
        <>
            <h1 className="cards__title">Рекомендуем также</h1>
            <div className="cards">
                <div className="card spons">
                    <span>Поиск спонсоров</span>
                    <img className="card__img" src="/images/icons/money.png" alt=""/>
                </div>
                <div className="card ispol">
                    <span>Поиск Исполнителей</span>
                    <img className="card__img"
                         src="https://s3-alpha-sig.figma.com/img/922e/3e7e/e61e711a8f9c7da4d3160bdad044d6c4?Expires=1734912000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=B29sC2GsjrowVOJ0Krg9bpzBolU8zlcJ3HLgwvT4fwJgwm~eDtYq3DR5Qk18wjz9-ufJ854RGsek~NITwIYYqjut0zXeFhFruemOQTHNdYuXTZSCCONFHqx4Hy7wHhLGtVlgVPiiGpkuGoZr6zRoAx41RUd9f0eNQr6lZnqCbf4mlHVTy6GlhcXBqu-EN0oPZmrFojyVQabxOSm4MlFUGvHDeisHrYQhy1C24FOB4USmeCWh0U5KfyK3LQvak8RpP3LtR6OGE0~fwoAPMEzKyHSsQX5-oqYZ81q1Sl7o2MtiTHn4HoLLMYJMUzBhffNlcbsn4bbOXt42vyiFJQDXGw__"
                         alt=""/>
                </div>
            </div>
        </>
    )
};